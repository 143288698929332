import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { missionActions } from '../mission/missionSlice';

export interface ToastType {
    id: string;
    severity: 'error' | 'info' | 'warning' | 'success' | 'default';
    autoHideDuration: number | null;
    message: string;
    vertical?: 'top' | 'bottom';
    horizontal?: 'center' | 'right' | 'left';
};

const initialState: { [key: string]: ToastType[] } = {};


const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        add_toast: ((state, action) => {
            if (!(action.payload.containerId in state)) state[action.payload.containerId] = [action.payload.toast];
            else {
                let index = state[action.payload.containerId].findIndex((t: ToastType) => t.id === action.payload.toast.id);
                if (index === -1) {
                    // If there is already a toast with the same id, we discard the action.
                    // Actually we could change the toast content but for now it is not possible. See https://github.com/iamhosseindhv/notistack/issues/234
                    state[action.payload.containerId].push(action.payload.toast);
                };
            };
        }),
        remove_toast: ((state, action) => {
            const index = state[action.payload.containerId].findIndex((t: ToastType) => t.id === action.payload.id);
            if (index !== -1) {
                state[action.payload.containerId] = [...state[action.payload.containerId].slice(0, index), ...state[action.payload.containerId].slice(index + 1)];
            }
            if (state[action.payload.containerId].length === 0) delete state[action.payload.containerId];
        }),
        remove_toast_container: ((state, action) => {
            delete state[action.payload.containerId];
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
    }
});

export const selecToast = (state: RootState) => state.toast;

export const toastActions = toastSlice.actions;

export default toastSlice.reducer;