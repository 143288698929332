import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RFeature, RLayerVectorImage } from "rlayers";
import { Point } from "ol/geom";
import { transform } from "ol/proj";
import { RootState } from '../../../../app/store';
import VectorLayer from '../../map/layers/VectorLayer';
import { aeroActions, MetarType } from '../../../../redux/aero/aeroSlice';
import { airportStyle } from '../styles/airport';
import { metarStyle } from '../styles/metar';
import { store } from '../../../../app/store';

const Airport = React.memo(function Airport({ position, properties }: { position: number[], properties: any }) {
    const dispatch = useDispatch();
    const metar = useSelector((state: RootState) => state.nav.aero.airports.metars.find((m: MetarType) => m.icao === properties.icao));
    const mapProj = store.getState().map.projection;
    const geometry = new Point(transform(position, "EPSG:4326", mapProj));
    const style = metar?.data ? metarStyle : airportStyle;

    const onSingleClick = useCallback((e: any) => {
        dispatch(aeroActions.airport_click({ icao: properties.icao }));
        return false; // prevent forwarding click event to other features
    }, [dispatch, properties]);

    const onDblClick = useCallback(() => {
        dispatch(aeroActions.airport_dblClick({ icao: properties.icao }));
        return false; // prevent zooming in on double click
    }, [dispatch, properties]);

    return (
        <RFeature
            key={properties.id}
            geometry={geometry}
            properties={{ ...properties, metar: metar?.data }}
            style={style}
            onSingleClick={onSingleClick}
            onDblClick={onDblClick}
        />
    );
});

export default function AirportsLayer({ zIndex }: { zIndex: number }) {
    const airports = useSelector((state: RootState) => state.nav.aero.airports);

    return (
        <VectorLayer
            id="airports-layer"
            component={RLayerVectorImage}
            zIndex={zIndex}
            visible={airports.visible}
            rulingSnap={true}
            style={null}
        >
            {
                airports.list.map((a: any) => <Airport key={a.id} position={a.position} properties={a} />)
            }
        </VectorLayer>
    );
};