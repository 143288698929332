import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import TextField from "@mui/material/TextField";
import GeoJSON from "ol/format/GeoJSON";
import KML from "ol/format/KML";
import { store } from '../../../app/store';
import { trim, DECIMAL_REGEX, isCoordinate, makeCoordinate, isCompleteCoordinate, degreesToSyntax } from "../../../utils/CoordinatesSyntax";
import { customActions, CustomProductType } from "../../../redux/custom/customSlice";
import { RootState } from "../../../app/store";

const PROJS = [
    {
        id: "EPSG:4326",
        type: "polar",
        description: "World Geodetic System 1984 (EPSG:4326)"
    },
    {
        id: "EPSG:3857",
        type: "metric",
        description: "Spherical Mercator (EPSG:3857)"
    },
    {
        id: "EPSG:3575",
        type: "metric",
        description: "North Pole LAEA Europe (EPSG:3575)"
    }
];

const ALLOWED_RASTER_EXTENSIONS = ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.bmp', '.BMP'];
const ALLOWED_VECTOR_EXTENSIONS = ['.json', '.JSON', '.geojson', '.GEOJSON', '.kml', '.KML'];

function createForm(custom: CustomProductType | undefined, syntax: string) {
    return ({
        file: custom?.file_name ? new File([""], custom.file_name) : null,
        fileType: custom?.data_type || null,
        sourceProjection: custom?.source_projection || PROJS[0].id,
        unit: "deg",
        imageWidth: 0,
        imageHeight: 0,
        minLon: {
            value: custom?.min_long ? degreesToSyntax(custom.min_long, "lon", syntax) : "",
            offset: 0,
            offsetDirection: "left",
        },
        minLat: {
            value: custom?.min_lat ? degreesToSyntax(custom.min_lat, "lat", syntax) : "",
            offset: 0,
            offsetDirection: "bottom",
        },
        maxLon: {
            value: custom?.max_long ? degreesToSyntax(custom.max_long, "lon", syntax) : "",
            offset: 0,
            offsetDirection: "right",
        },
        maxLat: {
            value: custom?.max_lat ? degreesToSyntax(custom.max_lat, "lat", syntax) : "",
            offset: 0,
            offsetDirection: "top",
        }
    });
};

export default function CustomSettings({ open, handleClose, custom }: { open: boolean, handleClose: () => void, custom?: CustomProductType }) {

    const dispatch = useDispatch();
    const syntax = useSelector((state: RootState) => state.map.coordinates_syntax);
    const [form, setForm] = useState<any>(createForm(custom, syntax));
    const [error, setError] = useState<string | null>(null);
    const prevUnit = useRef("deg");

    const handleFileSelect = (props: any) => {
        const { target } = props;
        const file = target.files[0];
        const ext = file.name.split('.').slice(-1)[0];
        if (file.size > 20000000) {
            setError(`${file.name} size exceed 20MB`);
            setForm((form: any) => ({
                ...form,
                file: null,
                fileType: null
            }));
            return;
        } else {
            setError(null);
        };

        if (ALLOWED_VECTOR_EXTENSIONS.includes(`.${ext}`)) {
            const fileReader = new FileReader();
            switch (ext) {
                case 'json':
                case 'JSON':
                case 'geojson':
                case 'GEOJSON':
                    fileReader.onload = (e: any) => {
                        try {
                            const proj = store.getState().map.projection;
                            new GeoJSON({
                                featureProjection: proj,
                            }).readFeatures(e.target?.result);
                            setForm((form: any) => ({
                                ...form,
                                file,
                                fileType: "vector"
                            }));
                            setError(null);
                        } catch (e: any) {
                            setError(`Invalid Geojson file content.\n ${e}`);
                        }
                    };
                    break;
                case 'kml':
                case 'KML':
                    fileReader.onload = (e) => {
                        try {
                            new KML({
                                iconUrlFunction: function (href) {
                                    return href.replace('maps.google.com', window.location.host);
                                }
                            }).readFeatures(e.target?.result);
                            setForm((form: any) => ({
                                ...form,
                                file,
                                fileType: "vector"
                            }));
                            setError(null);
                        } catch (e) {
                            setError("Invalid KML file content");
                        }
                    };
                    break;
                default:
                    break;
            }

            fileReader.readAsText(file);
        } else if (ALLOWED_RASTER_EXTENSIONS.includes(`.${ext}`)) {
            setForm((form: any) => ({
                ...form,
                file,
                fileType: "raster"
            }));
            setError(null);
        } else {
            setForm((form: any) => ({
                ...form,
                file: null,
                fileType: null
            }));
            setError(`Unsupported file extension .${ext}`);
        }
    };

    const onSPChange = (e: any) => {
        const proj_def = PROJS.find((p) => p.id === e.target.value);
        let unit: string | null = null;

        if (proj_def?.type === "polar" && form.unit === "met") {
            unit = prevUnit.current;

        } else if (proj_def?.type === "metric" && (form.unit === "deg" || form.unit === "rad")) {
            prevUnit.current = form.unit;
            unit = "met";
        };

        setForm((form: any) => ({
            ...form,
            unit,
            sourceProjection: e.target.value
        }));
    };

    const onUnitChange = (e: any) => {
        const newUnit = e.target.value;
        if (newUnit !== "met") {
            setForm((form: any) => ({
                ...form,
                minLon: {
                    ...form.minLon,
                    value: form.minLon.value !== "" ? newUnit === "deg" ? trim(Number(form.minLon.value) * 180 / Math.PI, 6) : trim(Number(form.minLon.value) * Math.PI / 180, 6) : ""
                },
                minLat: {
                    ...form.minLat,
                    value: form.minLat.value !== "" ? newUnit === "deg" ? trim(Number(form.minLat.value) * 180 / Math.PI, 6) : trim(Number(form.minLat.value) * Math.PI / 180, 6) : ""
                },
                maxLon: {
                    ...form.maxLon,
                    value: form.maxLon.value !== "" ? newUnit === "deg" ? trim(Number(form.maxLon.value) * 180 / Math.PI, 6) : trim(Number(form.maxLon.value) * Math.PI / 180, 6) : ""
                },
                maxLat: {
                    ...form.maxLat,
                    value: form.maxLat.value !== "" ? newUnit === "deg" ? trim(Number(form.maxLat.value) * 180 / Math.PI, 6) : trim(Number(form.maxLat.value) * Math.PI / 180, 6) : ""
                },
                unit: newUnit
            }));
        } else {
            setForm((form: any) => ({
                ...form,
                unit: newUnit
            }));
        };
    };

    const onCoordsChange = (e: any, key: string) => {
        const val = e.target.value;
        if (val === '' || (form.unit === "rad" && val.match(DECIMAL_REGEX) !== null) || (form.unit === "deg" && isCoordinate(val))) {
            setForm((form: any) => ({
                ...form, [key]: {
                    ...form[key],
                    value: String(val)
                }
            }));
        };
    };

    const onOffsetChange = (e: any, key: string) => {
        const val = e.target.value;
        const regex = /^[0-9]*$/;
        if (val === '' || val.match(regex)) {
            setForm((form: any) => ({
                ...form, [key]: {
                    ...form[key],
                    offset: String(val)
                }
            }));
        };
    };

    const onDirectionChange = (e: any, key: string) => {
        setForm((form: any) => ({
            ...form, [key]: {
                ...form[key],
                offsetDirection: e.target.value
            }
        }));
    };

    const handleClear = (e: any) => {
        setForm(createForm(custom, syntax));
        setError(null);
    };

    const handleCancel = (e: any) => {
        handleClear(e);
        handleClose();
    };

    const handleApply = (e: any) => {

        if (["minLon", "maxLon", "minLat", "maxLat"].map(key => getError(key)).reduce((sum: number, element) => sum + element, 0) === 0 || form.fileType === "vector") {
            let minLonValue = makeCoordinate(form.minLon.value);
            let minLatValue = makeCoordinate(form.minLat.value);
            let maxLonValue = makeCoordinate(form.maxLon.value);
            let maxLatValue = makeCoordinate(form.maxLat.value);

            minLonValue = !minLonValue && minLonValue !== 0 ? -Infinity : minLonValue;
            minLatValue = !minLatValue && minLatValue !== 0 ? -Infinity : minLatValue;
            maxLonValue = !maxLonValue && maxLonValue !== 0 ? -Infinity : maxLonValue;
            maxLatValue = !maxLatValue && maxLatValue !== 0 ? -Infinity : maxLatValue;

            if (form.unit === "rad") {
                minLonValue = minLonValue * 180 / Math.PI;
                minLatValue = minLatValue * 180 / Math.PI;
                maxLonValue = maxLonValue * 180 / Math.PI;
                maxLatValue = maxLatValue * 180 / Math.PI;
            }

            if (form.fileType === "raster" && (Number(form.minLon.offset) > 0 || Number(form.minLat.offset) > 0 || Number(form.maxLon.offset) > 0 || Number(form.maxLat.offset) > 0)) {
                // Calculating pixel to distance ratio
                const pixelLongDistance = form.minLon.offsetDirection === form.maxLon.offsetDirection ?
                    Math.abs(Number(form.maxLon.offset) - Number(form.minLon.offset))
                    :
                    Math.abs(form.imageWidth - Number(form.minLon.offset) - Number(form.maxLon.offset));

                const pixelLatDistance = form.minLat.offsetDirection === form.maxLat.offsetDirection ?
                    Math.abs(Number(form.maxLat.offset) - Number(form.minLat.offset))
                    :
                    Math.abs(form.imageHeight - Number(form.minLat.offset) - Number(form.maxLat.offset));

                const degreeLongDistance = maxLonValue - minLonValue;
                const degreeLatDistance = maxLatValue - minLatValue;

                const longPixelRatio = degreeLongDistance / pixelLongDistance;
                const latPixelRatio = degreeLatDistance / pixelLatDistance;

                // Applying offsets
                minLonValue = form.minLon.offsetDirection === "left" ?
                    minLonValue - (Number(form.minLon.offset) * longPixelRatio)
                    :
                    minLonValue - ((form.imageWidth - Number(form.minLon.offset)) * longPixelRatio);

                minLatValue = form.minLat.offsetDirection === "bottom" ?
                    minLatValue - (Number(form.minLat.offset) * latPixelRatio)
                    :
                    minLatValue - ((form.imageHeight - Number(form.minLat.offset)) * latPixelRatio);

                maxLonValue = form.maxLon.offsetDirection === "right" ?
                    maxLonValue + (Number(form.maxLon.offset) * longPixelRatio)
                    :
                    maxLonValue + ((form.imageWidth - Number(form.maxLon.offset)) * longPixelRatio);

                maxLatValue = form.maxLat.offsetDirection === "top" ?
                    maxLatValue + (Number(form.maxLat.offset) * latPixelRatio)
                    :
                    maxLatValue + ((form.imageHeight - Number(form.maxLat.offset)) * latPixelRatio);
            }

            dispatch(customActions.add({
                id: custom?.id,
                file: form.file,
                fileType: form.fileType,
                minLon: String(minLonValue),
                maxLon: String(maxLonValue),
                minLat: String(minLatValue),
                maxLat: String(maxLatValue),
                sourceProjection: form.sourceProjection
            }));

        }

        handleClose();
    };

    const getError = (id: string) => {

        if (!isCompleteCoordinate(form[id].value)) return 1;

        let minLonValue = makeCoordinate(form.minLon.value);
        let minLatValue = makeCoordinate(form.minLat.value);
        let maxLonValue = makeCoordinate(form.maxLon.value);
        let maxLatValue = makeCoordinate(form.maxLat.value);

        switch (id) {
            case 'minLon':
                if (minLonValue && maxLonValue && minLonValue >= maxLonValue) return 2;
                if (minLonValue && ((form.unit === "deg" && (minLonValue > 180 || minLonValue < -180)) || (form.unit === "rad" && (minLonValue > Math.PI || minLonValue < (-1 * Math.PI))))) return 3;
                break;
            case 'minLat':
                if (minLatValue && maxLatValue && minLatValue >= maxLatValue) return 2;
                if (minLatValue && (((form.unit === "deg" && (minLatValue > 90 || minLatValue < -90)) || (form.unit === "rad" && (minLatValue > Math.PI / 2 || minLatValue < (-1 * Math.PI / 2)))))) return 4;
                break;
            case 'maxLon':
                if (minLonValue && maxLonValue && maxLonValue <= minLonValue) return 2;
                if (maxLonValue && (((form.unit === "deg" && (maxLonValue > 180 || maxLonValue < -180)) || (form.unit === "rad" && (maxLonValue > Math.PI || maxLonValue < (-1 * Math.PI)))))) return 3;
                break;
            case 'maxLat':
                if (minLatValue && maxLatValue && maxLatValue <= minLatValue) return 2;
                if (maxLatValue && (((form.unit === "deg" && (maxLatValue > 90 || maxLatValue < -90)) || (form.unit === "rad" && (maxLatValue > Math.PI / 2 || maxLatValue < (-1 * Math.PI / 2)))))) return 4;
                break;
            default:
                break;
        };

        return 0;
    };

    const getHelperText = (id: string, error: number) => {
        if (error === 1) return "Invalid value";
        if (error === 2) {
            switch (id) {
                case 'minLon':
                    return "Must be lower than the max longitude";
                case 'maxLon':
                    return "Must be greater than the min longitude";
                case 'minLat':
                    return "Must be lower than the max latitude";
                case 'maxLat':
                    return "Must be greater than the min latitude";
                default:
                    break;
            }
        }
        if (error === 3) return `Must be between ${(form.unit === "deg" ? "-180 and 180" : "-π and π")}`;
        if (error === 4) return `Must be between ${(form.unit === "deg" ? "-90 and 90" : "-π/2 and π/2")}`;
        return null;
    };

    useEffect(() => {
        if (form.fileType === "raster") {
            // Recovering image dimensions
            const img = new Image();
            img.src = window.URL.createObjectURL(form.file);
            img.onload = () => {
                setForm((form: any) => ({
                    ...form,
                    imageWidth: Number(img.naturalWidth),
                    imageHeight: Number(img.naturalHeight)
                }));
                window.URL.revokeObjectURL(img.src);
            };
        }
    }, [form.fileType, form.file]);

    useEffect(() => {
        setForm(createForm(custom, syntax));
    }, [syntax, custom]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Custom Layer Settings</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <Typography variant="h6" style={{ fontSize: "1rem" }}>Selected File:</Typography>
                        {
                            custom?.file_name ?
                                <div>{custom?.file_name}</div>
                                :
                                <div>
                                    <input
                                        id="select-button-file"
                                        multiple
                                        onChange={handleFileSelect}
                                        type="file"
                                        accept={ALLOWED_RASTER_EXTENSIONS.join(',') + "," + ALLOWED_VECTOR_EXTENSIONS.join(',')}
                                    />
                                    <label htmlFor="select-button-file">
                                        <Button component="span" variant="text">
                                            {form.file?.name ? form.file.name : '...'}
                                        </Button>
                                    </label>
                                </div>
                        }
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        {
                            form.fileType !== null &&
                            <TextField
                                select
                                value={form.sourceProjection}
                                id="sourceProjection"
                                label="Projection ID"
                                onChange={onSPChange}
                                SelectProps={{ native: true }}
                                fullWidth
                            >
                                {PROJS.map((proj) => <option key={proj.id} value={proj.id}>{proj.description}</option>)}
                            </TextField>
                        }
                        {
                            form.fileType === "raster" &&
                            <TextField
                                select
                                value={form.unit}
                                id="unit"
                                label="Coordinates unit"
                                onChange={onUnitChange}
                                SelectProps={{ native: true }}
                                sx={{
                                    minWidth: '150px'
                                }}
                            >
                                {
                                    PROJS.find((p) => p.id === form.sourceProjection)?.type === 'polar' &&
                                    <>
                                        <option value="deg">degree</option>
                                        <option value="rad">radiant</option>
                                    </>
                                }
                                {
                                    PROJS.find((p) => p.id === form.sourceProjection)?.type === 'metric' &&
                                    <option value="met">meter</option>
                                }
                            </TextField>
                        }
                    </Stack>
                    {
                        form.fileType === "raster" &&
                        <>
                            <Grid container spacing={1}>
                                {
                                    [
                                        { id: "minLon", label: "Longitude", text: "Left border (min longitude)", offsetDirections: ['left', 'right'] },
                                        { id: "maxLon", label: "Longitude", text: "Right border (max longitude)", offsetDirections: ['left', 'right'] },
                                        { id: "minLat", label: "Latitude", text: "Lower border (min latitude)", offsetDirections: ['top', 'bottom'] },
                                        { id: "maxLat", label: "Latitude", text: "Upper border (max latitude)", offsetDirections: ['top', 'bottom'] }
                                    ].map(({ id, label, text, offsetDirections }: { id: string, label: string, text: string, offsetDirections: string[] }) => {
                                        const error = getError(id);
                                        const helperText = getHelperText(id, error);

                                        return (
                                            <Grid item key={id} xs={6}>
                                                <Stack direction="column" spacing={1}>
                                                    <Typography variant="subtitle2">{text}</Typography>
                                                    <TextField
                                                        value={form[id].value}
                                                        label={label}
                                                        onChange={(event) => onCoordsChange(event, id)}
                                                        error={error > 0}
                                                        helperText={helperText}
                                                        fullWidth
                                                    />
                                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                                                        <TextField
                                                            value={form[id].offset}
                                                            label="Pixel offset"
                                                            onChange={(event) => onOffsetChange(event, id)}
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            select
                                                            value={form[id].offsetDirection}
                                                            label="from"
                                                            onChange={event => onDirectionChange(event, id)}
                                                            SelectProps={{ native: true }}
                                                            fullWidth
                                                        >
                                                            {offsetDirections.map((od) => <option key={od} value={od}>{od}</option>)}
                                                        </TextField>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </>
                    }
                </Stack>
                {error && <Alert severity="error" variant="outlined">{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button
                    color="inherit"
                    variant="outlined"
                    onClick={handleClear}
                    size="small"
                >
                    {custom ? "Reset all" : "Clear all"}
                </Button>
                <Button
                    color="inherit"
                    variant="outlined"
                    disabled={
                        !form.file 
                        || (form.fileType === "raster" && (getError("minLon") !== 0 || getError("maxLon") !== 0 || getError("minLat") !== 0 || getError("maxLat") !== 0))
                    }
                    onClick={handleApply}
                    size="small"
                >
                    {custom ? "Edit" : "Add"}
                </Button>
                <Button
                    color="inherit"
                    variant="outlined"
                    size="small"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};