import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import StringUtils from "../../utils/StringUtils";
import favicon_red_dot from "../../img/favicon_red_dot.ico";
import favicon from "../../img/favicon.ico";
import { RootState } from "../../app/store";
import { ConversationsType } from "../../redux/chat/chatSlice";

const selectUnreadMessageCount = createSelector(
    (state: RootState) => state.chat.conversations,
    (convs: ConversationsType) => convs.list.map((c: any) => c.unread)
        .reduce(function (a, b) {
            return a + b;
        }, 0)
);

export default function MyHelmet() {
    const pathname = useLocation().pathname;
    const [title, setTitle] = useState('PLANET');
    const [icon, setIcon] = useState(favicon);
    const unreadMessageCount = useSelector(selectUnreadMessageCount);

    useEffect(() => {
        const [, first, second, third] = pathname.split('/');
        if (second === "dashboard") setTitle('PLANET - ' + StringUtils.capitalizeFirstLetter(third));
        else if (first === "admin") setTitle('PLANET - Admin');
        else setTitle('PLANET');
    }, [pathname]);

    useEffect(() => {
        if (unreadMessageCount > 0) setIcon(favicon_red_dot);
        else setIcon(favicon);
    }, [unreadMessageCount]);

    return (
        <Helmet>
            <link rel="icon" type="image/png" href={icon} sizes="16x16" />
            <title>{title}</title>
        </Helmet>
    );
};