import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { API_CONFIG } from '../../../config/api';
import { RootState } from "../../../app/store";

interface FormType {
    [key: string]: {
        value: string,
        shown: boolean,
        match?: boolean
    }
};

interface ErrorType {
    error: string;
    error2: string;
    error3: any;
};

const formList = [
    { id: 'current_password', label: 'Current password' },
    { id: 'new_password', label: 'New password' },
    { id: 're_new_password', label: 'New password confirmation' }
];

export default function ChangePassword({ open, onClose }: { open: boolean, onClose: () => void }) {

    const token = useSelector((state: RootState) => state.auth.token);
    const [error, setError] = useState<ErrorType | null>(null);
    const [success, setSuccess] = useState<boolean | null>(null);
    const [form, setForm] = useState<FormType>({
        current_password: {
            value: "",
            shown: false
        },
        new_password: {
            value: "",
            shown: false
        },
        re_new_password: {
            value: "",
            shown: false,
            match: true
        }
    });
    const [passwordMatch, setPasswordMatch] = useState(false);

    const saveNewPassword = () => {
        async function postData() {
            try {
                await axios.post(
                    `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/auth/users/set_password/`,
                    {
                        current_password: form.current_password.value,
                        new_password: form.new_password.value,
                        re_new_password: form.re_new_password
                    },
                    { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
                );
                setError(null);
                setSuccess(true);
                setTimeout(() => {
                    onClose();
                }, 1000);
            } catch (error: any) {
                setError({ error: error["response"]["status"], error2: error["response"]["statusText"], error3: error["response"]["data"] });
                setSuccess(false);
            };
        }
        postData();
    };

    useEffect(() => {
        setPasswordMatch(form.new_password.value === form.re_new_password.value);
    }, [form]);

    useEffect(() => {
        setError(null);
        setSuccess(null);
        setForm({
            current_password: {
                value: "",
                shown: false
            },
            new_password: {
                value: "",
                shown: false
            },
            re_new_password: {
                value: "",
                shown: false,
                match: true
            }
        });
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <FormControl
                    sx={{
                        '& .MuiFormControl-root': {
                            '& > *': {
                                margin: (theme) => theme.spacing(1),
                                width: '300px',
                            },
                        }
                    }}
                >
                    {formList.map((field, index) => (
                        <TextField
                            key={field.id}
                            type={form[field.id].shown ? "text" : "password"}
                            error={form[field.id].match && !passwordMatch}
                            helperText={form[field.id].match && !passwordMatch && "New password missmatch"}
                            required
                            fullWidth
                            label={field.label}
                            value={form[field.id].value}
                            onChange={(e) => setForm(form => ({ ...form, [field.id]: { ...form[field.id], value: e.target.value } }))}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => setForm(from => ({
                                                ...form,
                                                [field.id]: {
                                                    ...form[field.id],
                                                    shown: !form[field.id].shown
                                                }
                                            }))}
                                        >
                                            {form[field.id].shown ?
                                                <VisibilityOff fontSize="inherit" />
                                                :
                                                <Visibility fontSize="inherit" />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ))}
                </FormControl>
                {error &&
                    <Alert severity="error">
                        <AlertTitle>{`Error ${error.error}: ${error.error2}`}</AlertTitle>
                        {
                            Object.entries(error.error3).map(([key, value]) => {
                                return (<div key={key}>{key}: {JSON.stringify(value)}</div>)
                            })
                        }
                    </Alert>
                }
                {success &&
                    <Alert severity="success">
                        <AlertTitle>{`Password successfully changed`}</AlertTitle>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={form.new_password.value === "" || !passwordMatch}
                    onClick={saveNewPassword}>
                    Save
                </Button>
                <Button variant="contained" color="primary" onClick={onClose} >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};