import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectAuth, authActions } from '../../redux/auth/authSlice';
import Loading from './Loading';

export default function Check(props: any) {
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);

    useEffect(() => {
        dispatch(authActions.get_user_info({}))
    }, [dispatch]);

    if (!auth.token) return (
        <Redirect
            to={{
                pathname: '/login',
            }}
        />
    );

    if (auth.logged) return (
        <Redirect
            to={{
                pathname: '/getMissions',
            }}
        />
    );

    return <Loading />;
};
