import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { RFeature, RContext, RPopup, RLayerVectorImage } from "rlayers";
import Map from 'ol/Map';
import { transform } from "ol/proj";
import Point from "ol/geom/Point";
import { Circle as CircleStyle, Stroke, Style } from 'ol/style';
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import Box from '@mui/material/Box';
import VectorLayer from "../../map/layers/VectorLayer";
import { store } from "../../../../app/store";
import { hourOrNbDays, toDate } from "../../../../utils/TimeUtils";


interface StrikeType {
    id: string;
    coordinates: any[];
    properties: any;
};

const request = async (url: string) => {
    try {
        const { data } = await axios.get(url);
        return data;
    } catch (error: any) {
        return ({ error: error?.response?.status || "unknown error" })
    }
};

const Strike = React.memo(function Strike({ id, coordinates, properties }: StrikeType) {
    const mapProjection = store.getState().map.projection;
    const geometry = new Point(transform(coordinates, "EPSG:4326", mapProjection));
    return (
        <RFeature
            geometry={geometry}
            properties={properties}
        >
            <RPopup trigger="hover">
                <Box
                    sx={{
                        border: '2px solid',
                        borderColor: 'grey',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        borderRadius: "5px",
                        padding: (theme) => theme.spacing(0.5)
                    }}
                >
                    {hourOrNbDays(properties.time)}
                </Box>
            </RPopup>
        </RFeature>
    );
});

export default function Lightning({ productId, zIndex, url, visible, style, opacity }: { productId: string, zIndex: number, url: string, visible: boolean, style: () => void, opacity: number }) {
    const [strikes, setStrikes] = useState<StrikeType[]>([]);
    const timeout = useRef<NodeJS.Timeout | null>(null);
    const listenerKey = useRef(null);

    const flash = (event: any, map: Map, tilelayer: any) => {
        const start = Date.now();
        if (Math.abs(start - toDate(event.feature.getProperties().time).getTime()) > 90000) return; // do not animate too old flashes

        const duration = 3000;
        const flashGeom = event.feature.getGeometry().clone();
        listenerKey.current = tilelayer.on('postrender', animate);

        function animate(event: any) {
            const frameState = event.frameState;
            const elapsed = frameState.time - start;
            if (elapsed >= duration) {
                if (listenerKey.current) unByKey(listenerKey.current);
                return;
            }
            const vectorContext = getVectorContext(event);
            const elapsedRatio = elapsed / duration;
            // radius will be 5 at start and 30 at end.
            const radius = easeOut(elapsedRatio) * 25 + 5;
            const opac = easeOut(1 - elapsedRatio);

            const style = new Style({
                image: new CircleStyle({
                    radius: radius,
                    stroke: new Stroke({
                        color: 'rgba(255, 255, 255, ' + opac * opacity + ')',
                        width: 0.25 + opac,
                    }),
                }),
            });

            vectorContext.setStyle(style);
            vectorContext.drawGeometry(flashGeom);
            // tell OpenLayers to continue postrender animation
            setTimeout(() => {
                map.render();
            }, 80);

        }
    }

    useEffect(() => {
        async function getData() {
            const data = await request(url);
            const newStrikesIds: string[] = [];
            if (data.error) return null;

            const newStrikes = data.geometry.coordinates.map((c: any[], index: number) => {
                const id = `strike-${Math.random().toString(36).substring(7)}`;
                newStrikesIds.push(id);
                return ({
                    id,
                    coordinates: c,
                    properties: {
                        time: data.properties.times[index]
                    }
                });
            });

            setStrikes(strikes => [...strikes, ...newStrikes]);

            timeout.current = setTimeout(() => {
                // After 5 minutes, we remove the strikes
                setStrikes(strikes => strikes.filter(s => !(newStrikesIds.includes(s.id))));
                timeout.current = null;
            }, 5 * 60000);
        }
        getData();
    }, [url]);

    useEffect(() => {
        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        }
    }, []);

    useEffect(() => {
        return () => {
            if (listenerKey.current) unByKey(listenerKey.current);
        }
    }, []);

    return (
        <RContext.Consumer>
            {({ map }) => {
                if (map) {
                    const tilelayer = map.getLayers().getArray().find(l => l.get('id') === 'tilelayer');
                    return (
                        <VectorLayer
                            id={`${productId}-weather-layer`}
                            component={RLayerVectorImage}
                            zIndex={zIndex}
                            visible={visible}
                            onAddFeature={(e: any) => { if (visible) flash(e, map, tilelayer) }}
                            style={style}
                            opacity={opacity}
                        >
                            {
                                strikes.map((s: StrikeType) => {
                                    return (
                                        <Strike
                                            key={s.id}
                                            id={s.id}
                                            coordinates={s.coordinates}
                                            properties={s.properties}
                                        />
                                    )
                                })
                            }
                        </VectorLayer>
                    );
                }
                return null;
            }
            }
        </RContext.Consumer>
    );

};