import { Fill, Stroke, Style, Text, Icon } from "ol/style";
import { getCoverSymbol, getWindSymbol } from "./symbols";
import { isBackgroundDark } from "../../../../config/map";

export const metarStyle = (feature: any, _resolution: any) => {

    const styles = [];
    const fill_color = isBackgroundDark() ? "white" : "black";
    const stroke_color = isBackgroundDark() ? "black" : "white";
    const {
        wind_speed_kt,
        'sky_cover.2': sky_cover_2,
        'sky_cover.1': sky_cover_1,
        sky_cover,
        flight_category,
        temp_c,
        visibility_statute_mi,
        dewpoint_c,
        altim_in_hg,
        station_id,
        'cloud_base_ft_agl.2': cloud_base_ft_agl_2,
        'cloud_base_ft_agl.1': cloud_base_ft_agl_1,
        cloud_base_ft_agl
    } = feature.get('metar');

    const windSymbol = getWindSymbol(wind_speed_kt, fill_color);
    if (windSymbol) {
        const wind = new Style({
            image: new Icon({
                src:
                    "data:image/svg+xml;charset=utf-8," +
                    encodeURIComponent(windSymbol),
                scale: wind_speed_kt < 3 ? 0.12 : 0.2,
                anchor: wind_speed_kt < 3 ? [0.5, 0.5] : [0.95, 0.65],
                rotation: (wind_speed_kt + 90) * Math.PI / 180
            })
        });
        styles.push(wind);
    };

    const cover_cat = sky_cover_2 ? sky_cover_2 : sky_cover_1 ? sky_cover_1 : sky_cover ? sky_cover : 'UNDEF';
    const cover = new Style({
        image: new Icon({
            src:
                "data:image/svg+xml;charset=utf-8," +
                encodeURIComponent(
                    getCoverSymbol(cover_cat, flight_category)
                ),
            scale: cover_cat === 'UNDEF' ? 0.08 : 0.3,
        })
    });
    styles.push(cover);

    const temp = new Style({
        text: new Text({
            text: temp_c?.toString(),
            font: "bold 10px sans-serif",
            offsetX: -15,
            offsetY: -15,
            fill: new Fill({ color: fill_color }),
            stroke: new Stroke({
                color: stroke_color,
                width: 2
            }),
        })
    });
    styles.push(temp);

    /*const wx = new Style({
      image: new Icon({
        src:
          "data:image/svg+xml;charset=utf-8," +
          encodeURIComponent(
            getWeatherSymbol(fill_color)
          ),
        scale: 0.4,
        anchor: [1.5, 0.5]
      })
    });
    styles.push(wx);*/

    const vis = new Style({
        text: new Text({
            text: visibility_statute_mi?.toString(),
            font: "bold 10px sans-serif",
            offsetX: -40,
            offsetY: 0,
            fill: new Fill({ color: isBackgroundDark() ? 'lightgrey' : 'grey' }),
            stroke: new Stroke({
                color: stroke_color,
                width: 2
            }),
        })
    });
    styles.push(vis);

    const dew = new Style({
        text: new Text({
            text: dewpoint_c?.toString(),
            font: "bold 10px sans-serif",
            offsetX: -15,
            offsetY: 15,
            fill: new Fill({ color: isBackgroundDark() ? 'lightblue' : 'blue' }),
            stroke: new Stroke({
                color: stroke_color,
                width: 2
            }),
        })
    });
    styles.push(dew);

    const altim_value = altim_in_hg;
    if (altim_value) {
        const alt = new Style({
            text: new Text({
                text: (altim_in_hg.toFixed(2) * 100)?.toString().slice(-3),
                font: "bold 10px sans-serif",
                offsetX: 25,
                offsetY: -15,
                fill: new Fill({ color: fill_color }),
                stroke: new Stroke({
                    color: stroke_color,
                    width: 2
                }),
            })
        });
        styles.push(alt);
    }

    const icao = new Style({
        text: new Text({
            text: station_id,
            font: "bold 10px sans-serif",
            offsetX: 25,
            offsetY: 15,
            fill: new Fill({ color: fill_color }),
            stroke: new Stroke({
                color: stroke_color,
                width: 2
            }),
        })
    });
    styles.push(icao);

    const ceiling_value =
        (cloud_base_ft_agl_2 ? cloud_base_ft_agl_2 : cloud_base_ft_agl_1 ? cloud_base_ft_agl_1 : cloud_base_ft_agl ? cloud_base_ft_agl : 0) / 100;

    if (ceiling_value) {
        const ceiling = new Style({
            text: new Text({
                text: ceiling_value.toString(),
                font: "bold 10px sans-serif",
                offsetX: 25,
                offsetY: 0,
                fill: new Fill({ color: isBackgroundDark() ? 'lightgrey' : 'grey' }),
                stroke: new Stroke({
                    color: stroke_color,
                    width: 2
                }),
            })
        });
        styles.push(ceiling);
    }

    return styles;

};