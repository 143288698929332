import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import authReducer from '../redux/auth/authSlice';
import mqttReducer, { mqttActions } from '../redux/mqtt/mqttSlice';
import mqttMiddleware from '../redux/mqtt/mqttMiddleware';
import missionReducer from '../redux/mission/missionSlice';
import mapReducer from '../redux/map/mapSlice';
import chatReducer from '../redux/chat/chatSlice';
import trackingReducer from '../redux/tracking/trackingSlice';
import geomarkerReducer, { geomarkersActions } from '../redux/geomarkers/geomarkersSlice';
import aeroReducer from '../redux/aero/aeroSlice';
import weatherReducer from '../redux/weather/weatherSlice';
import telemetryReducer from '../redux/telemetry/telemetrySlice';
import widgetReducer from '../redux/widget/widgetSlice';
import toastReducer from '../redux/toast/toastSlice';
import transferReducer, { transferActions } from '../redux/transfer/transferSlice';
import customReducer, { customActions } from '../redux/custom/customSlice';
import replayReducer from '../redux/replay/replaySlice';
import rootSaga from './sagas';
import { apiQueryErrorHandler } from '../redux/middleware/apiQueryErrorHandler';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    mqtt: mqttReducer,
    chat: chatReducer,
    mission: missionReducer,
    map: mapReducer,
    nav: combineReducers({
      tracking: trackingReducer,
      geomarkers: geomarkerReducer,
      aero: aeroReducer,
      weather: weatherReducer,
      custom: customReducer,
    }),
    telemetry: telemetryReducer,
    widget: widgetReducer,
    toast: toastReducer,
    transfer: transferReducer,
    replay: replayReducer
  },
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [mqttActions.publish, transferActions.post_transfer.type, geomarkersActions.upload_geomarkers.type, customActions.add.type] // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    },
  }).concat([sagaMiddleware, mqttMiddleware, apiQueryErrorHandler]),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;