// SVG icons from https://commons.wikimedia.org/wiki/Weather_map

export const COVER_SYMBOLS = [
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <circle style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;" r="28" cx="32" cy="32"/>
     </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <circle style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;" r="28" cx="32" cy="32"/>
        <path style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;" d="M32,4 v56"/>
     </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <circle style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;" r="28" cx="32" cy="32"/>
        <path style="fill:${color ? color : FLIGHT_CAT.VFR};" d="M32,4 v28 h28 a28,28 0 0,0 -28,-28 z"/>
     </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <circle style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;" r="28" cx="32" cy="32"/>
        <path style="fill:${color ? color : FLIGHT_CAT.VFR};" d="M32,4 v28 h28 a28,28 0 0,0 -28,-28 z"/>
        <path style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;" d="M32,4 v56"/>
     </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <circle style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;" r="28" cx="32" cy="32"/>
        <path style="fill:${color ? color : FLIGHT_CAT.VFR};" d="M32,4 v56 a28,28 0 0,0 0,-56 z"/>
     </svg>
     `,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <g style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;">
        <circle r="28" cx="32" cy="32"/>
        <path d="M4,32 h56"/>
        </g>
        <path style="fill:${color ? color : FLIGHT_CAT.VFR};" d="M32,4 v56 a28,28 0 0,0 0,-56 z"/>
     </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <g style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;">
        <circle r="28" cx="32" cy="32"/>
        </g>
        <path style="fill:${color ? color : FLIGHT_CAT.VFR};" d="M32,4 v28 h-28 a28,28 0 1,0 28,-28 z"/>
     </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <g style="fill:${color ? color : FLIGHT_CAT.VFR}; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;">
        <circle r="28" cx="32" cy="32"/>
        </g>
        <path style="stroke:white; stroke-width:5;" d="M32,7 v50"/>
     </svg>
     `,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="0 0 64 64">
        <g style="fill:${color ? color : FLIGHT_CAT.VFR}; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;">
        <circle r="28" cx="32" cy="32"/>
        </g>
     </svg>
     `,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64" viewBox="-32 -32 64 64">
        <g style="fill:none; stroke:${color ? color : FLIGHT_CAT.VFR}; stroke-width:5;">
        <circle r="28"/>
        <path d="M19.8,19.8 L-19.8,-19.8 M19.8,-19.8 L-19.8,19.8"/>
        </g>
     </svg>`,
    (color: string) => `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="0 0 14.0625 14.0625">
        <g fill="${color ? color : "#4d4d4d"}">
        <path style="stroke-width:10" d="M 5.9337612,4.8252116 H 4.5073677 V 9.0676912 H 6.0216673 V 7.0546335 l 0.9401492,0.9401492 0.1063015,0.1058914 0.00122,-0.00122 2.1e-4,2.1e-4 1.0450403,-1.0450403 v 2.0130674 h 1.5143 V 4.8252116 H 8.2024948 L 7.068118,5.9595484 Z M 2.6949153,7.621822 H 0.67796611 c -0.31810165,0 -0.57627111,-0.2581695 -0.57627111,-0.5762712 0,-0.3181017 0.25816946,-0.5762712 0.57627111,-0.5762712 H 2.6949153 c 0.3181017,0 0.5762712,0.2581695 0.5762712,0.5762712 0,0.3181017 -0.2581695,0.5762712 -0.5762712,0.5762712 z m 4.3220338,6.338983 c -0.3181017,0 -0.5762712,-0.25817 -0.5762712,-0.576271 v -2.016949 c 0,-0.318102 0.2581695,-0.576272 0.5762712,-0.576272 0.3181017,0 0.5762712,0.25817 0.5762712,0.576272 v 2.016949 c 0,0.318101 -0.2581695,0.576271 -0.5762712,0.576271 z M 13.355932,7.621822 h -2.016949 c -0.318102,0 -0.576271,-0.2581695 -0.576271,-0.5762712 0,-0.3181017 0.258169,-0.5762712 0.576271,-0.5762712 h 2.016949 c 0.318102,0 0.576271,0.2581695 0.576271,0.5762712 0,0.3181017 -0.258169,0.5762712 -0.576271,0.5762712 z M 7.0169491,3.2997881 c -0.3181017,0 -0.5762712,-0.2581694 -0.5762712,-0.5762711 V 0.70656782 c 0,-0.31810164 0.2581695,-0.5762712 0.5762712,-0.5762712 0.3181017,0 0.5762712,0.25816956 0.5762712,0.5762712 V 2.723517 c 0,0.3181017 -0.2581695,0.5762711 -0.5762712,0.5762711 z m 0,9.6525419 c -3.2570847,0 -5.9067796,-2.649694 -5.9067796,-5.9067792 0,-3.2570847 2.6496949,-5.9067796 5.9067796,-5.9067796 3.2570849,0 5.9067799,2.6496949 5.9067799,5.9067796 0,3.2570852 -2.649695,5.9067792 -5.9067799,5.9067792 z m 0,-10.6610164 c -2.6214576,0 -4.7542372,2.1327796 -4.7542372,4.7542372 0,2.6214576 2.1327796,4.7542372 4.7542372,4.7542372 2.6214576,0 4.7542369,-2.1327796 4.7542369,-4.7542372 0,-2.6214576 -2.1327793,-4.7542372 -4.7542369,-4.7542372 z"/>
        </g>
     </svg>`,
];

export const COVER_CAT: { [key: string]: number } = {
    SKC: 0,
    CLR: 0,
    CAVOK: 0,
    FEW: 1,
    SCT: 2,
    BKN: 6,
    OVC: 8,
    OVX: 9,
    UNDEF: 10,
};

export const FLIGHT_CAT: { [key: string]: string } = {
    LIFR: 'magenta',
    IFR: 'red',
    MVFR: 'blue',
    VFR: 'green'
};

export const getCoverSymbol = (cover_cat: number, flight_cat: string) => {
    const color = FLIGHT_CAT[flight_cat];
    return COVER_SYMBOLS[COVER_CAT[cover_cat]](color);
};

export const WIND_SYMBOLS = [
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-25 -25 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <circle r="18"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40"/>
        <path d="M -35,0 l -2.5,-8.5"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        <path d="M -35,0 l -2.5,-8.5"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        <path d="M -35,0 l -5,-17"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        <path d="M -35,0 l -5,-17"/>
        <path d="M -30,0 l -2.5,-8.5"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        <path d="M -35,0 l -5,-17"/>
        <path d="M -30,0 l -5,-17"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        <path d="M -35,0 l -5,-17"/>
        <path d="M -30,0 l -5,-17"/>
        <path d="M -25,0 l -2.5,-8.5"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        <path d="M -35,0 l -5,-17"/>
        <path d="M -30,0 l -5,-17"/>
        <path d="M -25,0 l -5,-17"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40 l -5,-17"/>
        <path d="M -35,0 l -5,-17"/>
        <path d="M -30,0 l -5,-17"/>
        <path d="M -25,0 l -5,-17"/>
        <path d="M -20,0 l -2.5,-8.5"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <path style="fill:${color}; stroke:none; stroke-width:0;" d="M -44,1 L -44,0 L -39,-17.25 L -34,0 L -34,1"/>
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <path style="fill:${color}; stroke:none; stroke-width:0;" d="M -44,1 L -44,0 L -39,-17.25 L -34,0 L -34,1"/>
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40"/>
        <path d="M -30,0 l -2.5,-8.5"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <path style="fill:${color}; stroke:none; stroke-width:0;" d="M -44,1 L -44,0 L -39,-17.25 L -34,0 L -34,1"/>
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40"/>
        <path d="M -30,0 l -5,-17"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <path style="fill:${color}; stroke:none; stroke-width:0;" d="M -44,1 L -44,0 L -39,-17.25 L -34,0 L -34,1"/>
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40"/>
        <path d="M -30,0 l -5,-17"/>
        <path d="M -25,0 l -2.5,-8.5"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:${color}; stroke:none; stroke-width:0;">
        <path d="M -44,1 L -44,0 L -39,-17.25 L -34,0 L -34,1"/>
        <path d="M -34,1 L -34,0 L -29,-17.25 L -24,0 L -24,1"/>
        </g>
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40"/>
        </g>
    </svg>`,
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="200" height="200" viewBox="-48 -33 50 50">
        <g style="fill:${color}; stroke:none; stroke-width:0;">
        <path d="M -44,1 L -44,0 L -39,-17.25 L -34,0 L -34,1"/>
        <path d="M -34,1 L -34,0 L -29,-17.25 L -24,0 L -24,1"/>
        </g>
        <g style="fill:none; stroke:${color}; stroke-width:2;">
        <path d="M 0,0 h -40"/>
        <path d="M -20,0 l -2.5,-8.5"/>
        </g>
    </svg>`
];

export const getWindSymbol = (ws: number, color: string) => {
    if (ws < 3) return WIND_SYMBOLS[0](color);
    else if (ws >= 3 && ws <= 7) return WIND_SYMBOLS[1](color);
    else if (ws >= 8 && ws <= 12) return WIND_SYMBOLS[2](color);
    else if (ws >= 13 && ws <= 17) return WIND_SYMBOLS[3](color);
    else if (ws >= 18 && ws <= 22) return WIND_SYMBOLS[4](color);
    else if (ws >= 23 && ws <= 27) return WIND_SYMBOLS[5](color);
    else if (ws >= 28 && ws <= 32) return WIND_SYMBOLS[6](color);
    else if (ws >= 33 && ws <= 37) return WIND_SYMBOLS[7](color);
    else if (ws >= 38 && ws <= 42) return WIND_SYMBOLS[8](color);
    else if (ws >= 43 && ws <= 47) return WIND_SYMBOLS[9](color);
    else if (ws >= 48 && ws <= 52) return WIND_SYMBOLS[10](color);
    else if (ws >= 53 && ws <= 57) return WIND_SYMBOLS[11](color);
    else if (ws >= 58 && ws <= 62) return WIND_SYMBOLS[12](color);
    else if (ws >= 63 && ws <= 67) return WIND_SYMBOLS[13](color);
    else if (ws >= 68) return WIND_SYMBOLS[14](color);

};

export const WEATHER_SYMBOLS = [
    (color: string) => `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="55" height="55" viewBox="-27.5 -27.5 55 55">
        <g transform="translate(-9.5,0)">
        <g id="ww_70">
        <path id="ww70arm" d="M 0,-5.5 v11" stroke="${color}" stroke-linecap="round" stroke-width="3" />
        <use xlink:href="#ww70arm" transform="rotate(60)" />
        <use xlink:href="#ww70arm" transform="rotate(120)" />
        </g>
        </g>
        <use xlink:href="#ww_70" x="9.5" />
    </svg>`
];

export const getWeatherSymbol = (color: string) => {
    return WEATHER_SYMBOLS[0](color);
};