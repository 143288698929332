import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { authActions } from '../auth/authSlice';

export interface MissionConfigType {
    weather?: any; // TODO
    tracking?: any; // TODO
    telemetry?: any; // TOO
};

export interface VehicleType {
    id: string;
    name: string;
    kind: string;
    virtual?: boolean;
    in_mission: string;
};

export interface MissionType {
    id: string;
    name: string;
    codec: string;
    fleet: VehicleType[];
    config: MissionConfigType;
};

export interface MissionState {
    gettingMissions: Boolean;
    gettingMissionsError: string | null;
    allowed: { [key: string]: MissionType };
    active: string | null;
    activeConfig: any | null;
};

// TODO : changed and newMission from the platin_v1 state should be replaced by something check diff with previous values
const initialState: MissionState = {
    gettingMissions: false,
    gettingMissionsError: null,
    allowed: {},
    active: null,
    activeConfig: null,
};

const missionSlice = createSlice({
    name: 'mission',
    initialState,
    reducers: {
        get_missions: ((state, action) => {
            state.gettingMissions = true;
            state.gettingMissionsError = null;
            state.allowed = {};
            state.active = null;
        }),
        get_missions_success: ((state, action) => {
            state.gettingMissions = false;
            state.allowed = Object.fromEntries(action.payload.map((m: MissionType) => [m.id, m]));
        }),
        get_missions_failure: ((state, action) => {
            state.gettingMissions = false;
            state.gettingMissionsError = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        set_active_mission: ((state, action) => {
            state.active = action.payload.missionId;
        }),
        set_active_config: ((state, action) => {
            state.activeConfig = action.payload.config;
        }),
        new_mission_message: ((state, action) => {
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(authActions.logout_success, state => {
            Object.assign(state, initialState);
        });
    }
});

export const selectMission = (state: RootState) => state.mission;

export const missionActions = missionSlice.actions;

export default missionSlice.reducer;
