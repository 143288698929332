import { tcaCompute, tcaComputeInit } from "./TcaCompute";
import { dummyCompute, dummyComputeInit} from "./DummyCompute";

export type moduleKeyType = "tca_compute" | "dummy_compute";

// compute function arguments : (position: [lon: number, lat: number], alt: number, localOrigin: [lon: number, lat: number, alt: number])
// init function arguments : (history: [[timestamp: number], [{position: [lon: number, lat: number], gps_msl_alt: number}]], localOrigin: [lon: number, lat: number, alt: number])
// further arguments may be added in the future for new modules
export const computeModules = {
    tca_compute: {compute: tcaCompute, init: tcaComputeInit, params: ["position", "gps_msl_alt"]},
    dummy_compute: {compute: dummyCompute, init: dummyComputeInit, params: ["position", "gps_msl_alt"]}
};