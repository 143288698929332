import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import GroupLayer from './layers/GroupLayer';
import EditLayer from './layers/EditLayer';
import DrawLayer from './layers/DrawLayer';

export default function Geomarker({ zIndex }: { zIndex: number }) {
    const groups = useSelector((state: RootState) => state.nav.geomarkers.groups);

    return (
        <>
            {Object.entries(groups).map(([id, group]) =>
                <GroupLayer key={id} id={id} zIndex={zIndex} {...group} />
            )}
            <EditLayer visible={true} zIndex={zIndex} />
            <DrawLayer zIndex={zIndex} />
        </>
    );
};