import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { authActions } from '../../../redux/auth/authSlice';

const InvalidToken = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ id, ...props }, ref) => {
        const { closeSnackbar } = useSnackbar();
        const dispatch = useDispatch();

        const onClose = () => {
            closeSnackbar(id);
            dispatch(authActions.logout({}));
        };

        return (
            <SnackbarContent ref={ref}>
                <Alert
                    severity="warning"
                    sx={{ alignItems: 'center' }}
                >
                    <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">
                            Invalid token. Login is required again.
                        </Typography>
                        <IconButton
                            size="large"
                            onClick={onClose}
                        >
                            <LogoutIcon fontSize='inherit' />
                        </IconButton>
                    </Stack>
                </Alert>
            </SnackbarContent>
        );
    }
);

export default InvalidToken;