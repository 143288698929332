import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from 'usehooks-ts';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import WidgetTemplate from "../widgets/WidgetTemplate";
import { RootState } from "../../app/store";
import { ConversationType } from '../../redux/chat/chatSlice';
import Messages from "./Messages";
import BottomBar from "./BottomBar";
import { widgetActions } from "../../redux/widget/widgetSlice";
import isEqual from 'lodash.isequal';
import { messagesSelector } from '../chat/ChatRoom';

function ConversationSelector({ gridId, widgetId, conversationId }: { gridId: string, widgetId: string, conversationId: string | undefined }) {
    const dispatch = useDispatch();
    const conversations = useSelector((state: RootState) => state.chat.conversations.list);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(widgetActions.set_widget_params({ gridId, id: widgetId, params: { conversationId: e.target.value } }));
    };

    if (!conversationId) return null;
    return (
        <TextField
            select
            value={conversationId}
            onChange={onChange}
            variant="outlined"
            size="small"
            SelectProps={{
                native: true,
            }}
        >
            {conversations.map((c: ConversationType) => <option key={c.id} value={c.id}>{c.name}</option>)}
        </TextField>
    );
};

const ChatWidget = React.memo((props: any) => {
    const dispatch = useDispatch();
    const { id, gridId, reduced, params, width, height } = props;
    const conversation = useSelector((state: RootState) => state.chat.conversations.list.find((c: ConversationType) => c.id === params.conversationId));
    const messages = useSelector((state: RootState) => messagesSelector(state, { conversation }), isEqual);

    useEffectOnce(() => {
        if (!conversation) dispatch(widgetActions.remove_widget({ gridId, id }));
    });

    if (!conversation) return null;
    return (
        <WidgetTemplate
            id={id}
            gridId={gridId}
            header={`#${conversation?.name}`}
            actions={[
                <ConversationSelector gridId={gridId} widgetId={id} conversationId={conversation?.id} />
            ]}
            width={width}
            height={height}
            reduced={reduced}
        >
            <Stack sx={{ width: '100%', height: '100%' }} direction="column" spacing={0.5}>
                <Messages conversation={conversation} messages={messages} widget={{gridId, widgetId: id}}/>
                <BottomBar conversation={conversation} mobile={true}/>
            </Stack>
        </WidgetTemplate>
    );
});

export default ChatWidget;
