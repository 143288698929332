import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RFeature, RInteraction } from "rlayers";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "../../map/layers/VectorLayer";
import { RootState } from "../../../../app/store";
import { geomarkersActions, GeomarkerType } from "../../../../redux/geomarkers/geomarkersSlice";
import { store } from "../../../../app/store";
import editStyle from "../styles/edit";

function Modify(props: any) {
    /* Couldn't do directly <RInteraction.RModify onModifyEnd={onModifyEnd} />;
    ** Because onModifyEnd is not defined in the input props of RModify (cf: https://mmomtchev.github.io/rlayers/api/#interactions)
    ** Thus typescript won't accept this property.
    ** However, it's stated somewhere in rlayers doc that we can create callback for any openlayers event
    ** by adding an on${eventName} callback to the component.
    ** Doing onModifyEnd={...} allows to add a callback on modify-end openlayers event, triggered by Interactions.
    */
    return <RInteraction.RModify {...props} />;
};

const Geomarker = React.memo(function Geomarker({ geomarker }: { geomarker: GeomarkerType }) {
    const proj = store.getState().map.projection;
    const geometry = new GeoJSON({ featureProjection: proj }).readGeometry(geomarker.feature.geometry);
    const properties = { ...geomarker.feature.properties, id: geomarker.id };

    return (<RFeature geometry={geometry} properties={properties} />);
});

export default function EditLayer({ visible, zIndex }: { visible: boolean, zIndex: number }) {
    const dispatch = useDispatch();
    const edit = useSelector((state: RootState) => state.nav.geomarkers.edit);
    const enable_gm_measures = useSelector((state: RootState) => state.map.enable_gm_measures);

    const onModifyEnd = useCallback((e: any) => {
        const proj = store.getState().map.projection;
        const feature = new GeoJSON().writeFeatureObject(
            e.features.array_[0],
            { featureProjection: proj }
        );
        dispatch(geomarkersActions.update_edited_gm_coords({id: feature.properties?.id, coordinates: (feature.geometry as any).coordinates}));
    }, [dispatch]);

    const style = useCallback((f: any, res: any) => {
        return editStyle(f, res, enable_gm_measures);
    }, [enable_gm_measures]);

    return (
        <VectorLayer
            id="geomarker-edit-layer"
            zIndex={zIndex}
            visible={visible}
            style={style}
        >
            {edit.map((g: GeomarkerType) => <Geomarker key={g.id} geomarker={g} />)}
            <Modify onModifyEnd={onModifyEnd} />
        </VectorLayer>
    );
};