import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Loading from "./Loading";
import { RootState } from "../../app/store";
import { missionActions } from "../../redux/mission/missionSlice";

export default function LoadingMissions(props: any) {
    const dispatch = useDispatch();
    const mission = useSelector((state: RootState) => state.mission);

    useEffect(() => {
        if (Object.keys(mission.allowed).length > 0) {
            let missionIdFromLS = localStorage.getItem("mission");
            let missionId = null;
            if (missionIdFromLS && missionIdFromLS in mission.allowed) missionId = missionIdFromLS;
            else missionId = Object.keys(mission.allowed)[0];
            dispatch(missionActions.set_active_mission({ missionId }));
        }
    }, [dispatch, mission.allowed]);

    if (mission.active) return <Redirect to={props.location.state ? props.location.state.from : `/${mission.active}/dashboard/`} />;
    if (mission.gettingMissionsError) return <Redirect to={{ pathname: "/error", state: { title: "gettingMissionsError", message: mission.gettingMissionsError } }} />;
    return <Loading />;
};
