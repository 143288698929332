export function formatError(action: any) {
    if (action.status) {
        if (action.text || (action.payload && Object.values(action.payload)[0])) {
            return action.status + (action.text ? " " + action.text : "") + (action.data && Object.values(action.data)[0] ? " : " + Object.values(action.data)[0] : "");
        } else {
            return action.status + " : Uknown error";
        }
    } else {
        return "Uknown error";
    }
};