import uPlot from 'uplot';
import { alpha } from '@mui/material/styles';
import StringUtils from '../../utils/StringUtils';
import { TStooltipPlugin, onHoverPlugin } from './plugins';

export const options = (ids: string[], showLegend: boolean = true, onHover?: (props: any) => void) => {

    let plugins: any[] = [TStooltipPlugin()];
    if (onHover) plugins = [...plugins, onHoverPlugin({ onHover })];

    return ({
        legend: {
            show: showLegend
        },
        focus: {
            alpha: 0.5
        },
        cursor: {
            focus: {
                prox: 30
            },
            drag: {
                x: false,
                y: false,
                setScale: false
            }
        },
        axes: [
            {
                stroke: "white",
                grid: {
                    show: true,
                    stroke: "rgba(211, 211, 211, 0.2)",
                    width: 1,
                    dash: [10, 10],
                },
                space: 40,
                incrs: [
                    // minute divisors (# of secs)
                    1,
                    5,
                    10,
                    15,
                    30,
                    // hour divisors
                    60,
                    60 * 5,
                    60 * 10,
                    60 * 15,
                    60 * 30,
                    // day divisors
                    3600,
                    // ...
                ],
                values: [
                    // tick incr        default           year                             month    day                        hour     min                sec       mode
                    [3600, "{HH}", null, null, null, null, null, null, 1],
                    [60, "{HH}:{mm}", null, null, null, null, null, null, 1],
                    [1, "{HH}:{mm}\n{ss}", null, null, null, null, null, null, 1]
                ],
            },
            {
                stroke: "white",
                size: 80
            }
        ],
        tzDate: (ts: any) => uPlot.tzDate(new Date(ts * 1e3), 'UTC'),
        series: [
            {},
            ...ids.map((id: string) => ({
                id,
                label: id,
                value: (u: any, v: any) => (v == null ? "-" : v),
                stroke: StringUtils.stringToColor(id),
                fill: alpha(StringUtils.stringToColor(id), 0.05),
                width: 3,
                spanGaps: false,
            }))
        ],
        plugins,
    });
};