import * as React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffectOnce } from 'usehooks-ts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { toastActions, ToastType } from '../../redux/toast/toastSlice';
import { geomarkersActions } from '../../redux/geomarkers/geomarkersSlice';

function Toasts({ containerId, toastComponent: Toast, onUnmount }: { containerId: string, toastComponent: any, onUnmount?: () => void }) {
    const dispatch = useDispatch();
    const toasts = useSelector((state: RootState) => state.toast[containerId]);
    const { closeSnackbar } = useSnackbar();

    useEffectOnce(() => {
        return () => {
            closeSnackbar();
            onUnmount?.();
            dispatch(toastActions.remove_toast_container({ containerId }))
        }
    });

    return (
        <>
            {toasts &&
                toasts.map((t: ToastType) =>
                    <Toast key={t.id} containerId={containerId} {...t} />
                )
            }
        </>
    )
};

export default function ToastContainer(props: any) {
    const { id, onUnmount, toastComponent, components } = props;
    const dispatch = useDispatch();

    const onClose = (event: any, reason: any, myKey: any) => {
        if (reason === "maxsnack") {
            const gm_info_prefix = "geomarker-info-";
            if (myKey.startsWith(gm_info_prefix)) {
                dispatch(geomarkersActions.unclick({id: myKey.replace(gm_info_prefix, "")}))
            };
        };
    };

    return (
        <SnackbarProvider maxSnack={props.maxSnack} Components={components} onClose={onClose}>
            <Toasts containerId={id} toastComponent={toastComponent} onUnmount={onUnmount} />
        </SnackbarProvider>
    );
};