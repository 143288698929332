import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Badge from '@mui/material/Badge';
import ForumIcon from '@mui/icons-material/Forum';
import { ConversationsType } from '../../redux/chat/chatSlice';
import { RootState } from "../../app/store";

const selectUnreadMessageCount = createSelector(
    (state: RootState) => state.chat.conversations,
    (convs: ConversationsType) => convs.list.map((c: any) => c.unread)
        .reduce(function (a, b) {
            return a + b;
        }, 0)
);

export default function ChatIcon() {
    const unreadMessageCount = useSelector(selectUnreadMessageCount);
    const highlightBuffer = useRef<ReturnType<typeof setInterval> | null>(null);
    const [highlight, setHighlight] = useState(false);

    useEffect(() => {
        if (!highlight && !highlightBuffer.current && unreadMessageCount) {
            highlightBuffer.current = setTimeout(() => { setHighlight(true); highlightBuffer.current = null; }, 250);
        } else if (highlight && !unreadMessageCount) {
            setHighlight(false);
        } else if (highlightBuffer.current && !highlight && !unreadMessageCount) {
            clearTimeout(highlightBuffer.current);
            highlightBuffer.current = null;
        }
    }, [highlight, unreadMessageCount]);

    return (
        <Badge
            color="error"
            variant="dot"
            invisible={!highlight}
        >
            <ForumIcon />
        </Badge>
    );
};