import { OnMessageCallback } from 'mqtt';
import { store } from '../../app/store';
import { chatActions } from './chatSlice';
import { toDate } from '../../utils/TimeUtils';

export const chatMessageMqttParser: OnMessageCallback = (topic: any, payload: any, packet: any): void => {
    store.dispatch(chatActions.add_message(JSON.parse(payload.toString())));
};

export const chatConversationMqttParser: OnMessageCallback = (topic: any, payload: any, packet: any): void => {
    const { creator_id, id, delete: deleteDate, conv_kind, name } = JSON.parse(payload.toString());
    if (toDate(deleteDate) <= toDate(Date.now() / 1000)) {
        store.dispatch(chatActions.archive_conversation_success({ id }));
    } else {
        store.dispatch(chatActions.create_conversation_success({
            creator_id,
            id,
            delete: deleteDate,
            conv_kind,
            name
        }));
    }
};