import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import merge from "deepmerge";
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { RootState } from '../../../app/store';
import StringUtils from '../../../utils/StringUtils';
import { PlotType } from './TimeSeriesTab';

interface ParamType {
    source: string;
    label: string;
    param: string;
    unit: string;
    toFixed: number;
};

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

type ColumnId = 'source' | 'label' | 'param';

interface Column {
    id: ColumnId;
    label: string;
    minWidth?: number;
    format?: (source: string) => any;
    align?: 'right' | 'left';
};

const columns: readonly Column[] = [
    {
        id: 'source',
        label: 'Source',
        minWidth: 80,
        format: (source) => <SourceName source={source} />
    },
    { id: 'label', label: 'Label', minWidth: 80, align: 'left' },
    { id: 'param', label: 'Param', minWidth: 50, align: 'left' },
];

const SourceName = React.memo(function SourceName({ source }: { source: string }) {
    const name = useSelector((state: RootState) => {
        if (!state.mission.active) return 'UNDEF';
        else return state.mission.allowed[state.mission.active].fleet.find((v: any) => v.id === source)?.name;
    });
    return (<Typography variant="body1" sx={{ fontSize: '1.2rem', whiteSpace: "nowrap" }}>{name}</Typography>);
});

export function ParamsTable({ filter, exclude = [], onClick }: { filter: string, exclude?: string[], onClick?: (param: PlotType) => void }) {
    const availableParams: ParamType[] = useSelector((state: RootState) => {
        let to_return: ParamType[] = [];
        Object.entries(state.telemetry.streams).forEach(([source, labels]) => {
            const cfg = merge(state.telemetry.config?.default || {}, state.telemetry.config?.[source] || {});
            Object.entries(labels).forEach(([label, [_timestamps, values]]) => {
                let last = values[values.length - 1];
                const labelCfg = cfg?.streams?.[label];
                if (last) {
                    Object.keys(last).filter((param: string) => param !== "position").forEach((param) => {
                         let paramConfig = labelCfg ? labelCfg[param] : null;
                        let toFixed = paramConfig?.toFixed || 2;
                        let unit = paramConfig?.unit || "";
                        to_return.push({ source, label, param, unit, toFixed });
                    })
                }
            })
        })
        return to_return;
    });

    const rows = availableParams
        .filter((p: ParamType) => !exclude.includes(`${p.source}-${p.label}-${p.param}`))
        .filter((p: ParamType) => filter ? (p.source.includes(filter) || p.label.includes(filter) || p.param.includes(filter)) : p);

    const onRowClick = (row: { source: string, label: string, param: string, unit: string, toFixed: number }) => {
        onClick?.({ ...row, color: StringUtils.stringToColor(row.param), scale: 'linear', autoscale: true, min: -Infinity, max: Infinity, shown: true });
    };

    return (
        <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader size={'small'}>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        return (
                            <TableRow key={`${row.source}-${row.label}-${row.param}`} sx={{ cursor: 'pointer' }} hover role="checkbox" tabIndex={-1} onClick={() => onRowClick(row)}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.format
                                                ? column.format(row.source)
                                                :
                                                value
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default function ParamSelectDialog({ open, confirm, close, exclude }: { open: boolean, confirm: (param: PlotType) => void, close: () => void, exclude?: string[] }) {
    const [filter, setFilter] = useState('');

    const onClick = (param: PlotType) => {
        confirm(param);
    };

    useEffect(() => {
        return () => setFilter('');
    }, [open, setFilter]);

    return (
        <Dialog open={open} maxWidth={false}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
                Choose a parameter
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e: any) => setFilter(e.target.value)}
                    />
                </Search>
            </BootstrapDialogTitle>
            <DialogContent>
                <ParamsTable filter={filter} onClick={onClick} exclude={exclude} />
            </DialogContent>
        </Dialog>
    );
};