import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CacheBuster from './CacheBuster';

Object.entries(localStorage).forEach(([k, v]) => {
  if (k.startsWith('_')) { // Convention: localStorage key starting with _ must be stringified JSON.
    try {
      JSON.parse(v)
    } catch (e) {
      console.log("Couldn't JSON.parse localStorage key", k);
      localStorage.removeItem(k);
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV === "production") {
  root.render(
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: { loading: boolean, isLatestVersion: boolean, refreshCacheAndReload: () => void }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();

          // If a new version of the application is detected, we clear the localStorage to prevent format conflicts between version.
          console.log("Clearing localStorage");
          localStorage.clear()
        }

        return (
          <React.StrictMode>
            <Provider store={store}>
              <App />
            </Provider>
          </React.StrictMode>
        );
      }}
    </CacheBuster>
  );
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
