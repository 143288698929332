import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useEffectOnce } from 'usehooks-ts';
import { useDispatch } from 'react-redux';
import { toastActions } from '../../../redux/toast/toastSlice';

export default function Toast(props: any) {
    const dispatch = useDispatch();
    const { id, containerId, autoHideDuration, message, persist, variant, severity, vertical, horizontal } = props;
    const { enqueueSnackbar } = useSnackbar();
    const toastRef = React.useRef<any>(null);

    const onExited = (e: any) => {
        dispatch(toastActions.remove_toast({ containerId, id }));
    };

    useEffectOnce(() => {
        if (toastRef.current === null) {
            toastRef.current = enqueueSnackbar(
                message, {
                key: id,
                variant,
                severity,
                anchorOrigin: { vertical: vertical || 'bottom', horizontal: horizontal || 'left' },
                autoHideDuration,
                persist,
                TransitionProps: { onExited },
                onExited
            });
        };
    });

    return null;
};
