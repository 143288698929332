import React, { useCallback } from "react";
import { RFeature } from "rlayers";
import { useDispatch, useSelector } from "react-redux";
import geomarkerStyle, { createPolygonFromCircle } from "../styles/geomarker";
import { RootState, store } from "../../../../app/store";
import VectorLayer from "../../map/layers/VectorLayer";
import GeoJSON from "ol/format/GeoJSON";
import { transform } from "ol/proj";
import { geomarkersActions, GeomarkerType } from "../../../../redux/geomarkers/geomarkersSlice";
import { FeatureType } from "../../../../redux/map/mapSlice";
import { GEOMARKER_DEFAULT_GROUP } from "../../../../config/geomarker";
import { NM_TO_M } from "../../../../utils/GeomUtils";

const Geomarker = React.memo(function Geomarker({ id, feature }: { id: string, feature: FeatureType }) {
    const dispatch = useDispatch();
    const enable_gm_measures = useSelector((state: RootState) => state.map.enable_gm_measures);
    const proj = store.getState().map.projection;
    let geometry = null;
    let center = null;
    try {
        if (feature.geometry.type === "Point" && feature.properties?.radius) {
            // We transform Cirlce to Polygon, otherwise, if managed in the style, 
            // the circle won't be displayed if the center is not in the extent
            const mapProjection = store.getState().map.projection;
            const dataProjection = "EPSG:4326";
            center = transform(feature.geometry.coordinates, dataProjection, mapProjection);
            geometry = createPolygonFromCircle(center, feature.properties.radius * NM_TO_M);
        } else {
            geometry = new GeoJSON({ featureProjection: proj }).readGeometry(feature.geometry);
        };
    } catch (error) { };

    const onSingleClick = useCallback((e: any) => {
        dispatch(geomarkersActions.click({ id }));
        return false; // prevent forwarding click event to other features
    }, [dispatch, id]);

    const onDblClick = useCallback(() => {
        dispatch(geomarkersActions.dblClick({ id }));
        return false; // prevent zooming in on double click
    }, [dispatch, id]);

    const style = useCallback((f: any, res: any) => {
        return geomarkerStyle(f, res, feature.properties?.clicked && enable_gm_measures);
    }, [feature.properties, enable_gm_measures]);

    if (feature.properties?.beingEdited) return null; // being modified gms are removed temporarly from GroupLayer
    if (!geometry) return null;
    return (
        <RFeature
            geometry={geometry}
            properties={{...feature.properties, center}}
            onSingleClick={onSingleClick}
            onDblClick={onDblClick}
            style={style}
        />
    );
});

export default function GroupLayer({ id, visible, zIndex }: { id: string, visible: boolean, zIndex: number }) {

    const geomarkers = useSelector((state: RootState) => {
        return state.nav.geomarkers.geomarkers
            .filter((gm: GeomarkerType) => (
                gm.feature.properties.group === id ||
                (id === GEOMARKER_DEFAULT_GROUP && gm.feature.properties.group === undefined)
            ));
    });

    return (
        <VectorLayer
            id={`geomarker-group-${id}-layer`}
            zIndex={zIndex}
            visible={visible}
            style={null}
            rulingSnap={true}
        >
            {geomarkers.map((g) => {
                return (
                    <Geomarker
                        key={g.id}
                        feature={g.feature}
                        id={g.id}
                    />
                );
            })}
        </VectorLayer>
    );
}