import React, { forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { useConfirm } from 'material-ui-confirm';
import DOMPurify from 'dompurify'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AdjustIcon from '@mui/icons-material/Adjust';
import TimelineIcon from '@mui/icons-material/Timeline';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LensIcon from "@mui/icons-material/Lens";
import SvgIcon from '@mui/material/SvgIcon';
import { geomarkersActions, GeomarkerType } from '../../../../redux/geomarkers/geomarkersSlice';
import { GEOMARKER_DEFAULT_GROUP } from '../../../../config/geomarker';

const escapedKeys = ["beingEdited", "clicked", "highlight", "type"];
const specialKeys: { [key: string]: any } = {
    color: (color: any) => <LensIcon sx={{ color: color }} fontSize="small" />,
    description: (description: any) => {
        const sanitized = () => ({
            __html: DOMPurify.sanitize(description)
        })
        return (<div
            dangerouslySetInnerHTML={sanitized()}
        />)
    }
};

interface InfoProps extends CustomContentProps {
    gm: GeomarkerType
};

export function getGmIcon(type: string, radius: number | undefined, sx?: any) {
    if (type === 'Point' && radius) return <AdjustIcon sx={sx} />;
    if (type === 'Point' && !radius) return <MyLocationIcon sx={sx} />;
    if (type === 'LineString') return <TimelineIcon sx={sx} />;
    if (type === 'Polygon') return (
        <SvgIcon sx={sx}>
            <path d="m 11.996434,0.65883361 c -1.310689,0 -2.373213,1.06252359 -2.373213,2.37321509 0,0.088639 0.02761,0.1734341 0.037079,0.2595704 L 3.4306114,7.9638849 C 3.1466859,7.8437884 2.831249,7.7784773 2.5035764,7.7784773 c -1.3106902,0 -2.37321468,1.0625244 -2.37321468,2.3732147 0,1.146857 0.80972152,2.114844 1.89115538,2.336134 h 0.037082 l 2.2619701,6.78591 c -0.3746177,0.419597 -0.6303852,0.950662 -0.6303852,1.557423 0,1.310691 1.0625245,2.373214 2.3732147,2.373214 0.8776199,0 1.6288647,-0.478322 2.0394813,-1.186607 h 7.78711 c 0.410617,0.708285 1.161862,1.186607 2.039481,1.186607 1.31069,0 2.373215,-1.062523 2.373215,-2.373214 0,-0.606761 -0.255768,-1.137826 -0.630386,-1.557423 l 2.261971,-6.78591 h 0.03708 c 1.081434,-0.22129 1.891156,-1.189277 1.891156,-2.336134 0,-1.3106903 -1.062525,-2.3732147 -2.373215,-2.3732147 -0.327672,0 -0.643109,0.065264 -0.927036,0.1854076 l -6.22969,-4.6722658 c 0.0094,-0.086136 0.03708,-0.1709075 0.03708,-0.2595704 0,-1.3106915 -1.062526,-2.37321509 -2.373216,-2.37321509 z M 11.069397,5.2198554 c 0.283928,0.1200966 0.599365,0.1854076 0.927037,0.1854076 0.327672,0 0.64311,-0.065263 0.927037,-0.1854076 l 6.229689,4.6351853 c -0.01229,0.097859 -0.03708,0.1954573 -0.03708,0.2966513 0,0.606761 0.255767,1.137826 0.630384,1.557422 l -2.299054,6.785911 c -0.673068,0.13773 -1.22346,0.573463 -1.557422,1.149527 H 8.1028797 C 7.768918,19.068488 7.2185249,18.632755 6.5454576,18.495025 L 4.2464059,11.709114 c 0.3746176,-0.419596 0.6303851,-0.950661 0.6303851,-1.557422 0,-0.101217 -0.02479,-0.198792 -0.037082,-0.2966513 z" />
        </SvgIcon>
    );
    return null;
};

const ToastContent = React.memo(function ToastContent({ id, type, properties, onEdit, onDelete, onClose }: { id: string, type: string, properties: any, onEdit: () => void, onDelete: () => void, onClose: () => void }) {
    return (
        <Card>
            <CardHeader
                title={<Typography variant="body1">Geomarker Props</Typography>}
                subheader={id}
                avatar={
                    <Avatar>
                        {getGmIcon(type, properties.radius)}
                    </Avatar>
                }
                action={
                    <Stack direction="row" sx={{ ml: 4 }} spacing={0} alignItems="center">
                        <IconButton size="medium" onClick={onEdit}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="medium" onClick={onDelete}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="medium" onClick={onClose}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                }
            />
            <Divider />
            <CardContent>
                <Stack direction="column" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    {
                        Object.entries(properties).map(([key, value]) => {
                            if (escapedKeys.includes(key)) return null;
                            return (
                                <Stack key={key} sx={{ width: '80%' }} direction="row" spacing={1}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div>{key}</div>
                                    <div>{specialKeys.hasOwnProperty(key) ? specialKeys[key](value) : value}</div>
                                </Stack>
                            );

                        })
                    }
                    {
                        !Object.keys(properties).includes('group') &&
                        <Stack sx={{ width: '80%' }} direction="row" spacing={1}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <div>group</div>
                            <div>{GEOMARKER_DEFAULT_GROUP}</div>
                        </Stack>
                    }
                </Stack>
            </CardContent>
        </Card>
    );
});

const ToastInfo = forwardRef<HTMLDivElement, InfoProps>(
    ({ id, ...props }, ref) => {

        const dispatch = useDispatch();
        const confirm = useConfirm();
        const { closeSnackbar } = useSnackbar();
        const { properties, geometry } = props.gm.feature;

        const onEdit = useCallback(() => {
            dispatch(geomarkersActions.start_edit({ id: props.gm.id }));
            closeSnackbar(id);
        }, [id, props.gm.id, closeSnackbar, dispatch]);

        const onDelete = useCallback(() => {
            confirm({
                confirmationButtonProps: { color: "inherit" },
                cancellationButtonProps: { color: "inherit" },
                description: `Please confirm deletion of geomarker ${props.gm.id}.`
            })
                .then(() => {
                    dispatch(geomarkersActions.delete({ id: props.gm.id }))
                    closeSnackbar(id);
                })
                .catch(() => { });
        }, [id, props.gm.id, closeSnackbar, dispatch, confirm]);

        const onClose = useCallback(() => {
            dispatch(geomarkersActions.unclick({ id: props.gm.id }));
            closeSnackbar(id);
        }, [id, props.gm.id, closeSnackbar, dispatch]);

        return (
            <SnackbarContent ref={ref}>
                <ToastContent
                    id={props.gm.id}
                    type={geometry.type}
                    properties={properties}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onClose={onClose}
                />
            </SnackbarContent>
        );
    }
);

export default ToastInfo;