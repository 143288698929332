import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { ALL_MESSAGES_CHANNEL_ID, CONVERSATION_KIND_BROADCAST } from '../../config/chat';

export interface ConversationsType {
    loading: boolean;
    loaded: boolean;
    error: null | string;
    broadcastChannels: ConversationType[];
    list: ConversationType[];
    history: any[];
};

export interface MessageType {
    id: string;
    creator_id: string;
    author: string;
    conversation: string;
    delete: string;
    created: string;
    text: string;
};

export interface ConversationType {
    id: string;
    name: string;
    conv_kind: 'public' | 'pivrate' | 'broadcast' | 'all';
    unread: number;
    created?: string;
    delete?: string;
};

interface NotificationsType {
    [key: string]: number;
};

export interface ChatState {
    conversations: ConversationsType;
    messages: MessageType[];
    messages_history: MessageType[];
    fetchMessagesError: string | null;
    active: string | null;
    notifications: NotificationsType;
    nickname: string | null;
};

const initialState: ChatState = {
    conversations: {
        loading: false,
        loaded: false,
        error: null,
        broadcastChannels: [],
        list: [],
        history: []
    },
    messages: [],
    messages_history: [],
    fetchMessagesError: null,
    active: null,
    notifications: {},
    nickname: null,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        get_conversations: ((state, action) => {
            state.conversations.loading = true;
            state.conversations.loaded = false;
            state.conversations.error = null;
            state.conversations.list = [{
                id: ALL_MESSAGES_CHANNEL_ID,
                name: 'All Messages',
                conv_kind: 'all',
                unread: 0,
            }];
        }),
        get_conversations_success: ((state, action) => {
            state.conversations.loading = false;
            state.conversations.loaded = true;
        }),
        get_conversations_history_success: ((state, action) => {
            state.conversations.loading = false;
            state.conversations.loaded = true;
            state.conversations.history = action.payload.history;
        }),
        get_conversations_failure: ((state, action) => {
            state.conversations.loading = false;
            state.conversations.loaded = true;
            state.conversations.error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        get_conversations_history_failure: ((state, action) => {
            state.conversations.loading = false;
            state.conversations.loaded = true;
            state.conversations.error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        add_conversation: ((state, action) => {
            const index = state.conversations.list.findIndex((c: ConversationType) => c.id === action.payload.id);
            if (index !== -1) return;
            state.conversations.list.push({
                ...action.payload,
                unread: 0,
            });
            if (action.payload.conv_kind === CONVERSATION_KIND_BROADCAST) {
                state.conversations.broadcastChannels.push(action.payload);
            };
        }),
        set_conversations: ((state, action) => {
            state.conversations.list = action.payload.reduce((acc: any[], curr: any) => {
                const index = acc.findIndex((c: ConversationType) => c.id === curr.id);
                if (index === -1) {
                    acc.push({
                        ...curr,
                        unread: 0
                    });
                    if (curr.conv_kind === CONVERSATION_KIND_BROADCAST) state.conversations.broadcastChannels.push(curr);
                }
                return acc;
            }, []);
            state.conversations.list.push({
                id: ALL_MESSAGES_CHANNEL_ID,
                name: 'All Messages',
                conv_kind: 'all',
                unread: 0,
            });
        }),
        get_messages: ((state, action) => {
            state.fetchMessagesError = null;
            state.messages = [];
        }),
        get_messages_success: ((state, action) => {
            state.fetchMessagesError = null;
            state.messages = action.payload.messages;
        }),
        get_messages_failure: ((state, action) => {
            state.fetchMessagesError = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        get_messages_history: ((state, action) => {
            state.fetchMessagesError = null;
            state.messages_history = [];
        }),
        get_messages_success_history: ((state, action) => {
            state.fetchMessagesError = null;
            state.messages_history = action.payload.history;
        }),
        get_messages_failure_history: ((state, action) => {
            state.fetchMessagesError = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        set_active_conversation: ((state, action) => {
            const index = state.conversations.list.findIndex((c: ConversationType) => c.id === action.payload.id);
            if (index !== -1) {
                state.active = state.conversations.list[index].id;
            } else {
                state.active = null;
            }
        }),
        set_notifications: ((state, action) => {
            state.notifications = action.payload.notifications;
        }),
        increase_notification_level: ((state, action) => {
            let current = state.notifications[action.payload.key] || 0;
            state.notifications[action.payload.key] = (current + 1) % 3;
            if (action.payload.key === 'global') {
                state.conversations.list.forEach((c: ConversationType) => {
                    state.notifications[c.id] = (current + 1) % 3;
                });
            };
        }),
        create_conversation: ((state, action) => {
        }),
        create_conversation_success: ((state, action) => {
            const { setActive, ...rest } = action.payload;
            const index = state.conversations.list.findIndex((c: ConversationType) => c.id === action.payload.id);
            if (index !== -1) return;
            state.conversations.list.unshift({
                id: action.payload.id,
                ...rest,
                unread: 0,
            });
        }),
        create_conversation_failure: ((state, action) => {
        }),
        archive_conversation: ((state, action) => {
        }),
        archive_conversation_success: ((state, action) => {
            const index = state.conversations.list.findIndex((c: ConversationType) => c.id === action.payload.id);
            if (index !== -1) {
                state.conversations.list = [...state.conversations.list.slice(0, index), ...state.conversations.list.slice(index + 1)];
            };
            state.messages = state.messages.filter((m: MessageType) => m.conversation !== action.payload.id);

            const i = state.conversations.broadcastChannels.findIndex((b: ConversationType) => b.id === action.payload.id);
            if (i > -1) state.conversations.broadcastChannels.splice(i, 1);
        }),
        archive_conversation_failure: ((state, action) => {
        }),
        reset_conversation_unread: ((state, action) => {
            if (action.payload.id === ALL_MESSAGES_CHANNEL_ID) {
                state.conversations.list.forEach((c: ConversationType) => {
                    c.unread = 0;
                })
            } else {
                const index = state.conversations.list.findIndex((c: ConversationType) => c.id === action.payload.id);
                if (index !== -1) {
                    state.conversations.list[index].unread = 0;
                }
            };
        }),
        create_message: ((state, action) => {
        }),
        create_message_success: ((state, action) => {
        }),
        create_message_failure: ((state, action) => {
        }),
        add_message: ((state, action) => {
            state.messages.push(action.payload);
            const index = state.conversations.list.findIndex((c: ConversationType) => c.id === action.payload.conversation);
            if (index !== -1) {
                const broadcast = state.conversations.list[index].conv_kind === CONVERSATION_KIND_BROADCAST;
                if (!broadcast) state.conversations.list[index].unread += 1;
            }
        }),
        set_nickname: ((state, action) => {
            state.nickname = action.payload.nickname;
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, state => {
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.stop, state => {
            Object.assign(state, initialState);
        });
    }
});

export const selectChat = (state: RootState) => state.chat;

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;