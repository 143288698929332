import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { getCurrentDayDate } from "../../utils/TimeUtils";

export const CHAT_API = {
    getConversations: (token: string, missionId: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/chat/conversation/${missionId}/`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getConversationsHistory: (token: string, missionId: string, from: string, to: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/chat/conversation/${missionId}/?active_from=${from}&date_to=${to}&history`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getMessages: (token: string, missionId: string, conversations: any[]) => {

        if (conversations.length > 0) {
            // We split request in 2 intervals: [min_conversation_creation, current_day_at_00TU] 
            // and [current_day_at_00TU, Infinity], in order to take advantage of django caching.
            let min_conversation_creation = conversations.map((c: any) => c.created).reduce((min, current) => {
                return current < min ? current : min;
            }, conversations[0].created);

            const current_day_date = getCurrentDayDate();

            const intervals = [
                [min_conversation_creation, current_day_date],
                [current_day_date]
            ];

            const promise = new Promise((resolve, reject) => {
                Promise.all(intervals.map((i: any) => {
                    let url = `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/chat/message/${missionId}/?date_from=${i[0]}`;
                    if (i[1] !== undefined) url += `&date_to=${i[1]}`;
                    return axios.get(
                        url,
                        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
                    );
                }
                ))
                    .then((values) => {
                        const to_return = {
                            ...values[0],
                            data: [].concat.apply([], values.map(v => v.data))
                        };
                        resolve(to_return);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });

            return promise;
        };
    },
    getMessagesHistory: (token: string, missionId: string, from: string, to: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/chat/message/${missionId}/?active_from=${from}&date_to=${to}&history`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    postConversation: (token: string, missionId: string, name: string) => axios.post(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/chat/conversation/${missionId}/`,
        { name },
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    deleteConversation: (token: string, missionId: string, conversationId: string) => axios.delete(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/chat/conversation/${missionId}/${conversationId}/`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    postMessage: (token: string, missionId: string, conversationId: string, author: string, text: string) => axios.post(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/chat/message/${missionId}/`,
        {
            conversation: conversationId,
            author,
            text
        },
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
};