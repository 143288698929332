import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import produce from "immer";
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import TimelineIcon from '@mui/icons-material/Timeline';
import { GraphContainer } from '../../graph/GraphContainer';
import { widgetActions } from '../../../redux/widget/widgetSlice';
import { telemetryActions } from '../../../redux/telemetry/telemetrySlice';
import { NAVIGATION_GRID_ID, TIMESERIES_WIDGET_ID } from '../../../config/widget';
import Plot from './Plot';
import Side from './Side';
import ParamSelectDialog from './ParamSelectDialog';

export interface SerieType {
    id: string;
    main: PlotType;
    timespan: number;
    others: PlotType[];
};

export interface PlotType {
    source: string,
    label: string,
    param: string,
    unit: string,
    toFixed: number,
    color: string,
    scale: string,
    autoscale: boolean,
    min: number,
    max: number,
    shown?: boolean,
};

interface SidebarProps {
    isOpen: boolean;
};

interface MainProps {
    isOpen: boolean;
};

const SIDEBAR_WIDTH_LG = '380px';
const SIDEBAR_WIDTH_MD = '280px';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    borderRadius: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    flexGrow: 1,
    padding: 0,
    "&:last-child": {
        paddingBottom: 0
    }
}));

export function SelectTimespan({ value, onChange }: { value: number, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }) {
    return (
        <TextField
            select
            value={value}
            onChange={onChange}
            variant="outlined"
            size="small"
            SelectProps={{
                native: true,
            }}
        >
            <option value={1}>1 minute</option>
            <option value={5}>5 minutes</option>
            <option value={15}>15 minutes</option>
            <option value={30}>30 minutes</option>
            <option value={60}>1 hour</option>
            <option value={120}>2 hour</option>
            <option value={24 * 60}>Full</option>
        </TextField>
    );
};

const Sidebar = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<SidebarProps>(({ theme, isOpen }) => ({
    position: 'absolute',
    top: 0,
    left: isOpen ? 0 : `-${SIDEBAR_WIDTH_LG}`,
    width: SIDEBAR_WIDTH_LG,
    transition: 'left 0.3s ease-in-out',
    [theme.breakpoints.down('lg')]: {
        left: isOpen ? 0 : `-${SIDEBAR_WIDTH_MD}`,
        width: SIDEBAR_WIDTH_MD,
    },
    [theme.breakpoints.down('sm')]: {
        left: isOpen ? 0 : '100%',
        width: '100%',
    },
    height: '100%',
}));

const Main = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<MainProps>(({ theme, isOpen }) => ({
    position: 'absolute',
    top: 0,
    left: isOpen ? SIDEBAR_WIDTH_LG : 0,
    width: isOpen ? `calc(100% - ${SIDEBAR_WIDTH_LG})` : '100%',
    transition: 'left 0.3s ease-in-out',
    [theme.breakpoints.down('lg')]: {
        left: isOpen ? SIDEBAR_WIDTH_MD : 0,
        width: isOpen ? `calc(100% - ${SIDEBAR_WIDTH_MD})` : '100%',
    },
    [theme.breakpoints.down('sm')]: {
        left: isOpen ? '100%' : 0,
        width: isOpen ? 0 : '100%',
    },
    right: 0,
    height: '100%',
    backgroundColor: '#3D3D3D',
}));

export function TabContent(props: any) {

    const { tabId, series, setParams, sideOpen, dimensions, onPlotError, onPlotHover }: { tabId: string, series: SerieType[], setParams: (seriesId: string, series: SerieType[], sideOpen: boolean | undefined) => void, sideOpen: boolean, dimensions?: any, onPlotError?: () => void, onPlotHover?: (props: any) => void } = props;
    const [updateCounter, setUpdateCounter] = useState(0);
    const [selectedPlotIndex, setSelectedPlotIndex] = useState(0);

    const forceRemount = useCallback(() => {
        setUpdateCounter(updateCounter => updateCounter + 1);
    }, [setUpdateCounter]);

    const handleCloseSidebar = useCallback(() => {
        setParams(tabId, series, false);
        forceRemount();
    }, [setParams, forceRemount, tabId, series]);

    const handleOpenSidebar = useCallback(() => {
        setParams(tabId, series, true);
        forceRemount();
    }, [setParams, forceRemount, tabId, series]);

    const onValueBoxClick = useCallback((id: string) => {
        handleOpenSidebar();
        const index = series.findIndex((s: SerieType) => (s.id === id));
        if (index !== -1) setSelectedPlotIndex(index);
    }, [series, handleOpenSidebar]);

    const setSerieParams = (serie: SerieType) => {
        const newSeries = produce(series, (draft: SerieType[]) => {
            const index = series.findIndex((s: SerieType) => s.id === serie.id);
            if (index !== -1) draft[index] = serie;
        });
        setParams(tabId, newSeries, sideOpen);
    };

    const deleteSerie = (serieId: string) => {
        const newSeries = produce(series, (draft: SerieType[]) => {
            const index = series.findIndex((s: SerieType) => s.id === serieId);
            if (index !== -1) draft.splice(index, 1);
        });
        setParams(tabId, newSeries, sideOpen);

        const index = series.findIndex((s: SerieType) => s.id === serieId);
        if (selectedPlotIndex === index) setSelectedPlotIndex(0);
    };

    const moveSerie = (serieId: string, newIndex: number) => {
        if (!(newIndex < 0 || newIndex >= series.length)) {
            const newSeries = produce(series, (draft: any) => {
                const index = series.findIndex((s: SerieType) => s.id === serieId);
                if (index !== -1) {
                    //https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
                    if (newIndex >= draft.length) {
                        let k = newIndex - draft.length + 1;
                        while (k--) {
                            draft.push(undefined);
                        }
                    }
                    draft.splice(newIndex, 0, draft.splice(index, 1)[0]);
                };
            });
            setParams(tabId, newSeries, sideOpen);
        };
    };

    useEffect(() => {
        forceRemount();
    }, [dimensions, forceRemount]);

    return (
        <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
            <Sidebar isOpen={sideOpen}>
                <Side
                    serie={series[selectedPlotIndex]}
                    index={selectedPlotIndex}
                    forceRemount={forceRemount}
                    setSerieParams={setSerieParams}
                    deleteSerie={deleteSerie}
                    moveSerie={moveSerie}
                    handleClose={handleCloseSidebar}
                />
            </Sidebar>
            <Main isOpen={sideOpen}>
                <GraphContainer item xs={12} spacing={0} onValueBoxClick={onValueBoxClick} />
                {series.map(({ id: serieId, main, timespan, others }: SerieType, index: number) => {
                    if (!main) return null;
                    return <Plot
                        key={`${serieId}-${updateCounter}`}
                        id={`${serieId}-${updateCounter}`}
                        serieId={serieId}
                        index={index}
                        main={main}
                        timespan={timespan}
                        others={others}
                        onError={onPlotError}
                        onHover={onPlotHover}
                    />
                })}
            </Main>
        </Box>
    )
};

export default function TimeSeriesTab({ id, series, widgetable, sideOpen, setParams }: { id: string, series: SerieType[], widgetable: boolean, sideOpen: boolean | undefined, setParams: (seriesId: string, series: SerieType[], sideOpen: boolean | undefined) => void }) {
    const dispatch = useDispatch();
    const isLarge = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const medium = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));
    const [paramsDialogOpen, setParamsDialogOpen] = useState(false);

    const handleTimespanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let timespan: number;
        timespan = parseInt(event.target.value);
        const newSeries = series.map((s: SerieType) => ({ ...s, timespan }))
        setParams(id, newSeries, sideOpen);
    };

    const openParamsDialog = () => {
        setParamsDialogOpen(true);
    };

    const closeParamsDialog = () => {
        setParamsDialogOpen(false);
    };

    const confirm = (plot: PlotType) => {
        const newSeries = produce(series, (draft: SerieType[]) => {
            draft.push({
                id: uuidv4(),
                main: plot,
                timespan: series.length > 0 ? series[0].timespan : 24 * 60,
                others: []
            });
        });
        setParams(id, newSeries, sideOpen);
        closeParamsDialog();
    };

    const onWidgetAdd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(widgetActions.add_widget({ gridId: NAVIGATION_GRID_ID, id: uuidv4(), kind: TIMESERIES_WIDGET_ID, params: { series }, medium }))
    };

    const onPlotError = () => {
        dispatch(telemetryActions.remove_tab({ id })); // TODO: this removes the tab only when it is set as active. Could probably be handled in a better way
    };

    return (
        <Box sx={{ display: 'flex', flexGrow: 1 }} key={id}>
            <StyledCard>
                <CardHeader
                    title={<Typography variant="h6">Time Series</Typography>}
                    action={
                        <Stack spacing={2} direction="row">
                            <SelectTimespan value={series[0]?.timespan} onChange={handleTimespanChange} />
                            {
                                !mobile &&
                                <>
                                    <Button variant="contained" onClick={openParamsDialog} startIcon={<TimelineIcon />}>ADD PLOT</Button>
                                    {
                                        widgetable &&
                                        <Button variant="contained" onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onWidgetAdd(event)} startIcon={<WebAssetIcon />}>
                                            ADD WIDGET
                                        </Button>
                                    }
                                </>
                            }
                        </Stack>
                    }
                />
                <Divider />
                <StyledCardContent>
                    <TabContent
                        tabId={id}
                        series={series}
                        setParams={setParams}
                        sideOpen={sideOpen !== undefined ? sideOpen : isLarge}
                        onPlotError={onPlotError}
                    />
                    <ParamSelectDialog
                        open={paramsDialogOpen}
                        confirm={confirm}
                        close={closeParamsDialog}
                        exclude={series.map((s: SerieType) => `${s.main.source}-${s.main.label}-${s.main.param}`)}
                    />
                </StyledCardContent>
            </StyledCard>
        </Box>
    );
};