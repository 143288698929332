import React, { useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RFeature, RLayerVectorImage } from "rlayers";
import { Point } from "ol/geom";
import { transform } from "ol/proj";
import VectorLayer from '../../map/layers/VectorLayer';
import { store } from '../../../../app/store';
import { RootState } from "../../../../app/store";
import { adsbStyleFunction } from "../styles/adsb";
import { aeroActions } from '../../../../redux/aero/aeroSlice';

const Aircraft = React.memo(function Aircraft({ id, position, properties, hud, filters }: { id: string, position: number[], properties: any, hud: any, filters: any }) {
    const dispatch = useDispatch();
    const mapProj = store.getState().map.projection;
    const geometry = new Point(transform(position, "EPSG:4326", mapProj));

    const onSingleClick = useCallback((e: any) => {
        dispatch(aeroActions.adsb_aircraft_click({ id, properties }));
        return false; // prevent forwarding click event to other features
    }, [dispatch, id, properties]);

    const onDblClick = useCallback(() => {
        dispatch(aeroActions.adsb_aircraft_dblClick({ id, properties }));
        return false; // prevent zooming in on double click
    }, [dispatch, id, properties]);

    const styleFunc = useCallback((feature: any, resolution: any) => {
        return adsbStyleFunction(feature, resolution, filters, hud)
    }, [hud, filters]);

    return (
        <RFeature
            key={id}
            geometry={geometry}
            properties={properties}
            onSingleClick={onSingleClick}
            onDblClick={onDblClick}
            style={styleFunc}
        />
    );
});

export default function Adsb({ zIndex }: { zIndex: number }) {
    const adsb = useSelector((state: RootState) => state.nav.aero.adsb);

    return (
        <VectorLayer
            id="adsb-layer"
            component={RLayerVectorImage}
            zIndex={zIndex}
            visible={adsb.visible}
            rulingSnap={true}
        >
            {
                adsb.list.map((a: any) => <Aircraft key={a.id} id={a.id} position={a.geometry.coordinates} properties={a.properties} hud={a.hud} filters={adsb.filters} />)
            }
        </VectorLayer>
    );

};