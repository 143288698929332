import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { FeatureType } from '../map/mapSlice';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { GEOMARKER_DEFAULT_GROUP } from '../../config/geomarker';

export interface GeomarkerGroupType {
    visible: boolean;
};

export interface GeomarkerType {
    id: string;
    created: string;
    delete: string;
    creator_id: string;
    feature: FeatureType;
};

export interface GeomarkerState {
    groups: {
        [key: string]: GeomarkerGroupType;
    };
    geomarkers: GeomarkerType[];
    history: any[];
    edit: GeomarkerType[];
    draw: {
        type: "Point" | "LineString" | "LinearRing" | "Polygon" | "MultiPoint" | "MultiLineString" | "MultiPolygon" | "GeometryCollection" | "Circle" | null;
        feature: any;
    };
    loading: boolean;
    loaded: boolean;
    error: string | null;
};

const initialState: GeomarkerState = {
    groups: {},
    geomarkers: [],
    history: [],
    edit: [],
    draw: {
        type: null,
        feature: null,
    },
    loading: false,
    loaded: false,
    error: null,
};

const geomarkersSlice = createSlice({
    name: 'geomarker',
    initialState,
    reducers: {
        get_geomarkers: ((state, action) => {
            state.loading = true;
            state.loaded = false;
            state.error = null;
            state.groups = {};
            state.geomarkers = [];
            state.edit = [];
        }),
        get_geomarkers_success: ((state, action) => {
            state.loading = false;
            state.loaded = true;
            state.groups = action.payload.groups;
            state.geomarkers = action.payload.geomarkers;
            state.history = action.payload.history;
        }),
        get_geomarkers_failure: ((state, action) => {
            state.loading = false;
            state.loaded = true;
            state.error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        add: ((state, action) => {
            if ((action.payload.groupId && !(action.payload.groupId in state.groups)) ||
                (!action.payload.groupId && !(GEOMARKER_DEFAULT_GROUP in state.groups))) {
                state.groups[action.payload.groupId || GEOMARKER_DEFAULT_GROUP] = {
                    visible: true
                };
            };
            state.geomarkers.push(action.payload.geomarker);
        }),
        update: ((state, action) => {
            const index = state.geomarkers.findIndex((gm: GeomarkerType) => gm.id === action.payload.id);
            if (index !== -1) {
                let previousGroup = state.geomarkers[index].feature.properties?.group || GEOMARKER_DEFAULT_GROUP;
                // Add new group if it does not exists
                if (action.payload.groupId !== previousGroup) {
                    if ((action.payload.groupId && !(action.payload.groupId in state.groups)) ||
                        (!action.payload.groupId && !(GEOMARKER_DEFAULT_GROUP in state.groups))) {
                        state.groups[action.payload.groupId || GEOMARKER_DEFAULT_GROUP] = {
                            visible: true
                        };
                    };
                };

                if (action.payload.remove === true) state.geomarkers = [...state.geomarkers.slice(0, index), ...state.geomarkers.slice(index + 1)];
                else state.geomarkers[index] = action.payload.geomarker;

                // Remove previous group if it becomes empty
                let gmCount = state.geomarkers.reduce((result: number, gm: GeomarkerType) => {
                    if (gm.feature.properties?.group === previousGroup) return result += 1;
                    if (previousGroup === GEOMARKER_DEFAULT_GROUP && gm.feature.properties?.group === undefined) return result += 1;
                    return result;
                }, 0);
                if (gmCount === 0) delete state.groups[previousGroup];
            };
        }),
        delete: ((state, action) => {
        }),
        delete_success: ((state, action) => {
        }),
        delete_failure: ((state, action) => {
        }),
        delete_group: ((state, action) => {
        }),
        click: ((state, action) => {
            const gm = state.geomarkers.find((gm: GeomarkerType) => gm.id === action.payload.id);
            if (gm) gm.feature.properties.clicked = true;
        }),
        unclick: ((state, action) => {
            const gm = state.geomarkers.find((gm: GeomarkerType) => gm.id === action.payload.id);
            if (gm) delete gm.feature.properties.clicked;
        }),
        dblClick: ((state, action) => { }),
        start_edit: ((state, action) => { }),
        edit: ((state, action) => {
            const gm = state.geomarkers.find((gm: GeomarkerType) => gm.id === action.payload.id);
            if (gm) {
                gm.feature.properties.beingEdited = true;
                state.edit.push(gm);
            }
        }),
        cancel_edit: ((state, action) => {
            if (action.payload.id) {
                const index = state.edit.findIndex((gm: GeomarkerType) => gm.id === action.payload.id);
                if (index !== -1) state.edit = [...state.edit.slice(0, index), ...state.edit.slice(index + 1)];
                const gm = state.geomarkers.find((gm: GeomarkerType) => gm.id === action.payload.id);
                if (gm) {
                    delete gm.feature.properties.beingEdited;
                    delete gm.feature.properties.clicked;
                };
            } else {
                // if id is not defined, we cancel edit all GMs.
                state.edit.forEach((e: GeomarkerType) => {
                    const g = state.geomarkers.find((gm: GeomarkerType) => gm.id === e.id);
                    if (g) {
                        delete g.feature.properties.beingEdited;
                        delete g.feature.properties.clicked;
                    };
                });
                state.edit = [];
            };
        }),
        commit_edit: ((state, action) => {
            const index = state.edit.findIndex((gm: GeomarkerType) => gm.id === action.payload.id);
            if (index !== -1) {
                delete state.edit[index].feature.properties.beingEdited;
                delete state.edit[index].feature.properties.clicked;
            };
        }),
        commit_edit_success: ((state, action) => {
            const index = state.edit.findIndex((gm: GeomarkerType) => gm.id === action.payload.id);
            if (index !== -1) state.edit = [...state.edit.slice(0, index), ...state.edit.slice(index + 1)];

            /* This is actually done via MQTT */
            /* const gmIndex = state.geomarkers.findIndex((gm: GeomarkerType) => gm.id === action.payload.id);
            if (gmIndex !== -1) state.geomarkers[index] = action.payload.geomarker; */
        }),
        commit_edit_failure: ((state, action) => { }),
        update_edited_gm_props: ((state, action) => {
            const index = state.edit.findIndex((gm: GeomarkerType) => gm.id === action.payload.id);
            if (index !== -1) {
                state.edit[index].feature.properties = {
                    ...state.edit[index].feature.properties,
                    [action.payload.key]: action.payload.value
                };
            };
        }),
        update_edited_gm_coords: ((state, action) => {
            const index = state.edit.findIndex((gm: GeomarkerType) => gm.id === action.payload.id);
            if (index !== -1) {
                state.edit[index].feature.geometry.coordinates = action.payload.coordinates;
            };
        }),
        start_drawing: ((state, action) => {
            state.draw.type = action.payload.type;
            state.draw.feature = null;
        }),
        end_drawing: ((state, action) => {
            state.draw.type = null;
            state.draw.feature = action.payload.feature;
        }),
        cancel_drawing: ((state, action) => {
            state.draw.type = null;
            state.draw.feature = null;
        }),
        commit_drawing: ((state, action) => {
        }),
        commit_drawing_success: ((state, action) => {
            state.draw.type = null;
            state.draw.feature = null;
        }),
        commit_drawing_failure: ((state, action) => {
        }),
        update_drawing_gm_props: ((state, action) => {
            state.draw.feature.properties = {
                ...state.draw.feature.properties,
                [action.payload.key]: action.payload.value
            };
        }),
        update_drawing_gm_coords: ((state, action) => {
            state.draw.feature.geometry.coordinates = action.payload.coordinates;
        }),
        set_group_visibility: ((state, action) => {
            state.groups[action.payload.groupId].visible = action.payload.visible;
        }),
        drop_point: ((state, action) => {
        }),
        drop_point_success: ((state, action) => {
        }),
        drop_point_failure: ((state, action) => {
        }),
        upload_geomarkers: ((state, action) => {
        }),
        upload_geomarkers_success: ((state, action) => {
        }),
        upload_geomarkers_failure: ((state, action) => {
        }),
        download_geomarkers: ((state, action) => {
        }),
        download_geomarkers_success: ((state, action) => {
        }),
        download_geomarkers_failure: ((state, action) => {
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, state => {
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.stop, state => {
            Object.assign(state, initialState);
        });
    }
});

export const selectGeomarker = (state: RootState) => state.nav.geomarkers;

export const geomarkersActions = geomarkersSlice.actions;

export default geomarkersSlice.reducer;