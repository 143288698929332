import React from 'react';
import { useSelector } from 'react-redux';
import { RFeature, RLayerVectorImage } from "rlayers";
import { Point } from "ol/geom";
import { transform } from "ol/proj";
import { RootState } from '../../../../app/store';
import VectorLayer from '../../map/layers/VectorLayer';
import { navaidsStyle } from '../styles/navaids';
import { store } from '../../../../app/store';

const Navaid = React.memo(function Navaid({ position, properties }: { position: number[], properties: any }) {
    const mapProj = store.getState().map.projection;
    const geometry = new Point(transform(position, "EPSG:4326", mapProj));

    return (
        <RFeature
            key={properties.id}
            geometry={geometry}
            properties={properties}
            style={navaidsStyle}
        />
    );
});

export default function NavaidsLayer({ zIndex }: { zIndex: number }) {
    const navaids = useSelector((state: RootState) => state.nav.aero.navaids);

    return (
        <VectorLayer
            id="navaids-layer"
            component={RLayerVectorImage}
            zIndex={zIndex}
            visible={navaids.visible}
            rulingSnap={true}
            style={null}
        >
            {
                navaids.list.map((n: any) => <Navaid key={n.id} position={n.position} properties={n} />)
            }
        </VectorLayer>
    );
};