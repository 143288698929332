import { Stroke, Style } from "ol/style";
import chroma from "chroma-js";
import { Color } from "ol/color";
import { FEET_TO_METERS } from "../../../../utils/GeomUtils";
import { isBackgroundDark } from "../../../../config/map";

const MIN_CHROMA_ALT = 0;
const MAX_CHROMA_ALT = 15000;
const CHROMA_RANGE_ALT = chroma
  .scale([
    "#FBEEB0",
    "#FBFE02",
    "#05FE01",
    "#00FEFE",
    "#0309FF",
    "#CF00FF",
    "#FC00E0",
    "#FC243C"
  ])
  .domain([MIN_CHROMA_ALT, MAX_CHROMA_ALT]);

const MIN_CHROMA_TIME = 0;
const MAX_CHROMA_TIME = 60;
const CHROMA_RANGE_TME = chroma
  .scale([
    "#7A9FFF",
    "#7E9EFB",
    "#839DF7",
    "#879DF3",
    "#8C9CEF",
    "#909CEB",
    "#959BE7",
    "#9A9BE3",
    "#9E9AE0",
    "#A39ADC",
    "#A799D8",
    "#AC98D4",
    "#B198D0",
    "#B597CC",
    "#BA97C8",
    "#BE96C5",
    "#C396C1",
    "#C795BD",
    "#CC95B9",
    "#D194B5",
    "#D593B1",
    "#DA93AD",
    "#DE92AA",
    "#E392A6",
    "#E891A2",
    "#EC919E",
    "#F1909A",
    "#F59096",
    "#FA8F92",
    "#FF8F8F"
  ])
  .domain([MIN_CHROMA_TIME, MAX_CHROMA_TIME]);

let alt_cache: any = {};

export const getColorByAlt = (alt: number) => {
  if (alt in alt_cache) return alt_cache[alt];
  if (alt < 0) alt = 0;
  let to_return = CHROMA_RANGE_ALT(alt);
  alt_cache[alt] = to_return;
  return to_return;
};

export const getStyleByAlt = (alt: number) => {
  if (alt in alt_cache) return alt_cache[alt];
  if (alt < 0) alt = 0;
  let to_return = [
    new Style({
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        width: 2.5,
      })
    }),
    new Style({
      stroke: new Stroke({
        color: CHROMA_RANGE_ALT(alt) as any as Color,
        width: 2
      })
    }),
  ];
  alt_cache[alt] = to_return;
  return to_return;
};

let time_cache: any = {};
export const getStyleByTime = (time: number) => {
  if (time in time_cache) return time_cache[time];
  if (time < 0) time = 0;
  let to_return = [
    new Style({
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        width: 2.5,
      })
    }),
    new Style({
      stroke: new Stroke({
        color: CHROMA_RANGE_TME(time) as any as Color,
        width: 2
      })
    })
  ];
  time_cache[time] = to_return;
  return to_return;
};

export const trackStyleByAlt = (feature: any, resolution: any) => {
  const gps_msl_alt = feature.get("gps_msl_alt");
  const press_alt = feature.get("press_alt");
  const alt = gps_msl_alt ? gps_msl_alt : press_alt ? press_alt * FEET_TO_METERS : 0;

  return getStyleByAlt(alt);
};

export const trackStyleByTime = (feature: any, resolution: any) => {
  const timestamp = feature.get('timestamp');

  return getStyleByTime(Math.trunc((timestamp % 3600) / 60));
};

export const trackStyleNone = (feature: any, resolution: any) => {
  return ([
    new Style({
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        width: 2.5,
      })
    }),
    new Style({
      stroke: new Stroke({
        color: feature.get("color"),
        width: 2
      })
    })
  ]);
};

export const trackShadowStyle = (feature: any, resolution: any) => {
  const color =
          isBackgroundDark()
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(0, 0, 0, 0.5)";

  return new Style({
    stroke: new Stroke({
      color,
      width: 1,
    })
  });
};