export const DASHBOARD_TOAST_CONTAINER_ID = 'dashboard-toaster';

export const NAVIGATION_TOAST_CONTAINER_ID = 'navigation-toaster';

export const GEOMARKERS_TOAST_INFO_ID = 'geomarker-info';
export const GEOMARKERS_TOAST_EDIT_ID = 'geomarker-edit';
export const GEOMARKERS_TOAST_DRAWING_ID = 'geomarker-drawing';

export const DASHBOARD_TOAST_INFO_ID = 'dashboard-info';
export const DASHBOARD_TOAST_MISSION_CHANGED_ID = 'dahsboard-mission-changed';
export const DASHBOARD_TOAST_INVALID_TOKEN = 'dashboard-invalid-token';
export const DASHBOARD_TOAST_API_ERROR = 'dashboard-api-error';

export const AIRPORT_TOAST_INFO_ID = 'aero-airport-info';

export const WEATHER_TOAST_INFO_ID = 'weather-info';

export const ADSB_TOAST_INFO_ID = 'adsb-aircraft-info';