import { call, put, all, select, takeLatest } from "redux-saga/effects";
import { authActions } from './authSlice';
import { AUTH_API } from './authApi';
import { RootState } from "../../app/store";

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* login(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const res: any = yield call(AUTH_API.login, { username: action.payload.username, password: action.payload.password });
        yield put(authActions.login_success({ token: res?.data?.auth_token }));
        localStorage.setItem('token', res?.data?.auth_token);
    } catch (error: any) {
        yield put(authActions.login_fail({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    }
};

function* watchLogin() {
    yield takeLatest(authActions.login, login);
};

function* logout(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        localStorage.removeItem('token');
        const token: string = yield select((state: RootState) => state.auth.token);
        const res: any = yield call(AUTH_API.logout, token);
        yield put(authActions.logout_success({ data: res?.data }));

    } catch (error: any) {
        yield put(authActions.logout_fail({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    }
};

function* watchLogout() {
    yield takeLatest(authActions.logout, logout);
};

function* getUserInfo(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const res: any = yield call(AUTH_API.me, token);
        yield put(authActions.get_user_info_success({ ...res?.data }));
    } catch (error: any) {
        localStorage.removeItem('token');
        yield put(authActions.get_user_info_fail({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    }
};

function* watchGetUserInfo() {
    yield takeLatest(authActions.get_user_info, getUserInfo);
};

export default function* watchAuth() {
    yield all([
        watchLogin(),
        watchLogout(),
        watchGetUserInfo(),
    ]);
};