import { Fill, Stroke, Style, Text, Icon } from "ol/style";
import { transform } from "ol/proj";
import { toDate } from '../../../../utils/TimeUtils';
import { LineString, Polygon } from "ol/geom";
import { store } from '../../../../app/store';
import { isBackgroundDark } from "../../../../config/map";

const strikeIcon = `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="512" viewBox="0 0 192 192" width="512">
<g fill="#F7EA9C" stroke="grey">
<path style="stroke-width:10" d="m155.109 74.028a4 4 0 0 0 -3.48-2.028h-52.4l8.785-67.123a4.023 4.023 0 0 0 -7.373-2.614l-63.724 111.642a4 4 0 0 0 3.407 6.095h51.617l-6.962 67.224a4.024 4.024 0 0 0 7.411 2.461l62.671-111.63a4 4 0 0 0 .048-4.027z"/>
</g>
</svg>`;

const RDT_PALETTE: { [key: string]: { stroke_width: number, stroke_color: string, fill_color: string } } = {
    default: {
        stroke_width: 2,
        stroke_color: "rgb(0,0,255)",
        fill_color: "rgba(0,0,153,0.9)"
    },
    Naissance: {
        stroke_width: 2,
        stroke_color: "rgb(248,247,23)",
        fill_color: "rgba(248,247,23,0.2)",
    },
    Naissance_par_fission: {
        stroke_width: 2,
        stroke_color: "rgb(247,167,23)",
        fill_color: "rgba(247,167,167,0.2)",
    },
    Decroissance: {
        stroke_width: 2,
        stroke_color: "rgb(24,24,247)",
        fill_color: "rgba(24,24,247,0.2)",
    },
    Croissance: {
        stroke_width: 2,
        stroke_color: "rgb(247,24,22)",
        fill_color: "rgba(247,24,22,0.2)",
    },
    Mature: {
        stroke_width: 2,
        stroke_color: "rgb(135,22,135)",
        fill_color: "rgba(135,22,135,0.2)",
    }
};

const lightningStyle = (feature: any, resolution: any) => {
    let opacity = 1;
    const ms = Math.abs(toDate(Math.round(Date.now() / 1000)).getTime() - toDate(feature.get('time')).getTime());

    if (ms > 60000) {
        opacity = 1 - ms / 300000
    }

    let style = new Style({
        image: new Icon({
            src:
                "data:image/svg+xml;charset=utf-8," +
                encodeURIComponent(strikeIcon),
            scale: 0.04,
            rotateWithView: false,
            opacity
        })
    });

    return style;
};

const CountriesStyle = (feature: any, resolution: any) => {
    let style = new Style({
        fill: new Fill({
            color: "rgba(255, 255, 255, 0.6)"
        }),
        stroke: new Stroke({
            color: "#319FD3",
            width: 1
        }),
        text: new Text({
            font: "12px Calibri,sans-serif",
            fill: new Fill({
                color: "#000"
            }),
            stroke: new Stroke({
                color: "#fff",
                width: 3
            })
        })
    });

    style.getText().setText(feature.get("name"));
    return style;
};

const RDTStyle = (feature: any, resolution: any) => {
    let styles = [];
    const category = feature.get("category");
    const centroid = feature.get('centroid');
    const lightning = (feature.get("strokes_p") !== 0 || feature.get("strokes_n") !== 0);
    let direction = feature.get('direction');
    let speed = feature.get('speed');
    const projection = store.getState().map.projection

    styles.push(
        new Style({
            fill: new Fill({
                color: RDT_PALETTE[category].fill_color
            }),
            stroke: new Stroke({
                color: RDT_PALETTE[category].stroke_color,
                width: RDT_PALETTE[category].stroke_width,
                lineDash: lightning ? undefined : [4, 4]
            })
        })
    );

    if (centroid && direction && speed) {
        const head_angle = (180 - 20) * Math.PI / 180;
        const head_ratio = 0.15;
        direction *= Math.PI / 180;
        speed /= 36;

        const end = [centroid[0] + speed * Math.sin(direction), centroid[1] + speed * Math.cos(direction)];
        const barb0 = [end[0] + speed * head_ratio * Math.sin(direction + head_angle), end[1] + speed * head_ratio * Math.cos(direction + head_angle)];
        const barb1 = [end[0] + speed * head_ratio * Math.sin(direction - head_angle), end[1] + speed * head_ratio * Math.cos(direction - head_angle)];

        styles.push(
            new Style({
                geometry: new LineString([transform(centroid, "EPSG:4326", projection), transform(end, "EPSG:4326", projection)]),
                stroke: new Stroke({
                    color: isBackgroundDark() ? "#e5e5e5" : "black",
                    width: 1.5,
                })
            })
        );

        styles.push(
            new Style({
                geometry: new Polygon([[
                    transform(barb0, "EPSG:4326", projection),
                    transform(end, "EPSG:4326", projection),
                    transform(barb1, "EPSG:4326", projection),
                    transform(barb0, "EPSG:4326", projection),
                ]]),
                fill: new Fill({
                    color: isBackgroundDark() ? "#e5e5e5" : "black"
                }),
                stroke: new Stroke({
                    color: isBackgroundDark() ? "#e5e5e5" : "black",
                    width: 2.5,
                })
            })
        );
    }

    return styles;
};

export const weatherStyles: { [key: string]: any } = {
    rdt_msg: RDTStyle,
    countries: CountriesStyle,
    lightning: lightningStyle
};