import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { selectAuth, authActions } from '../../redux/auth/authSlice';

export default function Login() {
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 13 || event.which === 13) {
            !(username === "" || password === "") && handleLogin();
        }
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleLogin = () => {
        dispatch(authActions.login({ username, password }));
    };

    if (auth.token) return (
        <Redirect
            to={{
                pathname: '/checkToken',
            }}
        />
    );

    return (
        <Container maxWidth={'xs'}>
            <form noValidate autoComplete="off">
                <Card
                    sx={{
                        marginTop: (theme) => theme.spacing(15),
                        overflow: "auto"
                    }}
                >
                    <CardHeader
                        sx={{ background: (theme) => theme.palette.primary.main }}
                        avatar={
                            <Avatar src="/favicon.ico" />
                        }
                        title={<Typography component="h5" variant="h5">
                            Login
                        </Typography>}
                        subheader="Login on PLANET application"
                    />
                    <CardContent
                        sx={{ padding: (theme) => theme.spacing(2, 5) }}
                    >
                        <Box>
                            <TextField
                                error={auth.loggingInError !== null}
                                fullWidth
                                variant="outlined"
                                id="username"
                                type="email"
                                label="Username"
                                placeholder="Username"
                                margin="normal"
                                onChange={handleUsernameChange}
                                onKeyPress={handleKeyPress}
                            />
                            <TextField
                                error={auth.loggingInError !== null}
                                fullWidth
                                variant="outlined"
                                id="password"
                                type="password"
                                label="Password"
                                placeholder="Password"
                                margin="normal"
                                helperText={auth.loggingInError}
                                onChange={handlePasswordChange}
                                onKeyPress={handleKeyPress}
                            />
                        </Box>
                    </CardContent>
                    <CardActions
                        sx={{ padding: (theme) => theme.spacing(2) }}
                    >
                        <LoadingButton
                            sx={{
                                marginTop: (theme) => theme.spacing(2),
                                flexGrow: 1
                            }}
                            variant="contained"
                            size="large"
                            color="secondary"
                            onClick={handleLogin}
                            disabled={username === "" || password === ""}
                            loading={auth.isLoggingIn}
                        >
                            Login
                        </LoadingButton>
                    </CardActions>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: "center",
                            padding: (theme) => theme.spacing(1)
                        }}
                    >
                        <Typography variant="caption">
                            version {global.appVersion}
                        </Typography>
                    </Box>
                </Card>
            </form>
            <footer style={{ position: 'absolute', bottom: 50 }}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="subtitle1">Copyright &copy;&nbsp;&nbsp;</Typography>
                    <img src="/atmosphere-full.svg" alt="Atmosphere icon full svg" width="180" height="36" />
                    <Typography variant="subtitle1">&nbsp;&nbsp;- {new Date().getFullYear()}</Typography>
                </Box>
            </footer>
        </Container>
    );
};