import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { short_hour } from "../../../utils/TimeUtils";
import { RootState } from "../../../app/store";
import { replayActions } from "../../../redux/replay/replaySlice";

export default function Clock({ mobile }: { mobile: boolean }) {
  const dispatch = useDispatch();
  const [state, setState] = useState({ time: new Date() });
  const { isActive, play, currentTime } = useSelector((state: RootState) => state.replay);

  const tick = () => {
    if (isActive && play) dispatch(replayActions.increment_time({}));
    else setState({ time: new Date() });
  };

  useEffect(() => {
    const intervalID = setInterval(() => tick(), 1000);
    return () => clearInterval(intervalID);
  });

  if (mobile) return null;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ScheduleIcon />
      <Typography sx={{ fontSize: '1.2rem', display: { xs: 'none', sm: 'block' } }} variant="body1">{isActive ? short_hour(currentTime) : short_hour(state.time)}</Typography>
    </Stack>
  );

};