import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { getHourlyIntervals } from "../../utils/TimeUtils";

export const TELEMETRY_API = {
    getLabels: (token: string, missionId: string, plid: string, duration: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/telemetry/${missionId}/?creator=${plid}&duration=${duration}&unique_labels`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getLabelsWithDates: (token: string, missionId: string, plid: string, from: string, to: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/telemetry/${missionId}/?creator=${plid}&date_from=${from}&date_to=${to}&unique_labels`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getHistory: (token: string, missionId: string, plid: string, duration: string, label: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/telemetry/${missionId}/?creator=${plid}&duration=${duration}${label ? `&label=${label}` : ''}`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getHistoryWithDates: (token: string, missionId: string, plid: string, from: string, to: string, label: string) => {
        const intervals = getHourlyIntervals(from, to);

        const promise = new Promise((resolve, reject) => {
            Promise.all(intervals.map((i: string[]) => {
                let url = `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/telemetry/${missionId}/?creator=${plid}&date_from=${i[0]}&date_to=${i[1]}${label ? `&label=${label}` : ''}`;
                return axios.get(
                    url,
                    { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
                );
            }))
                .then((values) => {
                    const to_return = {
                        ...values[0],
                        data: [].concat.apply([], values.map(v => v.data))
                    };
                    resolve(to_return);
                })
                .catch((error) => {
                    reject(error);
                })
        });

        return promise;
    },
};