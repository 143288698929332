import { OnMessageCallback } from 'mqtt';
import merge from "deepmerge";
import { LabelType, telemetryActions } from './telemetrySlice';
import { store } from '../../app/store';
import { toDate } from '../../utils/TimeUtils';

export const telemetryMqttParser: OnMessageCallback = (topic: any, payload: any, packet: any): void => {
    const { creator_id, label, created, position, data, source } = JSON.parse(payload.toString());
    const telemetry = store.getState().telemetry;

    if (!(creator_id in telemetry.labels)) {
        console.error(`Unknown plid ${creator_id}`);
        return;
    };

    const stream_config = (merge(telemetry.config?.default?.streams || {}, telemetry.config?.[creator_id]?.streams || {}) as any)[label] || {};
    const entries = Object.entries(data).map(([key, value]: [string, any]) => {
        return ([stream_config[key]?.name || key, value]);
    });
    const obs_data = Object.fromEntries(entries);
    store.dispatch(telemetryActions.add_value({
        plid: creator_id,
        label,
        timestamp: toDate(created).getTime() / 1000,
        value: { position: position, ...obs_data }
    }));
};
