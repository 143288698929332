import { OriginType } from "../../redux/map/mapSlice";

const MAX_ITERATION = 20;
const a = 6378137.0;
const e = 0.081819191;
const eps = 1e-11;

// Computes the cartesian coordinates relative to a local point of origin from lat-long position, altitude and geocentric cartesian coordinates of the point of origin
export function dummyCompute(value: {[param: string]: [number, number] | number}, params: {[internalName: string]: string}, origin: OriginType) {

    const position = (params.position !== undefined ? value[params.position] : value.position) as [number, number];
    const altitude = (params.gps_msl_alt !== undefined ? value[params.gps_msl_alt] : value.gps_msl_alt) as number;

    const lon = position[0] * Math.PI / 180; // Converting to radiant
    const lat = position[1] * Math.PI / 180;
    const alt = altitude; // TODO : compute ellipsoidal height from altitude if necessary

    const lonO = origin.lon * Math.PI / 180; // Converting to radiant
    const latO = origin.lat * Math.PI / 180;
    const altO = origin.alt; // TODO : compute ellipsoidal height from altitude for better accuracy

    // Converting to geocentric cartesian coordinates
    const [x1, y1, z1] = polarToGeoCent(lon, lat, alt);
    const [xO, yO, zO] = polarToGeoCent(lonO, latO, altO);

    // Changing coordinates' origin
    const xDelta = x1 - xO;
    const yDelta = y1 - yO;
    const zDelta = z1 - zO;

    // Applying rotation around the Z axis
    const rotZ = lonO + Math.PI / 2;

    const x2 = xDelta * Math.cos(rotZ) + yDelta * Math.sin(rotZ);
    const y2 = -1 * xDelta * Math.sin(rotZ) + yDelta * Math.cos(rotZ);
    const z2 = zDelta;

    // Applying rotation around the X axis
    const rotX = Math.PI / 2 - latO;

    const x = x2;
    const y = y2 * Math.cos(rotX) + z2 * Math.sin(rotX);
    const z = -1 * y2 * Math.sin(rotX) + z2 * Math.cos(rotX);

    // Calculating elevation and bearing angles in radiant
    const gis = Math.acos(y / Math.sqrt(x**2 + y**2));
    const site = Math.asin(z / Math.sqrt(x**2 + y**2 + z**2));

    // returning angles in 'grades', returning bearing angle ('gis') between 0 and 400 grades clockwise as in the specifications
    return {
        dumx: x / 2,
        dumy: y / 2,
        dumz: z / 2,
        dumgis: x > 0 ? gis * 200 / Math.PI / 2 : 400 - gis * 200 / Math.PI / 2,
        dumsite: site * 200 / Math.PI / 2
    };
};

// Convert lat-long + altitude coordinates to geocentric cartesian coordinates
export function polarToGeoCent(lon: number, lat: number, alt: number) {
    const N = a / Math.sqrt(1 - e**2 * Math.sin(lat)**2);
    const x = (N + Number(alt)) * Math.cos(lon) * Math.cos(lat);
    const y = (N + Number(alt)) * Math.sin(lon) * Math.cos(lat);
    const z = (N * (1 - e**2) + Number(alt)) * Math.sin(lat);
    return [x, y, z];
};

// Convert geocentric cartesian coordinates to lat-long + altitude coordinates
export function geoCentToPolar(x: number, y: number, z: number) {
    const long = Math.atan(y / x);
    let lat = Math.atan(z / (Math.sqrt(x**2 + y**2) * (1 - a * e**2 / Math.sqrt(x**2 + y**2 + z**2))));
    let lat_old = null
    let i = 0;
    while (i < MAX_ITERATION && (lat_old === null || Math.abs(lat - lat_old) > eps)) {
        lat_old = lat;
        lat = Math.atan(z / (Math.sqrt(x**2 + y**2) * (1 - a * e**2 * Math.cos(lat_old) / (Math.sqrt(x**2 + y**2) * Math.sqrt(1 - e**2 * Math.sin(lat_old)**2)))));
        i++;
    }
    const alt = Math.sqrt(x**2 + y**2) / Math.cos(lat) - a / Math.sqrt(1 - e**2 * Math.sin(lat)**2)
    return [long, lat, alt];
};

export function degToRad(deg: number) {
    return deg * Math.PI / 180;
};

export function radToDeg(rad: number) {
    return rad * 180 / Math.PI;
};

export function dummyComputeInit(history: [number[], any[]], params: {[internalName: string]: string}, origin: OriginType): [number[], any[]] {
    if (history) {
        const computed = history[1].map((element, index) => {
            if (element.position !== undefined && element.position !== null && element.gps_msl_alt !== undefined && element.gps_msl_alt !== null) {
                const res = dummyCompute(element, params, origin);
                return {
                    position: element.position,
                    dumx: String(res.dumx),
                    dumy: String(res.dumy),
                    dumz: String(res.dumz),
                    dumgis: String(res.dumgis),
                    dumsite: String(res.dumsite)
                };
            } else {
                return null;
            }
        });

        return [history[0], computed];
    } else {
        return [[], []];
    }
};