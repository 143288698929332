import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Popover from '@mui/material/Popover';
import { AUTH_LEVELS } from '../../../config/auth';
import { RootState } from '../../../app/store';
import { DashboadRouteType } from '../../../config/routes';
import { authActions } from '../../../redux/auth/authSlice';

export default function MoreActions({ actions, prefix, user }: { actions: DashboadRouteType[], prefix: string, user: boolean }) {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const level = useSelector((state: RootState) => Object.entries(AUTH_LEVELS).find(([k, v]) => v.api_key === state.auth.level)?.[1]);
    const username = useSelector((state: RootState) => state.auth.username);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = () => {
        dispatch(authActions.logout({}));
    };

    return (
        <>
            <IconButton ref={ref} onClick={handleOpen}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
                    {
                        actions.map((a) => {
                            const { id, label, path, redirect, icon: Icon, target, rel, accessRights, disabled } = a;
                            if (level?.value && level.value < accessRights.value) return null;
                            return (
                                <ListItemButton key={id} disabled={disabled} onClick={handleClose} component={Link} to={redirect ? redirect : `${prefix}${path}`} target={target} rel={rel}>
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={label} />
                                </ListItemButton>
                            )
                        })
                    }
                    {
                        user &&
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <LockOpenIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" secondary={username} />
                        </ListItemButton>
                    }
                </List>
            </Popover>
        </>
    );
}