import { call, put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { RootState } from "../../app/store";
import { weatherActions, WeatherProductType } from "./weatherSlice";
import { WEATHER_DATA_HISTORY_LENGTH } from '../../config/weather';
import { WEATHER_API } from './weatherApi';
import { toastActions } from "../toast/toastSlice";
import { NAVIGATION_TOAST_CONTAINER_ID, WEATHER_TOAST_INFO_ID } from '../../config/toast';

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* initWeatherProducts(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    let products: any = yield select((state: RootState) => {
        if (state.mission.active) return (state.mission.allowed[state.mission.active].config?.weather?.products || {});
        return ({});
    });

    const lsWeather = JSON.parse(localStorage.getItem('_weather') || '{}');

    yield all(Object.entries(products).map(([key, value]: [string, any], idx) => put(weatherActions.add_product({
        id: key,
        product: {
            ...value, 
            data: new Array(WEATHER_DATA_HISTORY_LENGTH).fill(null),
            dataIndex: 0,
            //visible: lsWeather[key]?.visible !== undefined ? lsWeather[key]?.visible : false,
            zIndex: lsWeather[key]?.zIndex || idx + 1,
            opacity: lsWeather[key]?.opacity || 0.5
        }
    }))));
};

function* watchSetActiveMission() {
    yield takeLatest([missionActions.set_active_mission, replayActions.stop], initWeatherProducts);
};

function* setLocalStorage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const weather = yield select((state: RootState) => state.nav.weather);
    let ls = {};
    Object.entries(weather).forEach(([productId, product]: [string, any]) => {
        ls = {
            ...ls,
            [productId]: {
                visible: product.visible,
                zIndex: product.zIndex,
                opacity: product.opacity
            }
        };
    });

    localStorage.setItem('_weather', JSON.stringify(ls));
};

function* watchProductMove() {
    yield takeLatest([
        weatherActions.set_visibility,
        weatherActions.move_down,
        weatherActions.move_up,
        weatherActions.set_opacity
    ], setLocalStorage);
};

function* getProduct(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(weatherActions.get_product({ id: action.payload.id }));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(WEATHER_API.getProduct, token, missionId, action.payload.id);
        yield put(weatherActions.get_product_success({ id: action.payload.id }));
    } catch (error: any) {
        yield put(weatherActions.get_product_failure({ id: action.payload.id, status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchRefresh() {
    yield takeEvery(weatherActions.refresh, getProduct);
};

function* getProductMaybe(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const available: WeatherProductType = yield select((state: RootState) => state.nav.weather[action.payload.id].available);
    if (!available) yield getProduct(action);
};

function* watchSetVisibility() {
    yield takeEvery((action: any) => {
        return (action.type === weatherActions.set_visibility.type && action.payload.visible === true);
    }, getProductMaybe);
};

function* openProductToast(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    yield put(toastActions.add_toast({
        containerId: NAVIGATION_TOAST_CONTAINER_ID,
        toast: {
            id: `weather-info-${action.payload.id}-${action.payload.properties.tracking_id}`,
            variant: WEATHER_TOAST_INFO_ID,
            persist: true,
            vertical: 'bottom',
            horizontal: 'center',
            productId: action.payload.id,
            properties: action.payload.properties
        }
    }));
};

function* watchProductClick() {
    yield takeEvery(weatherActions.product_click, openProductToast);
};

export default function* watchWeather() {
    yield all([
        watchSetActiveMission(),
        watchProductMove(),
        watchRefresh(),
        watchSetVisibility(),
        watchProductClick(),
    ]);
};