import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Snap as SnapInteraction } from "ol/interaction";
import { get as getProjection } from "ol/proj";
import { RContext, RLayerVector } from "rlayers";
import Map from 'ol/Map';
import Vector from 'ol/source/Vector';
import Layer from 'ol/layer/Layer';
import { RootState } from "../../../../app/store";

function Snap({ map, source }: { map: Map, source: Vector }) {
    const ruling = useSelector((state: RootState) => state.map.ruler.ruling);

    useEffect(() => {
        let snap: any = null;

        if (ruling) {
            setTimeout(() => {
                snap = new SnapInteraction({ source });
                map.addInteraction(snap);
            }, 0);
        };

        return () => {
            if (snap) map.removeInteraction(snap);
        }
    }, [map, source, ruling]);

    return null;
};

function Projection({ source, currentProj }: { source: Vector, currentProj: string }) {

    const projection = useSelector((state: RootState) => state.map.projection);

    useEffect(() => {
        if (currentProj && currentProj !== projection) {
            const newProj = getProjection(projection);
            if (newProj) {
                const features = source.getFeatures();
                for (let i = 0; i < features.length; i += 1) {
                    features[i].getGeometry()?.transform(currentProj, newProj);
                }
            }
        }

    }, [source, projection, currentProj]);

    return null;
};

function Background({ layer }: { layer: Layer }) {
    const background = useSelector((state: RootState) => state.map.background);

    useEffect(() => {
        layer.changed();
    }, [layer, background])

    return null
};

export default function VectorLayer(props: any) {
    const { children, component: Component = RLayerVector, innerRef, rulingSnap = false, ...others } = props;
    return (
        <Component ref={innerRef} {...others}>
            <RContext.Consumer>
                {({ map, vectorsource, vectorlayer }) => (
                    <>
                        {rulingSnap && map && vectorsource && <Snap map={map} source={vectorsource} />}
                        {vectorsource && map && <Projection source={vectorsource} currentProj={map.getView().getProjection().getCode()} />}
                        {vectorlayer && <Background layer={vectorlayer} />}
                    </>
                )}
            </RContext.Consumer>
            {children}
        </Component>
    );
};