import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ChangePassword from './ChangePassword';
import { RootState } from "../../../app/store";
import { authActions } from "../../../redux/auth/authSlice";

export default function UserBox() {

    const dispatch = useDispatch();
    const { username, level } = useSelector((state: RootState) => state.auth);
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [isPwDialogOpen, setPwDialogOpen] = useState(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handlePwDialogOpen = () => {
        setPwDialogOpen(true);
    };

    const handlePwDialogClose = () => {
        setPwDialogOpen(false);
    };

    const handleLogout = () => {
        dispatch(authActions.logout({}));
    };

    return (
        <>
            <Button
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.dark,
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.main,
                    },
                }}
                variant='contained'
                ref={ref}
                onClick={handleOpen}
                startIcon={<PersonIcon />}
            >
                <Typography sx={{ fontSize: '1.2rem', display: { xs: 'none', sm: 'block' } }} variant="body1">{username}</Typography>
            </Button>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >

                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    subheader={
                        <ListSubheader component="div">
                            <Box p={2} display="flex" sx={{ alignItems: 'center' }}>
                                <PersonIcon />
                                <Typography ml={0.5} sx={{ lineHeight: '100%' }} variant="subtitle1">{username}</Typography>
                                <Typography ml={0.5} sx={{ lineHeight: '100%' }} variant="subtitle2">({level})</Typography>
                            </Box>
                            <Divider sx={{ mb: 0 }} />
                        </ListSubheader>
                    }
                >
                    <ListItemButton onClick={handlePwDialogOpen}>
                        <ListItemIcon>
                            <VpnKeyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Change password" />
                    </ListItemButton>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <LockOpenIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </List>
            </Popover>
            <ChangePassword open={isPwDialogOpen} onClose={handlePwDialogClose} />
        </>
    );
};