import { Fill, Stroke, Style, Text, Icon } from "ol/style";
import { isBackgroundDark } from "../../../../config/map";

export const airportStyle = (feature: any, _resolution: any) => {

    const fill_color = isBackgroundDark() ? "white" : "#4d4d4d";
    const stroke_color = isBackgroundDark() ? "black" : "white";
    const icon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="0 0 14.0625 14.0625">
      <g fill="${fill_color}">
      <path style="stroke-width:10" d="M 2.6313559,7.6302966 H 0.58474576 C 0.2619661,7.6302966 0,7.3683305 0,7.0455508 0,6.7227712 0.2619661,6.4608051 0.58474576,6.4608051 H 2.6313559 c 0.3227797,0 0.5847458,0.2619661 0.5847458,0.5847457 0,0.3227797 -0.2619661,0.5847458 -0.5847458,0.5847458 z M 7.0169492,14.0625 c -0.3227797,0 -0.5847458,-0.261966 -0.5847458,-0.584746 v -2.04661 c 0,-0.32278 0.2619661,-0.584746 0.5847458,-0.584746 0.3227796,0 0.5847457,0.261966 0.5847457,0.584746 v 2.04661 c 0,0.32278 -0.2619661,0.584746 -0.5847457,0.584746 z M 13.449153,7.6302966 h -2.046611 c -0.322779,0 -0.584745,-0.2619661 -0.584745,-0.5847458 0,-0.3227796 0.261966,-0.5847457 0.584745,-0.5847457 h 2.046611 c 0.322779,0 0.584745,0.2619661 0.584745,0.5847457 0,0.3227797 -0.261966,0.5847458 -0.584745,0.5847458 z M 7.0169492,3.2447034 c -0.3227797,0 -0.5847458,-0.2619661 -0.5847458,-0.5847458 V 0.61334746 c 0,-0.32277966 0.2619661,-0.58474577 0.5847458,-0.58474577 0.3227796,0 0.5847457,0.26196611 0.5847457,0.58474577 V 2.6599576 c 0,0.3227797 -0.2619661,0.5847458 -0.5847457,0.5847458 z m 0,9.7944916 c -3.3049831,0 -5.9936441,-2.688661 -5.9936441,-5.9936442 0,-3.304983 2.688661,-5.993644 5.9936441,-5.993644 3.3049828,0 5.9936438,2.688661 5.9936438,5.993644 0,3.3049832 -2.688661,5.9936442 -5.9936438,5.9936442 z m 0,-10.8177967 c -2.6600085,0 -4.8241526,2.1641441 -4.8241526,4.8241525 0,2.6600085 2.1641441,4.8241522 4.8241526,4.8241522 2.6600084,0 4.8241528,-2.1641437 4.8241528,-4.8241522 0,-2.6600084 -2.1641444,-4.8241525 -4.8241528,-4.8241525 z"/>
      </g>
    </svg>`;
    const scale =
        feature.get("type") === "LARGE_AIRPORT"
            ? 2.5
            : feature.get("type") === "MEDIUM_AIRPORT"
                ? 2
                : 1.5;

    let styles = [
        new Style({
            image: new Icon({
                opacity: 1,
                src: "data:image/svg+xml;charset=utf-8," + encodeURIComponent(icon),
                scale: 0.03 * scale
            }),
            text: new Text({
                text: feature.get("icao"),
                font: "bold 10px sans-serif",
                textBaseline: "top",
                offsetY: 12,
                fill: new Fill({ color: fill_color }),
                stroke: new Stroke({
                    color: stroke_color,
                    width: 2
                }),
            })
        })
    ];

    return styles;
};
