import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
    MAP_BACKGROUND,
    MAP_PROJECTION,
    MAP_VIEW,
    MAP_GRATICULE,
    MAP_COORDINATES_SYNTAX,
    MAP_LOCAL_ORIGIN,
    MAP_ENABLE_GM_MEASURES
} from '../../config/map';
import { RView } from 'rlayers/RMap';
import { transform } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';
import { getBottomLeft, getTopRight } from 'ol/extent.js';

interface ViewType extends RView {
    extent?: Coordinate;
    rotation?: number;
};

export interface RulerType {
    ruling: boolean;
    rulers: {
        type: string;
        features: FeatureType[];
    };
};

export interface FeatureType {
    id?: string;
    type?: string;
    geometry: any; // TODO
    properties?: any; // TODO
};

export type OriginType = {
    lon: number;
    lat: number;
    alt: number;
    show_layer: boolean;
};

export interface MapState {
    available_backgrounds: any[];
    background: number;
    projection: string;
    graticule: boolean;
    view: ViewType;
    ruler: RulerType;
    coordinates_syntax: string;
    localOrigin: OriginType;
    enable_gm_measures: boolean;
};

const initialState: MapState = {
    available_backgrounds: [],
    background: MAP_BACKGROUND,
    projection: MAP_PROJECTION,
    graticule: MAP_GRATICULE,
    view: MAP_VIEW,
    coordinates_syntax: MAP_COORDINATES_SYNTAX,
    ruler: {
        ruling: false,
        rulers: {
            type: "FeatureCollection",
            features: []
        }
    },
    localOrigin: MAP_LOCAL_ORIGIN,
    enable_gm_measures: MAP_ENABLE_GM_MEASURES,
};

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        set_available_backgrounds: ((state, action) => {
            state.available_backgrounds = action.payload.backgrounds;
        }),
        set_background: ((state, action) => {
            state.background = action.payload.background;
        }),
        set_projection: ((state, action) => {
            state.projection = action.payload.projection;
        }),
        set_graticule: ((state, action) => {
            state.graticule = action.payload.graticule;
        }),
        set_coordinates_syntax: ((state, action) => {
            state.coordinates_syntax = action.payload.coordinates_syntax;
        }),
        set_origin: ((state, action) => {
            state.localOrigin.lon = action.payload.lon;
            state.localOrigin.lat = action.payload.lat;
            state.localOrigin.alt = action.payload.alt;
        }),
        set_show_origin_layer: ((state, action) => {
            state.localOrigin.show_layer = action.payload.show_layer;
        }),
        set_view: ((state, action) => {
            let bottomLeft = transform(getBottomLeft(action.payload.extent), state.projection, "EPSG:4326");
            let topRight = transform(getTopRight(action.payload.extent), state.projection, "EPSG:4326");
            let center = transform(action.payload.center, state.projection, "EPSG:4326");
            state.view = {
                extent: [...bottomLeft, ...topRight],
                center,
                zoom: action.payload.zoom,
                rotation: action.payload.rotation
            };
        }),
        set_enable_gm_measures: ((state, action) => {
            state.enable_gm_measures = action.payload.enable_gm_measures;
        }),
        start_ruling: ((state, action) => {
            state.ruler.ruling = true;
        }),
        stop_ruling: ((state, action) => {
            state.ruler.ruling = false;
        }),
        add_ruler: ((state, action) => {
            state.ruler.ruling = false;
            state.ruler.rulers.features.push(action.payload.feature);
        }),
        update_ruler: ((state, action) => {
            let index = state.ruler.rulers.features.findIndex((r: FeatureType) => r.properties.id === action.payload.id);
            if (index !== -1) {
                state.ruler.rulers.features[index].geometry.coordinates = action.payload.coordinates;
                state.ruler.rulers.features[index].properties = action.payload.properties;
            };
        }),
        clear_ruler: ((state, action) => {
            let index = state.ruler.rulers.features.findIndex((r: FeatureType) => r.properties.id === action.payload.id);
            if (index !== -1) state.ruler.rulers.features.splice(index, 1);
        }),
        clear_rulers: ((state, action) => {
            state.ruler.rulers.features = [];
        }),
    },
});

export const selectMap = (state: RootState) => state.map;

export const mapActions = mapSlice.actions;

export default mapSlice.reducer;
