import { OnMessageCallback } from 'mqtt';
import { store } from '../../app/store';
import { weatherActions } from './weatherSlice';

export const weatherMqttParser: OnMessageCallback = (topic: any, payload: any, packet: any): void => {
    const [, , , status] = topic.split("/");
    const msg = JSON.parse(payload.toString());

    switch (status) {
        case 'available':
            store.dispatch(weatherActions.product_available({...msg}));
            break;
        case 'progress':
            store.dispatch(weatherActions.product_progress({...msg}));
            break;
        case 'response':
            store.dispatch(weatherActions.product_response({...msg}));
            break;
        default:
            break;
    };
};