
export interface AuthLevelObject {
    value: number;
    api_key: string | null;
};

export const AUTH_LEVELS: { [key: string]: AuthLevelObject } = {
    not_logged_in: { value: 0, api_key: null },
    passive : {value : 1, api_key: 'passive'},
    user: { value: 2, api_key: 'user' },
    admin: { value: 3, api_key: 'admin' },
    superuser: { value: 4, api_key: 'superuser' }
};

export const AUTH_LOGIN_URL = "/auth/token/login/";
export const AUTH_LOGOUT_URL = "/auth/token/logout/";
export const AUTH_CHECK_URL = "/auth/users/me/";