import React from "react";
import Badge from '@mui/material/Badge';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function TelemetryIcon() {
    return (
        <Badge
            color="error"
            variant="dot"
            invisible={true}
        >
            <BarChartIcon />
        </Badge>
    );
};