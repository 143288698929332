import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Chip from "@mui/material/Chip";
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LensIcon from '@mui/icons-material/Lens';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import StringUtils from '../../../utils/StringUtils';
import { RootState } from '../../../app/store';
import { MissionType, VehicleType } from '../../../redux/mission/missionSlice';
import { FleetStatusProvider, useVehicleStatus } from './MissionSelectorContext';


const MyDialogTitle = (props: any) => {
    const { children, action, ...other } = props;
    return (
        <DialogTitle sx={{
            margin: 0,
            padding: (theme) => theme.spacing(2),
            backgroundColor: (theme) => theme.palette.primary.dark
        }} {...other}>
            {children}
            {action}
        </DialogTitle>
    );
};

const VehcileChip = ({ vehicle }: { vehicle: VehicleType }) => {

    const status = useVehicleStatus({ vehicleId: vehicle.id });

    return (
        <Chip
            icon={vehicle.virtual ? undefined : <LensIcon sx={{ fontSize: 12, color: status === 'online' ? '#44b700 !important' : status === 'offline' ? "red !important" : 'grey !imporant' }} />}
            label={vehicle.name}
            sx={{
                margin: (theme) => theme.spacing(0.5),
                '&.MuiChip-root': {
                    color: (theme) => theme.palette.text.primary,
                    borderWidth: '1px',
                    borderStyle: vehicle.virtual ? "dashed solid" : "solid",
                    borderColor: StringUtils.stringToColor(vehicle.id, true)
                },
            }}
            variant="outlined"
        />
    );
};

const FleetChips = ({ fleet }: { fleet: VehicleType[] }) => {
    return (
        <Paper component="ul" elevation={0}
            sx={{
                backgroundColor: "inherit",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                margin: 0,
                width: "100%",
                maxHeight: 'inherit',
                overflow: 'auto'
            }}
        >
            {fleet.map((v, index) => {
                return (
                    <li key={v.id}>
                        <VehcileChip vehicle={v} />
                    </li>
                )
            })}
        </Paper>
    );
};

function MissionRow({ mission, active, onClick }: { mission: MissionType, active: string, onClick: () => void }) {
    const history = useHistory();
    const name = mission.name || mission.id;

    const onRowClick = (e: any) => {
        onClick();
        history.push(`/${mission.id}/dashboard/`);
    };

    return (
        <TableRow
            sx={{
                textDecoration: 'none',
                maxWidth: 'none',
                backgroundColor: (theme) => (active === mission.id) ? theme.palette.action.selected : '',
                // hide last border
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
                '&:hover': { backgroundColor: (theme) => theme.palette.action.hover, cursor: 'pointer' },
            }}
            onClick={onRowClick}
        >
            <TableCell component="th" scope="row">
                {name}
            </TableCell>
            <TableCell align="right">{mission.id}</TableCell>
            <TableCell align="right"><FleetChips fleet={mission.fleet} /></TableCell>
        </TableRow>
    );
};

export default function MissionSelector() {
    const { allowed, active } = useSelector((state: RootState) => state.mission);
    const [isOpen, setOpen] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleChange = () => {
        setShowEmpty(current => !current);
    };

    if (!active) return null;
    return (
        <>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.dark,
                    '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.main,
                    },
                    '& .MuiButton-root': {
                        maxHeight: (theme) => theme.mixins.toolbar.minHeight,
                    }
                }}
                onClick={handleOpen}
            >
                <Typography
                    variant="h6"
                    sx={{
                        maxWidth: '15em',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >{allowed[active].name || allowed[active].id}</Typography>
            </Button>
            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='md'>
                <MyDialogTitle
                    action={
                        <FormControlLabel
                            sx={{
                                position: 'absolute',
                                right: (theme) => theme.spacing(2),
                                top: (theme) => theme.spacing(1),
                                color: (theme) => theme.palette.grey[500],
                            }}
                            value="start"
                            control={
                                <Checkbox
                                    checked={showEmpty}
                                    onChange={handleChange}
                                    color="secondary"
                                />
                            }
                            label="Show empty missions"
                            labelPlacement="start"
                        />
                    }
                >
                    Choose a Mission
                </MyDialogTitle>
                <DialogContent sx={{ backgroundColor: (theme) => theme.palette.primary.dark, }}>
                    <FleetStatusProvider>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Id</TableCell>
                                        <TableCell align="right">Fleet</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(allowed).filter(mId => showEmpty || allowed[mId].fleet.length > 0).map((id) => (
                                        <MissionRow key={id} mission={allowed[id]} active={active} onClick={handleClose} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </FleetStatusProvider>
                </DialogContent>
            </Dialog>
        </>
    );
}