import React, { createContext, useContext, useMemo, useReducer } from "react";
import { Options } from "uplot";
import produce from "immer";

export type GraphType = {
    id: string;
    size: number;
    timespan: number;
    series: any[];
    options: Options;
    maxHeight?: number;
    isLoading: boolean;
    onValueBoxClick?: (id: string) => void
}

type GraphState = GraphType[];

type GraphValueContextType = {
    graphs: GraphType[];
};

type GraphActionContextType = {
    add: (id: string, size: number, timespan: number, options: any) => any;
    remove: (id: string) => any;
    move: (id: string, newIndex: number) => any;
    clear: () => any;
    setData: (id: string, data: any[]) => any;
    setSize: (id: string, size: number) => any;
    setTimespan: (id: string, timespan: number) => any;
    setOptions: (id: string, options: any) => any;
    setLoading: (id: string, isLoading: boolean) => any;
};

const GraphActionContext = createContext<GraphActionContextType | null>(null);
const GraphValueContext = createContext<GraphValueContextType>({ graphs: [] });

function reducer(state: GraphState, action: any): GraphState {
    const { type, id, newIndex, size, timespan, data, options, isLoading } = action;
    let index;
    switch (type) {
        case 'add':
            index = state.findIndex(g => g.id === id);
            if (index === -1) return [...state, { id, size, timespan, series: [[], []], options, isLoading: true }];
            break;
        case 'remove':
            index = state.findIndex(g => g.id === id);
            if (index !== -1) return [...state.slice(0, index), ...state.slice(index + 1)];
            break;
        case 'move':
            return produce(state, draft => {
                const index = state.findIndex(g => g.id === id);
                if (index !== -1) {
                    if (newIndex >= draft.length) {
                        let k = newIndex - draft.length + 1;
                        while (k--) {
                            draft.push(undefined as unknown as GraphType);
                        }
                    }
                    draft.splice(newIndex, 0, draft.splice(index, 1)[0]);
                }
            });
        case 'clear':
            return [];
        case 'setData':
            return produce(state, draft => {
                const index = state.findIndex(g => g.id === id);
                if (index !== -1 && data.length > 0) {
                    let filtered_data = new Array(data.length).fill([]);
                    let last_t = data[0][data[0].length - 1];
                    filtered_data[0] = data[0].filter((t: number) => t >= last_t - state[index].timespan);
                    for (let j = 1; j < data.length; j++) {
                        filtered_data[j] = data[j].slice(-filtered_data[0].length);
                    };
                    draft[index].series = filtered_data;
                    draft[index].isLoading = false;
                };
            });
        case 'setSize':
            return produce(state, draft => {
                const index = state.findIndex(g => g.id === id);
                if (index !== -1) draft[index].size = size;
            });
        case 'setTimespan':
            return produce(state, draft => {
                const index = state.findIndex(g => g.id === id);
                if (index !== -1) draft[index].timespan = timespan;
            });
        case 'setOptions':
            return produce(state, draft => {
                const index = state.findIndex(g => g.id === id);
                if (index !== -1) draft[index].options = options;
            });
        case 'setLoading':
            return produce(state, draft => {
                const index = state.findIndex(g => g.id === id);
                if (index !== -1) draft[index].isLoading = isLoading;
            });
        default:
            return state;
    }

    return state;
}

export function GraphContextProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(reducer, []);

    const actions = useMemo(() => {
        const add = (id: string, size: number, timespan: number, options: Options, isLoading: boolean = false) => {
            dispatch({ type: 'add', id, size, timespan, options, isLoading });
        };

        const remove = (id: string) => {
            dispatch({ type: 'remove', id });
        };

        const move = (id: string, newIndex: number) => {
            dispatch({ type: 'move', id, newIndex });
        };

        const clear = () => {
            dispatch({ type: 'clear' });
        };

        const setData = (id: string, data: any[]) => {
            dispatch({ type: 'setData', id, data });
        };

        const setSize = (id: string, size: number) => {
            dispatch({ type: 'setSize', id, size });
        };

        const setTimespan = (id: string, timespan: number) => {
            dispatch({ type: 'setTimespan', id, timespan });
        };

        const setOptions = (id: string, options: Options) => {
            dispatch({ type: 'setOptions', id, options });
        };

        const setLoading = (id: string, isLoading: boolean) => {
            dispatch({ type: 'setLoading', id, isLoading });
        };

        return { add, remove, move, clear, setData, setSize, setTimespan, setOptions, setLoading };
    }, []);

    return (
        <GraphActionContext.Provider value={actions}>
            <GraphValueContext.Provider value={{ graphs: state }}>
                {children}
            </GraphValueContext.Provider>
        </GraphActionContext.Provider>
    );
};

export const useGraphActionContext = () => useContext(GraphActionContext);
export const useGraphValueContext = () => useContext(GraphValueContext);