import uPlot from "uplot";

const fmtDate = uPlot.fmtDate("{DD}/{MM}/{YY} {HH}:{mm}:{ss}");

export function TStooltipPlugin() {
    let seriestt = [];
    let prevIdx = null;

    function addSeries(u, seriesIdx) {
        if (seriesIdx === 0) return;

        if (!u.cursortt) u.cursortt = [];

        let tt = u.cursortt[seriesIdx] = document.createElement("div");
        tt.style.display = "none";
        tt.className = "TStooltip";
        tt.style.pointerEvents = "none";
        tt.style.position = "absolute";
        tt.style.color = "black";
        tt.style.background = u.series[seriesIdx].stroke();
        tt.style.boxShadow = "2px 2px 10px rgba(0,0,0,0.5)";
        tt.style.fontFamily = "monospace";
        tt.style.padding = "5px";
        tt.style.whiteSpace = "pre";
        tt.style.zIndex = 100;
        u.over.appendChild(tt);
        seriestt[seriesIdx] = tt;
    };

    function delSeries(u, seriesIdx) {
        if (seriesIdx === 0) return;
        u.over.removeChild(seriestt[seriesIdx]);
        seriestt = [...seriestt.slice(0, seriesIdx), ...seriestt.slice(seriesIdx + 1)];
    };

    function init(u, opts, data) {
        let over = u.over;

        function hideTips() {
            seriestt.forEach((tt, i) => {
                if (i === 0) return;

                tt.style.display = "none";
            });
        }

        function showTips() {
            seriestt.forEach((tt, i) => {
                if (i === 0) return;

                let s = u.series[i];
                tt.style.display = s.show ? null : "none";
            });
        }

        over.addEventListener("mouseleave", () => {
            if (!u.cursor._lock) {
                hideTips();
            }
        });

        over.addEventListener("mouseenter", () => {
            showTips();
        });

        if (u.cursor.left < 0)
            hideTips();
        else
            showTips();
    };

    function setCursor(u) {
        const { idx } = u.cursor;

        if (idx === prevIdx) return;

        seriestt.forEach((tt, i) => {
            if (i === 0) return;

            let s = u.series[i];

            if (s.show) {
                let xVal = u.data[0][idx];
                let yVal = u.data[i][idx];

                if (yVal === undefined) {
                    tt.style.display = "none";
                    return;
                } else {

                    const content = [];
                    content.push("Time: " + fmtDate(uPlot.tzDate(new Date(xVal * 1e3), 'UTC')) + ' UTC');
                    content.push(`${u.series[i].label}: ${yVal?.toFixed(u.series[i].toFixed)} ${u.series[i].unit}`);
                    tt.textContent = content.join('\n');
                    tt.style.display = null;

                    const isRight = u.width - Math.round(u.valToPos(xVal, 'x')) < u.cursortt[i].offsetWidth + 100;

                    if (isRight) tt.style.left = (Math.round(u.valToPos(xVal, 'x')) - u.cursortt[i].offsetWidth) + "px";
                    else tt.style.left = Math.round(u.valToPos(xVal, 'x')) + "px";

                    const isBottom = u.height - Math.round(u.valToPos(yVal, 'y')) < u.cursortt[i].offsetHeight + 100;
                    if (isBottom) tt.style.top = (Math.round(u.valToPos(yVal, 'y')) - u.cursortt[i].offsetHeight) + "px";
                    else tt.style.top = Math.round(u.valToPos(yVal, 'y')) + "px";
                };
            };
        });

        prevIdx = idx;
    };

    function setData(u) {
        const { idx } = u.cursor;

        seriestt.forEach((tt, i) => {
            if (i === 0) return;

            let s = u.series[i];

            if (s.show) {
                let xVal = u.data[0][idx];
                let yVal = u.data[i]?.[idx];

                if (xVal !== undefined && yVal !== undefined) {
                    const content = [];
                    content.push("Time: " + fmtDate(uPlot.tzDate(new Date(xVal * 1e3), 'UTC')) + ' UTC');
                    content.push(`${u.series[i].label}: ${yVal?.toFixed(u.series[i].toFixed)} ${u.series[i].unit}`);
                    tt.textContent = content.join('\n');
                    tt.style.display = null;

                    const isRight = u.width - Math.round(u.valToPos(xVal, 'x')) < u.cursortt[i].offsetWidth + 100;
                    if (isRight) tt.style.left = (Math.round(u.valToPos(u.data[0][idx - 1], 'x')) - u.cursortt[i].offsetWidth) + "px";
                    else tt.style.left = Math.round(u.valToPos(u.data[0][idx - 1], 'x')) + "px";

                    const isBottom = u.height - Math.round(u.valToPos(yVal, 'y')) < u.cursortt[i].offsetHeight + 100;
                    if (isBottom) tt.style.top = (Math.round(u.valToPos(yVal, 'y')) - u.cursortt[i].offsetHeight) + "px";
                    else tt.style.top = Math.round(u.valToPos(yVal, 'y')) + "px";
                };
            };

        });
    };

    return {
        hooks: {
            init,
            setCursor,
            addSeries,
            delSeries,
            setData
        },
    };
};

export function onHoverPlugin({ onHover }: { onHover: (props: any) => void }) {
    let prevIdx = null;


    function init(u, opts, data) {
        let over = u.over;

        over.addEventListener("mouseleave", () => {
            if (!u.cursor._lock) {
                onHover(null);
            }
        });
    };

    function setCursor(u) {
        const { idx } = u.cursor;

        if (idx === prevIdx) return;
        if (idx === null || idx === undefined) return;

        const position_array_shift = Math.trunc(u.data.length / 2);

        u.series.forEach((s, i) => {
            if (i === 0) return;
            if (s.show) onHover({ id: `${s.id}-${i}`, position: u.data[i + position_array_shift][idx], color: s._stroke });
        });

        prevIdx = idx;
    };

    return {
        hooks: {
            init,
            setCursor,
        },
    };
};