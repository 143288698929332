import { Action, Dispatch } from "redux";
import type { Middleware } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { toastActions } from "../toast/toastSlice";
import {
    DASHBOARD_TOAST_CONTAINER_ID,
    DASHBOARD_TOAST_INVALID_TOKEN,
    DASHBOARD_TOAST_API_ERROR,
} from '../../config/toast';

export const apiQueryErrorHandler: Middleware = ({ dispatch }: { dispatch: Dispatch<Action> }) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (action.type.endsWith('_failure') && action.payload?.status === 403) {
        if (action.payload.data.detail.toLowerCase().includes('invalid token')) {
            dispatch(
                toastActions.add_toast({
                    containerId: DASHBOARD_TOAST_CONTAINER_ID,
                    toast: {
                        id: `error-${uuidv4()}`,
                        variant: DASHBOARD_TOAST_INVALID_TOKEN,
                        severity: "error",
                        autoHideDuration: null,
                        message: action.payload.data.detail,
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                })
            );
        } else {
            dispatch(
                toastActions.add_toast({
                    containerId: DASHBOARD_TOAST_CONTAINER_ID,
                    toast: {
                        id: `error-${uuidv4()}`,
                        variant: DASHBOARD_TOAST_API_ERROR,
                        severity: "error",
                        autoHideDuration: 6000,
                        message: action.payload.data.detail,
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                })
            );
        };
    };

    return next(action);
};