import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#6b3640"
        },
        secondary: {
            main: "#FF9B36"
        },
        background: {
            paper: "#512930",
            default: "#442229"
        }
    },
    typography: {
        fontFamily: "Roboto",
        fontSize: 14,
        button: {
            fontWeight: 500,
            fontSize: 14,
            textTransform: "none"
        }
    },
    mixins: {
        toolbar: {
            minHeight: '128px'
        }
    },
});