import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { ConfirmProvider } from 'material-ui-confirm';
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import AppRoutes from './components/routes/AppRoutes';
import { RootState } from './app/store';
import { mqttActions } from './redux/mqtt/mqttSlice';
import { theme as baseTheme } from './theme/theme';
import { theme as replayTheme } from './theme/replayTheme';
import { APP_ID } from './config/const';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./components/navigation/telemetry/flight-indicators/flightindicators.css";

moment.tz.setDefault('UTC'); // TODO : is this still usefull ?

function App() {
  const dispatch = useDispatch();
  const mqttInit = useRef(false);
  const appRef = useRef(APP_ID);
  const settings = useSelector((state: RootState) => state.mqtt.settings);
  const isActive = useSelector((state: RootState) => state.replay.isActive);

  useEffect(() => {
    const options = {
      ...settings,
      clientId: `planet-${appRef.current}`,
    };
    dispatch(mqttActions.connect(options));
    mqttInit.current = true;

    return () => {
      if (mqttInit.current) dispatch(mqttActions.end());
      mqttInit.current = false;
    }
  }, [dispatch, settings]);

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission !== "granted" && Notification.permission !== "denied") {
        Notification.requestPermission();
      }
    } else {
      console.warn("Notifications are not available for this browser");
    }
  }, []);

  return (
    <ThemeProvider theme={isActive ? replayTheme : baseTheme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ConfirmProvider>
          <HelmetProvider>
            <Router>
              <AppRoutes />
            </Router>
          </HelmetProvider>
        </ConfirmProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
