import { call, put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { missionActions, VehicleType } from '../mission/missionSlice';
import { CHAT_API } from "./chatApi";
import { RootState } from "../../app/store";
import { chatActions, ConversationType, ConversationsType } from "./chatSlice";
import { authActions } from "../auth/authSlice";
import { GROUND_PLID } from "../../config/const";
import { ALL_MESSAGES_CHANNEL_ID } from "../../config/chat";
import { replayActions } from "../replay/replaySlice";
import { toDate } from "../../utils/TimeUtils";

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getConversations(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(chatActions.get_conversations({}));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(CHAT_API.getConversations, token, missionId);
        yield all(res.data.sort(function (a: any, b: any) { return (a.created < b.created) }).map((c: any) => put(chatActions.add_conversation({ ...c }))));
        const activeConversationIdFromLS = localStorage.getItem('activeConversationId');
        let activeConvId = null;
        if (activeConversationIdFromLS && [...res.data, { id: ALL_MESSAGES_CHANNEL_ID }].map((c: any) => c.id).includes(activeConversationIdFromLS)) {
            activeConvId = activeConversationIdFromLS;
        } else if (res.data.length > 0) {
            activeConvId = res.data[0].id;
        }
        yield put(chatActions.set_active_conversation({ id: activeConvId }));
        yield put(chatActions.get_conversations_success({ conversations: res.data }));

        // Get notifications from LS
        const chatLS = JSON.parse(localStorage.getItem("_chat") || "{}");
        yield put(chatActions.set_notifications({ notifications: chatLS.notifications || {} }));
        yield put(chatActions.set_nickname({ nickname: chatLS.nickname || null }));
    } catch (error: any) {
        yield put(chatActions.get_conversations_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchSetActiveMission() {
    yield takeLatest([missionActions.set_active_mission, replayActions.stop], getConversations);
};

function* getConversationsHistory(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(chatActions.get_conversations({}));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(CHAT_API.getConversationsHistory, token, missionId, action.payload.from, action.payload.to);

        /* Retrieve only the initial object for each conversation */
        const current_conv_objects = res.data.reduce((acc: any[], curr: any) => {
            const first = curr.history[0].content_object;
            if (first.created <= action.payload.from) acc.push(first);
            return acc;
        }, []);

        const history = res.data.map((action: any) => {
            let h: any = {};
            action.history.forEach((ac: any, i: number) => {
                h[ac.received] = ac.content_object
            });
            return h;
        });

        yield put(chatActions.set_conversations(current_conv_objects.sort(function (a: any, b: any) { return (a.created < b.created) })));
        yield put(chatActions.set_active_conversation({ id: current_conv_objects[0]?.id }));
        yield put(chatActions.get_conversations_history_success({ history, from: action.payload.from, to: action.payload.to }));
    } catch (error: any) {
        yield put(chatActions.get_conversations_history_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchReplayStart() {
    yield takeLatest((action: any) => (action.type === replayActions.start.type && !action.payload.fromFile), getConversationsHistory);
};

function* replayUpdate(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const currentTime: string = yield select((state: RootState) => state.replay.currentTime);
    const conv_history: any[] = yield select((state: RootState) => state.chat.conversations.history);
    const messages_history: any[] = yield select((state: RootState) => state.chat.messages_history);

    let conv_objects = conv_history.reduce((acc: any[], curr: any) => {
        const ret = Object.entries(curr).reverse().find(([time, el]: [string, any]) => {
            return (time < currentTime);
        });
        if (ret) acc.push(ret[1]);
        return acc;
    }, []);

    // We remove deleted convs from the list
    conv_objects = conv_objects.filter((gm: any) => {
        const deleteTS = toDate(gm.delete).getTime() + 3 * 60000; // We are adding 3 minutes to get the real delete TS because when deleting an object, we are removing 3 minutes.
        const currentTS = toDate(currentTime).getTime();
        return !(deleteTS <= currentTS);
    });

    yield put(chatActions.set_conversations(conv_objects));

    let messages_objects = messages_history.reduce((acc: any[], curr: any) => {
        const ret = Object.entries(curr).reverse().find(([time, el]: [string, any]) => {
            return (time < currentTime);
        });
        if (ret) acc.push(ret[1]);
        return acc;
    }, []);

    // We remove deleted messages from the list
    messages_objects = messages_objects.filter((gm: any) => {
        const deleteTS = toDate(gm.delete).getTime() + 3 * 60000; // We are adding 3 minutes to get the real delete TS because when deleting an object, we are removing 3 minutes.
        const currentTS = toDate(currentTime).getTime();
        return !(deleteTS <= currentTS);
    });

    yield put(chatActions.get_messages_success({ messages: messages_objects }));
};

function* watchReplayUpdate() {
    yield takeLatest([replayActions.increment_time, replayActions.set_currentTime], replayUpdate);
};


function* getMessagesHistory(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(chatActions.get_messages_history({}));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(CHAT_API.getMessagesHistory, token, missionId, action.payload.from, action.payload.to);

        /* Retrieve only the initial object for each conversation */
        const current_messages_objects = res.data.reduce((acc: any[], curr: any) => {
            const first = curr.history[0].content_object;
            if (first.created <= action.payload.from) acc.push(first);
            return acc;
        }, []);

        yield put(chatActions.get_messages_success({ messages: current_messages_objects }));

        const history = res.data.map((action: any) => {
            let h: any = {};
            action.history.forEach((ac: any, i: number) => {
                h[ac.received] = ac.content_object
            });
            return h;
        });

        yield put(chatActions.get_messages_success_history({ history }));
    } catch (error: any) {
        yield put(chatActions.get_messages_failure_history({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchGetConversationsHistorySuccess() {
    yield takeEvery(chatActions.get_conversations_history_success, getMessagesHistory);
};

function* getMessages(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(chatActions.get_messages({}));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(CHAT_API.getMessages, token, missionId, action.payload.conversations);
        yield put(chatActions.get_messages_success({ messages: res.data }));
    } catch (error: any) {
        yield put(chatActions.get_messages_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchGetConversationsSuccess() {
    yield takeLatest(chatActions.get_conversations_success, getMessages);
};

function* setActiveConversationLS(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const active = yield select((state: RootState) => state.chat.active);
    if (active) localStorage.setItem("activeConversationId", active);
};

function* watchSetActiveConversation() {
    yield takeLatest(chatActions.set_active_conversation, setActiveConversationLS);
};

function* createConversation(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(CHAT_API.postConversation, token, missionId, action.payload.name);
        yield put(chatActions.create_conversation_success({ ...res.data, setActive: true }));
    } catch (error: any) {
        yield put(chatActions.create_conversation_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchCreateConversation() {
    yield takeEvery(chatActions.create_conversation, createConversation);
};

function* createConversationSuccess(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    if (action.payload.setActive) {
        yield put(chatActions.set_active_conversation({ id: action.payload.id }));
    };
};

function* watchCreateConversationSuccess() {
    yield takeLatest(chatActions.create_conversation_success, createConversationSuccess);
};

function* archiveConversation(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(CHAT_API.deleteConversation, token, missionId, action.payload.conversationId);
        yield put(chatActions.archive_conversation_success({ id: action.payload.conversationId }));
    } catch (error: any) {
        yield put(chatActions.archive_conversation_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchArchiveConversation() {
    yield takeEvery(chatActions.archive_conversation, archiveConversation);
};

function* setActiveConversation(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    // if archivedConversation was active, set new active
    const active: ConversationType = yield select((state: RootState) => state.chat.active);
    if (action.payload.id === active) {
        const conversations: ConversationsType = yield select((state: RootState) => state.chat.conversations);
        yield put(chatActions.set_active_conversation({ id: conversations.list.length > 0 ? conversations.list[0].id : null }));
    };
};

function* watchArchiveConversationSuccess() {
    yield takeEvery(chatActions.archive_conversation_success, setActiveConversation);
};

function* createMessage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(CHAT_API.postMessage, token, missionId, action.payload.conversationId, action.payload.username, action.payload.text);
        yield put(chatActions.create_message_success({ message: res.data }));
    } catch (error: any) {
        yield put(chatActions.create_message_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchCreateMessage() {
    yield takeEvery(chatActions.create_message, createMessage);
};

function* notifyMessage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const level = yield select((state: RootState) => state.chat.notifications[action.payload.conversation] || 0);
    const conversation = yield select((state: RootState) => state.chat.conversations.list.find((c: ConversationType) => c.id === action.payload.conversation));
    const from = yield select((state: RootState) => {
        if (state.mission.active) {
            if (action.payload.creator_id === GROUND_PLID) return "GROUND";
            let v = state.mission.allowed[state.mission.active].fleet.find((v: VehicleType) => v.id === action.payload.creator_id)
            return v?.name;
        };
        return "";
    })
    if (!document.hasFocus()) {
        if (level >= 1) {
            new Notification(`New message on ${conversation.name}`, {
                icon: '/favicon.ico',
                body: `${action.payload.author}@${from}: ${action.payload.text}`,
                silent: true
            });
        };
        if (level >= 2) {
            const audio = new Audio("/sound/arpeggio.mp3");
            audio.play();
        };
    };
};

function* watchAddMessage() {
    yield takeEvery(chatActions.add_message, notifyMessage);
};

function* setLocalStorage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const { nickname, notifications } = yield select((state: RootState) => state.chat);
    localStorage.setItem('_chat', JSON.stringify({ nickname, notifications }));
};

function* watchActionsForLS() {
    yield takeEvery([
        chatActions.set_nickname,
        chatActions.increase_notification_level
    ], setLocalStorage);
};

function* setNullNicknameinLS(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const { notifications } = yield select((state: RootState) => state.chat);
    localStorage.setItem('_chat', JSON.stringify({ nickname: null, notifications }));
};

function* watchLogout() {
    yield takeLatest(authActions.logout, setNullNicknameinLS);
};

export default function* watchChat() {
    yield all([
        watchSetActiveMission(),
        watchReplayStart(),
        watchReplayUpdate(),
        watchGetConversationsSuccess(),
        watchGetConversationsHistorySuccess(),
        watchSetActiveConversation(),
        watchCreateConversation(),
        watchCreateConversationSuccess(),
        watchArchiveConversation(),
        watchArchiveConversationSuccess(),
        watchCreateMessage(),
        watchAddMessage(),
        watchActionsForLS(),
        watchLogout(),
    ]);
};