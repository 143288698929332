import { Fill, Style, Text, Icon } from "ol/style";
import { get_hud_styles } from '../../tracking/styles/vehicle';
import chroma from "chroma-js";
import { isBackgroundDark } from "../../../../config/map";

const planeIcon = (color: string) => {
    const planeColor = color ? color : "blue";
    const stroke = isBackgroundDark() ? "white" : "black";
    const planeSVG =
      '<svg version="1.1" id="VARG" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"' +
      ' width="305px" height="313px" viewBox="0 0 305 313" enable-background="new 0 0 305 313" xml:space="preserve">' +
      '<g fill="' +
      planeColor +
      '" stroke="' +
      stroke +
      '">' +
      '<path style="stroke-width:10" d="M134.875,19.74c0.04-22.771,34.363-22.771,34.34,0.642v95.563L303,196.354v35.306l-133.144-43.821v71.424l30.813,24.072' +
      'v27.923l-47.501-14.764l-47.501,14.764v-27.923l30.491-24.072v-71.424L3,231.66v-35.306l131.875-80.409V19.74z"/>' +
      "</g>" +
      "</svg>";
    return planeSVG;
  };

const applyFilter = function (properties: any, filters: any[]) {
    let cond: { [key: string]: any } = {
        "<": function (x: number, y: number) {
            return x < y;
        },
        "≤": function (x: number, y: number) {
            return x <= y;
        },
        "=": function (x: number, y: number) {
            return x === y;
        },
        "≠": function (x: number, y: number) {
            return x !== y;
        },
        "≥": function (x: number, y: number) {
            return x >= y;
        },
        ">": function (x: number, y: number) {
            return x > y;
        }
    };

    let return_color = "blue";
    let condition = false;

    filters.some(({ key, operator, value, color }: { key: any, operator: any, value: any, color: any }) => {
        condition = cond[operator](properties[key], value);
        return_color = condition ? color : return_color;
        return condition;
    });
    return return_color;
};

export const adsbStyleFunction = (feature: any, _resolution: any, filters: any[], hud: any) => {
    let styles = [];
    const labelColor = isBackgroundDark() ? "white" : "#4d4d4d";

    let color = applyFilter(feature.getProperties(), filters);

    let now: any = new Date();

    let time: any = new Date(feature.get("time") * 1000);
    if (isNaN(time.getTime())) time = new Date(feature.get("time"));
    let diff = now - time;
    let max = 2 * 60 * 1000;
    let opacity = diff < max ? chroma(color).alpha() * (max - diff) / max : 0;
    let display_name = feature.get("callsign");
    let heading = feature.get("true_track") || feature.get("track_angle") || 0;

    styles.push(
        new Style({
            image: new Icon({
                src:
                    "data:image/svg+xml;charset=utf-8," +
                    encodeURIComponent(
                        planeIcon(color)
                    ),
                scale: 0.08,
                rotateWithView: true,
                rotation: heading * (Math.PI / 180),
                opacity: opacity,
            }),
            text: new Text({
                text: diff < max ? display_name : "",
                fill: new Fill({
                    color: chroma(labelColor).brighten(1).alpha(opacity) as any
                }),
                font: "bold 10px sans-serif",
                textBaseline: "top",
                offsetY: 15,
            })
        })
    );


    const vert_rate = feature.get("vertical_rate");
    const vert_sense = feature.get("vert_sense");

    if (vert_rate < -1 || vert_sense === "Descending") {
        styles.push(
            new Style({
                image: new Icon({
                    src: '/down-arrow.png',
                    anchor: [1.6, 0.5],
                    opacity: opacity,
                    scale: 1,
                }),
            })
        );
    } else if (vert_rate > 1 || vert_sense === "Climbing") {
        styles.push(
            new Style({
                image: new Icon({
                    src: '/up-arrow.png',
                    anchor: [1.6, 0.5],
                    opacity: opacity,
                    scale: 1,
                }),
            })
        );
    } else if (typeof vert_rate !== "undefined" || vert_sense === "Level Flight") {
        styles.push(
            new Style({
                image: new Icon({
                    src: '/equal.png',
                    anchor: [2.2, 0.5],
                    opacity: opacity,
                    scale: 0.8,
                }),
            })
        );
    }

    if (hud.shown && opacity > 0) {
        let ts = time.getTime() / 1000;
        let hud_styles = get_hud_styles(feature, display_name, ts, hud);
        styles = [...styles, ...hud_styles];
    }

    return styles;
};