import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { GeomarkerType } from "./geomarkersSlice";

export const GEOMARKERS_API = {
    getGeomarkers: (token: string, missionId: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/geomarkers/${missionId}/`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getGeomarkersHistory: (token: string, missionId: string, from: string, to: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/geomarkers/${missionId}/?active_from=${from}&date_to=${to}&history`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    editGeomarker: (token: string, missionId: string, geomarker: GeomarkerType) => axios.put(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/geomarkers/${missionId}/${geomarker.id}/`,
        { feature: geomarker.feature },
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    createGeomarker: (token: string, missionId: string, feature: any) => axios.post(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/geomarkers/${missionId}/`,
        { feature },
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    deleteGeomarker: (token: string, missionId: string, gmId: string) => axios.delete(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/geomarkers/${missionId}/${gmId}/`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    uploadGeomarkers: (token: string, missionId:  string, file: any) => {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/geomarkers/${missionId}/upload/`,
        formData,
        { headers: { "Content-type": "multipart/form-data", Authorization: "Token " + token } }
    )},
    downloadGeomarkers: (token: string, missionId: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/geomarkers/${missionId}/download/`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
};