import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import { chatActions, ConversationType } from '../../redux/chat/chatSlice';
import { ALL_MESSAGES_CHANNEL_ID } from '../../config/chat';
import { RootState } from '../../app/store';

export default function BottomBar({ conversation, mobile }: { conversation: ConversationType, mobile: boolean }) {
    const dispatch = useDispatch();
    const [text, setText] = useState<string>("");
    const nickname = useSelector((state: RootState) => state.chat.nickname);
    const username = useSelector((state: RootState) => state.auth.username);
    const [inputNickname, setInputNickname] = useState(nickname || username);
    const isActive = useSelector((state: RootState) => state.replay.isActive);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'nickname-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        dispatch(chatActions.set_nickname({ nickname: inputNickname }));
        if (inputNickname === "") setInputNickname(username);
        setAnchorEl(null);
    };

    const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputNickname(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 13 || event.which === 13) handleClose();
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const sendMessage = () => {
        if (text && text !== "") dispatch(chatActions.create_message({ conversationId: conversation.id, username: nickname || username, text: text.trim() }));
        setText("");
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.which === 13 && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: 'fit-content',
            }}
        >
            <Input
                sx={{
                    fontSize: mobile ? '1rem' : '1.2rem',
                    padding: (theme) => mobile ? theme.spacing(1) : theme.spacing(2),
                    border: '1px solid grey',
                    '&.Mui-focused': {
                        borderColor: "white",
                    },
                    '& .MuiInputBase-input': {
                        maxHeight: '300px',
                        overflowY: 'visible'
                    }
                }}
                value={text}
                multiline={true}
                fullWidth={true}
                disableUnderline={true}
                placeholder={`from ${nickname || username} on #${conversation.name}...`}
                startAdornment={
                    <Stack sx={{ mr: 1, borderRight: '1px solid grey' }} direction="column" spacing={0}>
                        <IconButton size="small" disabled={false} onClick={handleClick}>
                            <PersonIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                }
                endAdornment={
                    <IconButton size="medium" onClick={sendMessage} disabled={text === ""}>
                        <SendIcon fontSize="inherit" />
                    </IconButton>
                }
                onChange={handleInputChange}
                onKeyPress={onKeyPress}
                disabled={isActive || (conversation.id === ALL_MESSAGES_CHANNEL_ID)}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <TextField
                    value={inputNickname}
                    onChange={handleNicknameChange}
                    onKeyPress={handleKeyPress}
                />
            </Popover>
        </Box>
    );
};