import * as React from "react";
import Box from "@mui/material/Box";
import { constants, IInstrumentProps } from './config';
import { FiBox, FiCircle, HorizonBack, HorizonBall, HorizonCircle, HorizonMechanics } from './images';

export interface IAttitudeProps extends IInstrumentProps {
  roll: number;
  pitch: number;
};

const Attitude: React.FunctionComponent<IAttitudeProps> = props => {
  let pitch = props.pitch;
  if (pitch > constants.pitch_bound) pitch = constants.pitch_bound;
  else if (pitch < -constants.pitch_bound) pitch = -constants.pitch_bound;

  pitch *= 0.7;

  return (
    <Box sx={{width: '100%', height: 'calc(100% - 10px)'}} id="attitude">
      <div className="instrument attitude" style={{ height: '100%', width: '100%' }}>
        <img src={FiBox} alt="attitude-fibox" className="background box" style={{ display: props.showBox ? "" : "none" }} />
        <div className="roll box" style={{ transform: `rotate(${props.roll}deg)` }}>
          <img src={HorizonBack} alt="attitude-horizon-back" className="box" />
          <div className="pitch box" style={{ top: `${pitch}%` }}>
            <img src={HorizonBall} alt="attitude-horizon-ball" className="box" />
          </div>
          <img src={HorizonCircle} alt="attitude-horizon-circle" className="box" />
        </div>
        <div className="mechanics box">
          <img src={HorizonMechanics} alt="attitude-horizon-mechanics" className="box" />
          <img src={FiCircle} alt="attitude-ficircle" className="box" />
        </div>
      </div>
    </Box>
  );
};

export default Attitude;