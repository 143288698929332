import axios from "axios";
import { API_CONFIG } from '../../config/api';
import { AUTH_LOGIN_URL, AUTH_LOGOUT_URL, AUTH_CHECK_URL } from "../../config/auth";

export const AUTH_API = {
    login: (args: any) => axios.post(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}${AUTH_LOGIN_URL}`,
        args,
        { headers: { "Content-type": "application/json" } }
    ),

    logout: (token: string) => axios.post(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}${AUTH_LOGOUT_URL}`,
        null,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),

    me: (token: any) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}${AUTH_CHECK_URL}`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),

};
