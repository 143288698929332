import { Fill, Icon, Style, Text } from "ol/style";
import { store } from "../../../../app/store";
import { hour } from "../../../../utils/TimeUtils";
import StringUtils from "../../../../utils/StringUtils";
import { VEHICLE_KINDS } from "../../../../config/const";
import { isBackgroundDark } from "../../../../config/map";

export const get_hud_styles = (feature: any, name: string, timestamp: number, hud: any) => {
    const styles = [];

    const featureKeys = feature.getKeys();
    const filteredHudValues: any[] = hud.values ? Object.entries(hud.values).filter(([internalName, displayName]) => featureKeys.includes(internalName)) : [];
    const backgroundWidth = Math.max(100, name.length * 10);
    const nameOffsetX = Math.max(75, name.length * 5 + 25);
    
    const background = new Style({
        text: new Text({
            text: ' ',
            offsetX: 35,
            offsetY: -25,
            backgroundFill: new Fill({ color: 'rgba(0,0,0,0.5)' }),
            padding: [5, backgroundWidth, 25 + 15.5 * (filteredHudValues.length || 0), 20]
        })
    });
    styles.push(background);

    const id = new Style({
        text: new Text({
            text: name,
            fill: new Fill({ color: "white" }),
            font: "bold 14px sans-serif",
            offsetX: nameOffsetX,
            offsetY: -22,
        })
    });
    styles.push(id);

    styles.push(new Style({
        text: new Text({
            text: 'H:',
            textAlign: "left",
            font: "14px sans-serif",
            offsetY: -3,
            offsetX: 20,
            fill: new Fill({ color: "white" }),
        })
    }));

    styles.push(new Style({
        text: new Text({
            text: StringUtils._9digits(hour(timestamp)),
            textAlign: "left",
            font: "bold 14px sans-serif",
            offsetY: -3,
            offsetX: 40,
            fill: new Fill({ color: "white" }),
        })
    }));

    for (let i = 0; i < filteredHudValues.length; i += 1) {
        let value = feature.get(filteredHudValues[i][0]);
        if (value !== undefined && value !== null) {

            // inspired from https://stackoverflow.com/questions/46190029/openlayers-ol-style-text-with-multiple-colors for canvas use

            value = String(Number(value).toFixed(2));
            const value_name = (filteredHudValues[i][1].length + value.length < 15 ? filteredHudValues[i][1] : filteredHudValues[i][1].substring(0, 10 - value.length) + "...") + ": ";
            const font = "14px sans-serif";
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (context) {
                context.font = font;
                const value_name_width = value_name ? context.measureText(value_name).width : 0;
                if (value_name) {
                    styles.push(
                        new Style({
                            text: new Text({
                                text: value_name,
                                textAlign: "left",
                                font,
                                offsetY: -3 + (i + 1) * 15,
                                offsetX: 20,
                                fill: new Fill({ color: "white" }),
                            })
                        })
                    );
                }

                styles.push(new Style({
                    text: new Text({
                        text: value,
                        textAlign: "left",
                        font: "bold " + font,
                        offsetY: -3 + (i + 1) * 15,
                        offsetX: 20 + value_name_width,
                        fill: new Fill({ color: "white" }),
                    })
                }));
            };
        }
    }

    return styles;
};

export const vehicleStyle = (feature: any, resolution: any, hud: any) => {
    const labelColor = isBackgroundDark() ? "white" : "#4d4d4d";
    const name = feature.get("name");
    const color = feature.get("color");
    const kind = feature.get("kind");
    const virtual = feature.get("virtual");
    const status = feature.get("status");
    const isActive = store.getState().replay.isActive;
    let stroke_color;
    if (isActive) stroke_color = "grey";
    else stroke_color = virtual ? labelColor : status === 'online' ? "#008000" : status === 'offline' ? "red" : "grey";

    let styles = [];

    const icon = new Style({
        image: new Icon({
            src:
                "data:image/svg+xml;charset=utf-8," +
                encodeURIComponent(
                    VEHICLE_KINDS[kind].icon.svg_string(color, stroke_color)
                ),
            scale: 0.1,
            rotateWithView: true,
            rotation: (feature.get("true_heading") || feature.get("track") || 0) * (Math.PI / 180)
        })
    });

    styles.push(icon);

    const label = new Style({
        text: new Text({
            text: name,
            fill: new Fill({ color: labelColor }),
            font: "bold 10px sans-serif",
            textBaseline: "top",
            offsetY: 18,
        })
    })

    styles.push(label);

    if (hud.shown) {
        let ts = feature.get('timestamp');
        let hud_styles = get_hud_styles(feature, name, ts, hud);
        styles = [...styles, ...hud_styles];
    }

    return styles;
};