import React from "react";
import { useElementSize } from 'usehooks-ts';
import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';
import { useGraphValueContext } from './GraphContext';
import { GraphItem } from './GraphItem';

interface GraphContainerType extends GridProps {
    onValueBoxClick?: (id: string) => void
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    height: '100%',
    overflow: 'auto'
}));

export const GraphContainer = ({ onValueBoxClick, ...rest }: GraphContainerType) => {
    const { graphs } = useGraphValueContext();
    const [elRef, { height }] = useElementSize();

    return (
        <StyledGrid ref={elRef} container {...rest}>
            {
                graphs.map(g =>
                    <GraphItem
                        key={g.id}
                        id={g.id}
                        maxHeight={height / graphs.length}
                        timespan={g.timespan}
                        size={g.size}
                        series={g.series}
                        options={g.options}
                        isLoading={g.isLoading}
                        onValueBoxClick={onValueBoxClick}
                    />
                )
            }
        </StyledGrid>
    );
};