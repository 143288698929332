export const MQTT_TELEMETRY_MSG_KEY = 'TELEMETRY_MSG';
export const MQTT_TELEMETRY_STOP_KEY = 'TELEMETRY_STOP';
export const MQTT_TELEMETRY_DASSAULT_KEY = 'TELEMETRY_DASSAULT';
export const MQTT_MISSIONS_CHANGE_KEY = 'MISSIONS_CHANGE';
export const MQTT_CHAT_MESSAGE_MSG_KEY = "CHAT_MESSAGE_MSG";
export const MQTT_CHAT_CONVERSATION_MSG_KEY = "CHAT_CONVERSATION_MSG";
export const MQTT_GEOMARKERS_MSG_KEY = "GEOMARKERS_MSG";
export const MQTT_TRACKING_CHANGE_KEY = 'TRACKING_CHANGE';
export const MQTT_TRANSER_MSG_KEY = "TRANSFER_MSG";
export const MQTT_WEATHER_MSG_KEY = "WEATHER_MSG";
export const MQTT_CUSTOM_MSG_KEY = "CUSTOM_MSG";
export const MQTT_ADSB_MSG_KEY = "ADSB_MSG";