import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { RootState } from "../../app/store";
import { toDate, strftime } from "../../utils/TimeUtils";
import { replayActions } from "../../redux/replay/replaySlice";
import { Typography } from "@mui/material";

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default function Replayer() {
  const dispatch = useDispatch();
  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const { isActive, speed, play, currentTime, startTime, endTime } = useSelector((state: RootState) => state.replay);
  const [value, setValue] = useState(toDate(currentTime).getTime() / 1000);

  const handleStopReplay = () => {
    dispatch(replayActions.stop({}));
  };

  const handlePlay = () => {
    dispatch(replayActions.play({}));
  };

  const handlePause = () => {
    dispatch(replayActions.pause({}));
  };

  const debouncedSlide = useMemo(
    () => debounce((value) => {
      dispatch(replayActions.set_currentTime({ time: value }));
    }, 10, {
      leading: false,
      trailing: true
    }),
    [dispatch],
  );

  const onSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (typeof newValue === "number") {
      setValue(newValue as number);
      debouncedSlide(newValue);
    }
  };

  const handleSpeedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(replayActions.set_speed({ speed: parseInt(event.target.value) }));
  };

  useEffect(() => {
    setValue(toDate(currentTime).getTime() / 1000);
  }, [currentTime]);

  if (!isActive) return null;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{ marginLeft: 2, marginRight: 2, padding: 1, backgroundColor: (theme) => theme.palette.primary.main, }}
    >
      {!mobile &&
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Typography>
            Replayer
          </Typography>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            {strftime('%c', toDate(currentTime))}
          </Typography>
        </Stack>
      }
      <Stack sx={{ width: '100%' }} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        {play ?
          <IconButton
            onClick={handlePause}
          >
            <PauseIcon />
          </IconButton>
          :
          <IconButton
            onClick={handlePlay}
          >
            <PlayArrowIcon />
          </IconButton>
        }
        <PrettoSlider
          value={value || 0}
          min={toDate(startTime).getTime() / 1000}
          max={toDate(endTime).getTime() / 1000}
          onChange={onSliderChange}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <TextField
          select
          value={speed}
          onChange={handleSpeedChange}
          variant="outlined"
          size="small"
          SelectProps={{
            native: true,
          }}
          sx={{
            minWidth: '80px'
          }}
        >
          <option value={1}>1 second</option>
          <option value={30}>30 seconds</option>
          <option value={60}>1 minute</option>
          <option value={300}>5 minutes</option>
        </TextField>
        <Button variant="outlined" color="error" onClick={handleStopReplay}>Stop</Button>
      </Stack>
    </Stack>
  );
};