import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RFeature, RInteraction } from "rlayers";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "../../map/layers/VectorLayer";
import { RootState } from "../../../../app/store";
import drawStyle from "../styles/draw";
import editStyle from "../styles/edit";
import { M_TO_NM } from "../../../../utils/GeomUtils";
import { store } from "../../../../app/store";
import { geomarkersActions } from "../../../../redux/geomarkers/geomarkersSlice";

export default function DrawLayer({ zIndex }: { zIndex: number }) {
    const dispatch = useDispatch();
    const proj = store.getState().map.projection;
    const draw = useSelector((state: RootState) => state.nav.geomarkers.draw);
    const enable_gm_measures = useSelector((state: RootState) => state.map.enable_gm_measures);

    const onDrawEnd = useCallback((e: any) => {
        let f = e.feature;

        if (f.getGeometry().getType() === "Circle") {
            const center = f.getGeometry().getCenter();
            const radius = Math.round(f.getGeometry().getRadius() * M_TO_NM);
            f = new Feature({ geometry: new Point(center) });
            f.setProperties({ radius: radius });
        };
        const feature = new GeoJSON().writeFeatureObject(f, { featureProjection: proj });
        feature.properties = feature.properties ? feature.properties : {};
        dispatch(geomarkersActions.end_drawing({ feature }));
    }, [dispatch, proj]);

    const eStyle = useCallback((f: any, res: any) => {
        return editStyle(f, res, enable_gm_measures);
    }, [enable_gm_measures]);

    const dStyle = useCallback((f: any, res: any) => {
        return drawStyle(f, res, enable_gm_measures);
    }, [enable_gm_measures]);

    return (
        <VectorLayer
            id="geomarker-draw-layer"
            zIndex={zIndex}
            style={null}
        >
            {
                draw.type &&
                <RInteraction.RDraw
                    key={draw.type}
                    type={draw.type}
                    style={dStyle}
                    stopClick={true} // prevent zooming in on double click to end draw
                    onDrawEnd={onDrawEnd}
                />
            }
            {
                draw.feature &&
                <RFeature
                    style={eStyle}
                    geometry={new GeoJSON({ featureProjection: proj }).readGeometry(draw.feature.geometry)}
                    properties={draw.feature.properties}
                />
            }
        </VectorLayer>
    );
};