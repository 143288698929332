//https://stackoverflow.com/questions/39666643/multiple-redux-sagas
import { all } from 'redux-saga/effects';
import watchAuth from '../redux/auth/authSagas';
import watchMission from '../redux/mission/missionSagas';
import watchMap from '../redux/map/mapSaga';
import watchTracking from '../redux/tracking/trackingSagas';
import watchTelemetry from '../redux/telemetry/telemetrySagas';
import watchChat from '../redux/chat/chatSagas';
import watchGeomarker from '../redux/geomarkers/geomarkersSagas';
import watchAero from '../redux/aero/aeroSagas';
import watchWidget from '../redux/widget/widgetSagas';
import watchTransfer from '../redux/transfer/transferSagas';
import watchWeather from '../redux/weather/weatherSagas';
import watchCustom from '../redux/custom/customSagas';

function* watchAll() {
  yield all([
    watchAuth(),
    watchMission(),
    watchMap(),
    watchTracking(),
    watchTelemetry(),
    watchChat(),
    watchGeomarker(),
    watchWidget(),
    watchTransfer(),
    watchAero(),
    watchWeather(),
    watchCustom(),
  ]);
};

export default watchAll;