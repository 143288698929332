import { createSlice } from '@reduxjs/toolkit';
import { missionActions } from '../mission/missionSlice';
import { RootState } from '../../app/store';
import { API_CONFIG } from '../../config/api';

export interface CustomProductType {
    id: string;
    data_type: string;
    file_name: string;
    url: string;
    source_projection: string;
    visible: boolean;
    zIndex: number;
    opacity: number;
    min_long: number;
    min_lat: number;
    max_long: number;
    max_lat: number;
    extent?: number[];
};

export interface CustomState {
    loading: boolean;
    loaded: boolean;
    error: string | null;
    files: {
        [key: string]: CustomProductType;
    };
};

const initialState: CustomState = {
    loading: false,
    loaded: false,
    error: null,
    files: {}
};

const customSlice = createSlice({
    name: 'custom',
    initialState,
    reducers: {
        get_customs: ((state, action) => {
            state.loading = true;
            state.loaded = false;
            state.error = null;
            state.files = {};
        }),
        get_customs_success: ((state, action) => {
            state.loading = false;
            state.loaded = true;
            state.files = action.payload.list;
        }),
        get_customs_failure: ((state, action) => {
            state.loading = false;
            state.loaded = true;
            state.error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        add: ((state, action) => {

        }),
        add_success: ((state, action) => {

        }),
        add_failure: ((state, action) => {

        }),
        append: ((state, action) => {
            state.files[action.payload.custom.id] = {
                ...action.payload.custom,
                url: `${API_CONFIG.HOSTNAME}/custom/hash/${action.payload.custom.hash}`,
                opacity: state.files[action.payload.custom.id]?.opacity || 100,
                visible: state.files[action.payload.custom.id]?.visible || true,
                zIndex: state.files[action.payload.custom.id]?.zIndex || Object.keys(state.files).length + 1
            };
        }),
        delete: ((state, action) => {

        }),
        delete_success: ((state, action) => {

        }),
        delete_failure: ((state, action) => {

        }),
        remove: ((state, action) => {
            if (action.payload.id in state.files) {
                const removedIndex = state.files[action.payload.id].zIndex;
                delete state.files[action.payload.id];
                Object.keys(state.files).forEach(customKey => // decreasing zIndex for layers above the deleted one
                    state.files[customKey].zIndex = state.files[customKey].zIndex > removedIndex ?
                        state.files[customKey].zIndex - 1
                        :
                        state.files[customKey].zIndex
                );
            };
        }),
        set_visibility: ((state, action) => {
            state.files[action.payload.id].visible = action.payload.visible;
        }),
        set_opacity: ((state, action) => {
            state.files[action.payload.id].opacity = action.payload.opacity;
        }),
        move_up: ((state, action) => {
            const currentIdx = state.files[action.payload.id].zIndex;
            if (currentIdx < Object.keys(state.files).length) { // if product_id is not already on top of the list
                const moveDownProductId = Object.keys(state.files).filter((key) => state.files[key].zIndex === currentIdx + 1)[0];
                state.files[action.payload.id].zIndex = currentIdx + 1;
                state.files[moveDownProductId].zIndex = currentIdx;
            }
        }),
        move_down: ((state, action) => {
            const currentIdx = state.files[action.payload.id].zIndex;
            if (currentIdx > 1) { // if product_id is not already on bottom of the list
                const moveUpProductId = Object.keys(state.files).filter((key) => state.files[key].zIndex === currentIdx - 1)[0];
                state.files[action.payload.id].zIndex = currentIdx - 1;
                state.files[moveUpProductId].zIndex = currentIdx;
            }
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            for (let key in state) delete state.files[key];
            Object.assign(state, initialState);
        });
    }
});

export const selectCustom = (state: RootState) => state.nav.custom;

export const customActions = customSlice.actions;

export default customSlice.reducer;