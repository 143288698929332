import { Fill, Style, Text, Icon } from "ol/style";
import { isBackgroundDark } from "../../../../config/map";

export const navaidsStyle = (feature: any, _resolution: any) => {

    const color = isBackgroundDark() ? "white" : "#4d4d4d";

    let icon;
    switch (feature.get("type")) {
        case "VOR":
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="95" height="83">
          <path fill="none" stroke="${color}" stroke-width="6" d="m0 40L24 1h47l23 40-23 40H24z"/>
          <circle fill="${color}" cx="47" cy="41" r="7"/>
          </svg>`
            break;
        case "DME":
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="95" height="83">
          <path d="M1 1h93v80H1z" fill="none" stroke="${color}" stroke-width="6"/>
          <circle fill="${color}" cx="47" cy="41" r="7"/>
          </svg>`
            break;
        case "VORDME":
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="95" height="83">
          <path fill="none" stroke="${color}" stroke-width="6" d="M1 1h93v80H1zm0 40L24 1h47l23 40-23 40H24z"/>
          <circle fill="${color}" cx="47" cy="41" r="7"/>
          </svg>`
            break;
        case "NDB":
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 50 50" version="1.0">
          <g stroke="${color}" stroke-width="2" stroke-linecap="square">
          <circle cx="25" cy="25" r="5" fill="none"/>
          <circle cx="25" cy="25" r=".625"/>
          <circle r=".625" cy="15" cx="25" fill="none"/>
          <circle r=".625" cy="35" cx="25" fill="none"/>
          <circle r=".625" cy="24.151" cx="9.151" transform="rotate(-30)" fill="none"/>
          <circle r=".625" cy="44.151" cx="9.151" transform="rotate(-30)" fill="none"/>
          <circle r=".625" cy="24.151" cx="-9.151" transform="rotate(-60)" fill="none"/>
          <circle r=".625" cy="44.151" cx="-9.151" transform="rotate(-60)" fill="none"/>
          <circle r=".625" cy="15" cx="-25" transform="rotate(-90)" fill="none"/>
          <circle r=".625" cy="35" cx="-25" transform="rotate(-90)" fill="none"/>
          <circle r=".625" cy="-.849" cx="-34.151" transform="rotate(-120)" fill="none"/>
          <circle r=".625" cy="19.151" cx="-34.151" transform="rotate(-120)" fill="none"/>
          <circle r=".625" cy="-19.151" cx="-34.151" transform="rotate(-150)" fill="none"/>
          <circle r=".625" cy=".849" cx="-34.151" transform="rotate(-150)" fill="none"/>
          <circle r=".625" cy="18.223" cx="12.092" transform="rotate(-25)" fill="none"/>
          <circle r=".625" cy="48.223" cx="12.092" transform="rotate(-25)" fill="none"/>
          <circle r=".625" cy="20.355" transform="rotate(-45)" fill="none"/>
          <circle r=".625" cy="50.355" transform="rotate(-45)" fill="none"/>
          <circle r=".625" cy="18.223" cx="-12.092" transform="rotate(-65)" fill="none"/>
          <circle r=".625" cy="48.223" cx="-12.092" transform="rotate(-65)" fill="none"/>
          <circle r=".625" cy="12.084" cx="-22.726" transform="rotate(-85)" fill="none"/>
          <circle r=".625" cy="42.084" cx="-22.726" transform="rotate(-85)" fill="none"/>
          <circle r=".625" cy="2.678" cx="-30.619" transform="rotate(-105)" fill="none"/>
          <circle r=".625" cy="32.678" cx="-30.619" transform="rotate(-105)" fill="none"/>
          <circle r=".625" cy="-8.861" cx="-34.818" transform="rotate(-125)" fill="none"/>
          <circle r=".625" cy="21.139" cx="-34.818" transform="rotate(-125)" fill="none"/>
          <circle r=".625" cy="-21.139" cx="-34.818" transform="rotate(-145)" fill="none"/>
          <circle r=".625" cy="8.861" cx="-34.818" transform="rotate(-145)" fill="none"/>
          <circle r=".625" cy="-32.678" cx="-30.619" transform="rotate(-165)" fill="none"/>
          <circle r=".625" cy="-2.678" cx="-30.619" transform="rotate(-165)" fill="none"/>
          <circle r=".625" cy="-42.084" cx="-22.726" transform="rotate(175)" fill="none"/>
          <circle r=".625" cy="-12.084" cx="-22.726" transform="rotate(175)" fill="none"/>
          <circle r=".625" cy="45" cx="25" fill="none"/>
          <circle r=".625" cy="5" cx="25" fill="none"/>
          <circle r=".625" cy="50.619" cx="17.678" transform="rotate(-15)" fill="none"/>
          <circle r=".625" cy="10.619" cx="17.678" transform="rotate(-15)" fill="none"/>
          <circle r=".625" cy="54.151" cx="9.151" transform="rotate(-30)" fill="none"/>
          <circle r=".625" cy="14.151" cx="9.151" transform="rotate(-30)" fill="none"/>
          <circle r=".625" cy="55.355" transform="rotate(-45)" fill="none"/>
          <circle r=".625" cy="15.355" transform="rotate(-45)" fill="none"/>
          <circle r=".625" cy="54.151" cx="-9.151" transform="rotate(-60)" fill="none"/>
          <circle r=".625" cy="14.151" cx="-9.151" transform="rotate(-60)" fill="none"/>
          <circle r=".625" cy="50.619" cx="-17.678" transform="rotate(-75)" fill="none"/>
          <circle r=".625" cy="10.619" cx="-17.678" transform="rotate(-75)" fill="none"/>
          <circle r=".625" cy="45" cx="-25" transform="rotate(-90)" fill="none"/>
          <circle r=".625" cy="5" cx="-25" transform="rotate(-90)" fill="none"/>
          <circle r=".625" cy="37.678" cx="-30.619" transform="rotate(-105)" fill="none"/>
          <circle r=".625" cy="-2.322" cx="-30.619" transform="rotate(-105)" fill="none"/>
          <circle r=".625" cy="29.151" cx="-34.151" transform="rotate(-120)" fill="none"/>
          <circle r=".625" cy="-10.849" cx="-34.151" transform="rotate(-120)" fill="none"/>
          <circle r=".625" cy="20" cx="-35.355" transform="rotate(-135)" fill="none"/>
          <circle r=".625" cy="-20" cx="-35.355" transform="rotate(-135)" fill="none"/>
          <circle r=".625" cy="10.849" cx="-34.151" transform="rotate(-150)" fill="none"/>
          <circle r=".625" cy="-29.151" cx="-34.151" transform="rotate(-150)" fill="none"/>
          <circle r=".625" cy="2.322" cx="-30.619" transform="rotate(-165)" fill="none"/>
          <circle r=".625" cy="-37.678" cx="-30.619" transform="rotate(-165)" fill="none"/>
          </g>
          </svg>`
            break;
        case "WAYPOINT":
        default:
            icon = `<svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 24 24">
        <path fill="${color}" d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z" />
        </svg>`
            break;
    }

    let styles = [
        new Style({
            image: new Icon({
                opacity: 1,
                src: "data:image/svg+xml;charset=utf-8," + encodeURIComponent(icon),
                scale: 0.2
            }),
            text: new Text({
                text: feature.get("icao"),
                font: "bold 10px sans-serif",
                textBaseline: "top",
                offsetY: 12,
                fill: new Fill({ color: color })
            })
        })
    ];

    return styles;
};