import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#36406B"
        },
        secondary: {
            main: "#2B5DFF"
        },
        background: {
            paper: "#293051",
            default: "#222944"
        }
    },
    typography: {
        fontFamily: "Roboto",
        fontSize: 14,
        button: {
            fontWeight: 500,
            fontSize: 14,
            textTransform: "none"
        }
    },
    mixins: {
        toolbar: {
            minHeight: '64px'
        }
    },
});