import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from 'material-ui-confirm';
import { useElementSize } from 'usehooks-ts';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getGmIcon } from "./toasts/Info";
import { GeomarkerGroupType, GeomarkerType, geomarkersActions } from '../../../redux/geomarkers/geomarkersSlice';
import { GEOMARKER_DEFAULT_GROUP } from '../../../config/geomarker';
import { RootState } from '../../../app/store';
import { Accordion, AccordionSummary, AccordionDetails } from '../../utils/accordion/Accordion';
import UploadFile from './UploadFile';

const Geomarker = React.memo(function Geomarker({ gm }: { gm: GeomarkerType }) {
    const dispatch = useDispatch();
    const confirm = useConfirm();

    const onEdit = useCallback(() => {
        dispatch(geomarkersActions.start_edit({ id: gm.id }));
    }, [gm.id, dispatch]);

    const onDelete = useCallback(() => {
        confirm({
            confirmationButtonProps: { color: "inherit" },
            cancellationButtonProps: { color: "inherit" },
            description: `Please confirm deletion of geomarker ${gm.id}.`
        })
            .then(() => {
                dispatch(geomarkersActions.delete({ id: gm.id }))
            })
            .catch(() => { });
    }, [gm.id, dispatch, confirm]);

    return (
        <Stack key={gm.id} direction="row" sx={{ width: '100%' }} spacing={1} alignItems="center" justifyContent="space-between">
            <div>{getGmIcon(gm.feature.geometry.type, gm.feature.properties?.radius, { color: gm.feature.properties?.color })}</div>
            <div style={{ fontSize: '0.9rem',maxWidth: '250px', overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{gm.feature.properties?.label || 'no-label'}</div>
            <Stack
                direction="row"
                spacing={0}
                alignItems="center"
                justifyContent="flex-end"
            >
                <IconButton size="small" onClick={onEdit}>
                    <EditIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={onDelete}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Stack>
        </Stack>
    );
});

const Group = React.memo(function Group({ id, group, expanded, handleChange }: { id: string, group: GeomarkerGroupType, expanded: string | boolean, handleChange: (groupId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void }) {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const [elRef, { width }] = useElementSize();
    const geomarkers = useSelector((state: RootState) => state.nav.geomarkers.geomarkers.filter((gm: GeomarkerType) => {
        if (gm.feature.properties?.group) return (gm.feature.properties?.group === id);
        else return (id === GEOMARKER_DEFAULT_GROUP);
    }));

    const handleDelete = useCallback((e: any) => {
        e.stopPropagation();
        confirm({
            confirmationButtonProps: { color: "inherit" },
            cancellationButtonProps: { color: "inherit" },
            description: `Please confirm deletion of ${id} group.`
        })
            .then(() => {
                dispatch(geomarkersActions.delete_group({ groupId: id }));
            })
            .catch(() => { });
    }, [dispatch, confirm, id]);

    const toggleLayer = useCallback((e: any) => {
        dispatch(geomarkersActions.set_group_visibility({ groupId: id, visible: !group.visible }));
    }, [dispatch, id, group]);

    const onClick = (e: any) => {
        e.stopPropagation();
    };

    return (
        <Box ref={elRef} sx={{ width: '100%' }}>
            <Accordion expanded={expanded === id} onChange={handleChange(id)} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ width: '100%' }}
                        >
                            <Typography variant="h6" sx={{ fontSize: '0.9rem', maxWidth: `${width / 1.7}px`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{id}</Typography>
                            <Typography variant="h6" sx={{ fontSize: '0.9rem', maxWidth: 50, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>({geomarkers.length})</Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <IconButton
                                color="inherit"
                                size="small"
                                onClick={handleDelete}
                            >
                                <DeleteForeverIcon fontSize="small"/>
                            </IconButton>
                            <Switch
                                size="small"
                                checked={group.visible}
                                onClick={onClick}
                                onChange={toggleLayer}
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 0 }}>
                    <List
                        dense
                        subheader={
                            <ListSubheader sx={{ lineHeight: '20px' }}>
                                <Typography variant="overline" style={{ fontSize: "0.75rem" }}>
                                    Geomakers List
                                </Typography>
                            </ListSubheader>
                        }
                    >
                        <>
                            <Divider variant="middle" />
                            {
                                geomarkers.map((gm: GeomarkerType) => (
                                    <ListItem key={gm.id}>
                                        <Geomarker gm={gm} />
                                    </ListItem>
                                ))
                            }
                        </>
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
});

export function GeomarkerControlActions(props: any) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const isActive = useSelector((state: RootState) => state.replay.isActive);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleExport = () => {
        dispatch(geomarkersActions.download_geomarkers({}));
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpen} startIcon={<FileUploadIcon />} disabled={isActive}>
                Upload
            </Button>
            <Button variant="contained" onClick={handleExport} startIcon={<FileDownloadIcon />}>
                Download
            </Button>
            <UploadFile open={open} handleClose={handleClose} />
        </>
    );
};

export function GeomarkerControl(props: any) {
    const groups = useSelector((state: RootState) => state.nav.geomarkers.groups);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (groupId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? groupId : false);
        };

    return (
        <Stack direction="column" spacing={0}>
            {Object.entries(groups).map(([groupId, group]) => <Group key={groupId} id={groupId} group={group} expanded={expanded} handleChange={handleChange} />)}
        </Stack>
    );
}
