import { useEffect, useState } from "react";
import packageJson from "../package.json";

global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export default function CacheBuster(props: any) {
  const [state, setState] = useState({
    loading: true,
    isLatestVersion: false
  });

  const refreshCacheAndReload = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload();
  };

  useEffect(() => {
    async function getMeta() {
      try {
        const res = await fetch("/meta.json");
        const meta = await res.json();
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );
          setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          setState({ loading: false, isLatestVersion: true });
        }
      } catch {
        console.error(`meta.json file not found`);
      }
    }

    getMeta();
  }, []);

  return props.children({ ...state, refreshCacheAndReload });
};
