import React from 'react';
import Box from '@mui/material/Box';
import chroma from "chroma-js";

export interface ValueBoxParam {
    label: string,
    color: string,
    value: number,
    unit: string,
    toFixed: number,
    shown: boolean
};

export const ValueBoxes = React.memo(function ValueBoxes({ params, onClick }: { params: ValueBoxParam[], onClick?: () => void }) {

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row'
            }}
            onClick={() => { onClick?.() }}
        >
            {params.map((p: ValueBoxParam, i: number) => {
                if (!p.label) return null;
                return (
                    <Box
                        key={i}
                        sx={{
                            marginRight: '5px',
                            width: 'fit-content',
                            padding: '5px 5px 0px 5px',
                            backgroundColor: p.color,
                            opacity: p.shown ? 1 : 0.3,
                            color: 'black',
                            "&:hover": {
                                backgroundColor: chroma(p.color).darken().css()
                            }
                        }}
                    >
                        <div>{p.label}</div>
                        <div>{`${p.value?.toFixed?.(p.toFixed) || '-'} ${p.unit}`}</div>
                    </Box>
                )
            })}
        </Box>
    );
});