import React, { useState, useCallback, SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "usehooks-ts";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import IconButton from "@mui/material/IconButton";
import Slider, { SliderValueLabelProps } from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Popper from '@mui/material/Popper';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CancelIcon from '@mui/icons-material/Cancel';
import { hourOrNbDays } from '../../../utils/TimeUtils';
import { Accordion, AccordionSummary, AccordionDetails } from '../../utils/accordion/Accordion';
import { RootState } from "../../../app/store";
import { weatherActions, WeatherProductType } from "../../../redux/weather/weatherSlice";
import CircularProgressWithLabel from "../../utils/CircularProgressWithLabel";
import { WEATHER_DATA_HISTORY_LENGTH } from '../../../config/weather';

function ValueLabelComponent(props: SliderValueLabelProps) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={`opacity: ${value}`}>
            {children}
        </Tooltip>
    );
};

function Replayer({ productId, product }: { productId: string, product: WeatherProductType }) {
    const dispatch = useDispatch();
    const [playing, setPlaying] = useState(false);

    const handlePrevious = useCallback(() => {
        dispatch(weatherActions.previous({ id: productId }));
    }, [dispatch, productId]);

    const handleNext = useCallback(() => {
        dispatch(weatherActions.next({ id: productId }));
    }, [dispatch, productId]);

    const handlePlayStop = () => {
        setPlaying(playing => !playing);
    };

    useUpdateEffect(() => {
        let play: any;
        if (playing) {
            play = setInterval(() => {
                dispatch(weatherActions.unwind({ id: productId }));
            }, 750)
        }
        return () => {
            clearInterval(play);
            dispatch(weatherActions.reset_index({ id: productId }));
        }
    }, [dispatch, playing, productId]);

    return (
        <Box
            sx={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(255,255,255,0.25)',
                borderRadius: 5
            }}
        >
            <IconButton
                aria-label="previous"
                onClick={handlePrevious}
                disabled={playing || product.dataIndex === WEATHER_DATA_HISTORY_LENGTH || product.data.filter(d => d !== null).length < 2}
                size="small"
            >
                <SkipPreviousIcon fontSize="small" />
            </IconButton>
            <IconButton
                aria-label="play/pause"
                onClick={handlePlayStop}
                disabled={product.data.filter(d => d !== null).length < 2}
                size="small"
            >
                {playing ? <StopIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
            </IconButton>
            <IconButton
                aria-label="next"
                onClick={handleNext}
                disabled={playing || product.dataIndex === 0}
                size="small"
            >
                <SkipNextIcon fontSize="small" />
            </IconButton>
        </Box>
    );
};

function Legend({ productId, info, onClose }: { productId: string, info: any | null, onClose: () => void }) {

    useUpdateEffect(() => {
        return () => {
            onClose();
        }
    }, [onClose]);

    return (
        <Card style={{ width: "300px" }}>
            <CardHeader
                title={productId}
                subheader={info ? `Source: ${info.source}` : 'No info available'}
                avatar={<Avatar>i</Avatar>}
                action={
                    <IconButton size="small" onClick={onClose}>
                        <CancelIcon fontSize="inherit" />
                    </IconButton>
                }
            />
            {info &&
                <>
                    <Divider />
                    <CardContent >
                        <Stack
                            direction="column"
                            spacing={0}
                        >
                            {info.refresh_rate &&
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div>Refresh rate</div>
                                    <div>{info.refresh_rate}</div>
                                </Stack>
                            }
                            {info.palette &&
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div>Palette Unit</div>
                                    <div>{info.palette?.unit}</div>
                                </Stack>
                            }
                            {info.palette?.img &&
                                <Box sx={{ overflow: 'auto', maxHeight: "250px" }}>
                                    <img src={info.palette?.img} width={info.palette?.width} height={info.palette?.height} alt="palette" style={{ display: 'block', margin: '0 auto' }} />
                                </Box>
                            }
                        </Stack>
                    </CardContent>
                </>
            }
        </Card>
    );
};

const Product = React.memo(function Product({ id, product, expanded, handleChange }: { id: string, product: WeatherProductType, expanded: string | boolean, handleChange: (groupId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void }) {
    const dispatch = useDispatch();
    const [opacity, setOpacity] = useState(product.opacity * 100);
    const [maxWidth, setMaxWidth] = useState<string | number>("100%");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const toggleLayer = useCallback((e: any) => {
        dispatch(weatherActions.set_visibility({ id, visible: !product.visible }));
    }, [dispatch, id, product]);

    const onClick = (e: any) => {
        e.stopPropagation();
    };

    const moveUp = useCallback((e: any) => {
        e.stopPropagation();
        dispatch(weatherActions.move_up({ id }));
    }, [dispatch, id]);

    const moveDown = useCallback((e: any) => {
        e.stopPropagation();
        dispatch(weatherActions.move_down({ id }));
    }, [dispatch, id]);

    const refresh = useCallback((e: any) => {
        e.stopPropagation();
        dispatch(weatherActions.refresh({ id }));
    }, [dispatch, id]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
        if (typeof value === 'number') setOpacity(value);
    };

    const onSliderChangeCommitted = useCallback((event: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
        if (typeof value === 'number') dispatch(weatherActions.set_opacity({ id, opacity: value / 100 }));
    }, [dispatch, id]);

    const onSliderClick = (e: any) => {
        e.stopPropagation();
    };

    const widthCallback = useCallback((ref: HTMLSpanElement) => setMaxWidth(product.error && ref ? ref.clientWidth + 200 : "100%"), [product.error]);

    return (
        <Accordion
            expanded={expanded === id}
            onChange={handleChange(id)}
            TransitionProps={{ unmountOnExit: true }}
            sx={{
                width: maxWidth,
                minWidth: "100%"
            }}
        >
            <AccordionSummary>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Stack
                        direction="column"
                        spacing={0}
                    >
                        <Typography variant="h6" sx={{ fontSize: '1rem' }}>{id}</Typography>
                        <Typography variant="h6" sx={{ fontSize: '0.8rem' }} ref={widthCallback}>
                            {product.error && <Box sx={{ color: (theme) => theme.palette.error.light, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{product.error}</Box>}
                            {product.data?.[product.dataIndex]?.dates &&
                                <Box sx={{ color: (theme) => product.dataIndex === 0 ? theme.palette.success.light : theme.palette.error.light }}>
                                    {`Issued: ${hourOrNbDays(product.data[product.dataIndex].dates.created[0])}`}
                                </Box>
                            }
                            {!product.error && !product.data.some(d => d !== null) && <Box sx={{ color: (theme) => theme.palette.info.light }}>No data</Box>}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={0}
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {
                            product.loading && product.progress < 100 ?
                                <CircularProgressWithLabel
                                    color="secondary"
                                    value={product.progress ? product.progress : 0}
                                    size="small"
                                />
                                :
                                <IconButton disabled={!product.visible} onClick={refresh} size="small">
                                    <RefreshIcon fontSize="small" />
                                </IconButton>
                        }
                        <IconButton onClick={moveUp} size="small">
                            <ArrowUpwardIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={moveDown} size="small">
                            <ArrowDownwardIcon fontSize="small" />
                        </IconButton>
                        <Switch
                            size="small"
                            checked={product.visible}
                            onClick={onClick}
                            onChange={toggleLayer}
                            color="secondary"
                        />
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 0 }}>
                <Stack
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    justifyContent="space-evenly"
                >
                    <IconButton onClick={handleClick} size="small">
                        <InfoIcon fontSize="small" />
                    </IconButton>
                    <Slider
                        sx={{ width: 100, margin: 2 }}
                        size="small"
                        valueLabelDisplay="auto"
                        slots={{
                            valueLabel: ValueLabelComponent,
                        }}
                        color='secondary'
                        value={opacity}
                        onClick={onSliderClick}
                        onChange={onSliderChange}
                        onChangeCommitted={onSliderChangeCommitted}
                    />
                    <Replayer productId={id} product={product} />
                </Stack>
            </AccordionDetails>
            <Popper open={open} anchorEl={anchorEl} placement='bottom-start' style={{ zIndex: 1300 }}>
                <Legend productId={id} info={product?.info} onClose={() => setAnchorEl(null)} />
            </Popper>
        </Accordion>
    );
});

export function WeatherControl(props: any) {

    const weather = useSelector((state: RootState) => state.nav.weather);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (productId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? productId : false);
        };

    return (
        <Stack direction="column" spacing={1}>
            {Object.entries(weather)
                .sort((a: [string, WeatherProductType], b: [string, WeatherProductType]) => {
                    if (a[1].zIndex > b[1].zIndex) return -1;
                    if (a[1].zIndex < b[1].zIndex) return 1;
                    return 0;
                })
                .map(([productId, product]) =>
                    <Product
                        key={productId}
                        id={productId}
                        product={product}
                        expanded={expanded}
                        handleChange={handleChange}
                    />
                )}
        </Stack>
    );
};