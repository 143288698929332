import React from "react";
import Badge from '@mui/material/Badge';
import ImportExportIcon from '@mui/icons-material/ImportExport';

export default function TransferIcon() {
    return (
        <Badge
            color="error"
            variant="dot"
            invisible={true}
        >
            <ImportExportIcon />
        </Badge>
    );
};