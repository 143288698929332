import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { DEFAULT_WIDGET_CONFIG } from '../../config/widget';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';

export interface GridLayoutType {
    x: number;
    y: number;
    w: number;
    h: number;
    minW: number;
    minH: number;
    maxH?: number;
    maxW?: number;
};

export interface WidgetType {
    id: string;
    kind: string;
    params: any;
    reduced: boolean;
    gridLayout: {
        expanded: GridLayoutType;
        reduced: GridLayoutType | null;
    };
};

export interface WidgetState {
    [key: string]: WidgetType[]
};

const initialState: WidgetState = {};

const widgetSlice = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        restore_grid: ((state, action) => {
            state[action.payload.gridId] = action.payload.widgets
        }),
        add_widget: ((state, action) => {
            if (!(action.payload.gridId in state)) state[action.payload.gridId] = [];
            const index = state[action.payload.gridId].findIndex((w: WidgetType) => w.id === action.payload.id);
            if (index === -1) {
                state[action.payload.gridId].push({
                    id: action.payload.id,
                    kind: action.payload.kind,
                    params: action.payload.params,
                    reduced: false,
                    gridLayout: {
                        expanded: action.payload.medium ? DEFAULT_WIDGET_CONFIG[action.payload.kind].gridLayout_md : DEFAULT_WIDGET_CONFIG[action.payload.kind].gridLayout,
                        reduced: null
                    },
                });
            };
        }),
        remove_widget: ((state, action) => {
            if (!(action.payload.gridId in state)) return;
            const index = state[action.payload.gridId].findIndex((w: WidgetType) => w.id === action.payload.id);
            if (index !== -1) {
                state[action.payload.gridId] = [...state[action.payload.gridId].slice(0, index), ...state[action.payload.gridId].slice(index + 1)];
            }
            if (state[action.payload.gridId].length === 0) delete state[action.payload.gridId];
        }),
        reduce_widget: ((state, action) => {
            const index = state[action.payload.gridId].findIndex((w: WidgetType) => w.id === action.payload.id);
            if (index !== -1) {
                state[action.payload.gridId][index].reduced = true;
                state[action.payload.gridId][index].gridLayout.reduced = {
                    ...state[action.payload.gridId][index].gridLayout.expanded,
                    h: 2,
                    minW: 2,
                    minH: 2,
                }
            };
        }),
        expand_widget: ((state, action) => {
            const index = state[action.payload.gridId].findIndex((w: WidgetType) => w.id === action.payload.id);
            if (index !== -1) {
                state[action.payload.gridId][index].reduced = false;
                state[action.payload.gridId][index].gridLayout.reduced = null;
            };
        }),
        update_widget_layout: ((state, action) => {
            const index = state[action.payload.gridId].findIndex((w: WidgetType) => w.id === action.payload.id);
            if (index !== -1) {
                if (state[action.payload.gridId][index].reduced) {
                    state[action.payload.gridId][index].gridLayout.reduced = action.payload.layout;
                    state[action.payload.gridId][index].gridLayout.expanded = {
                        ...state[action.payload.gridId][index].gridLayout.expanded,
                        x: action.payload.layout.x,
                        y: action.payload.layout.y,
                    }
                }
                else
                    state[action.payload.gridId][index].gridLayout.expanded = action.payload.layout;
            };
        }),
        set_widget_params: ((state, action) => {
            const index = state[action.payload.gridId].findIndex((w: WidgetType) => w.id === action.payload.id);
            if (index !== -1) {
                state[action.payload.gridId][index].params = action.payload.params;
            };
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.stop, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
    }
});

export const selectWidget = (state: RootState) => state.widget;

export const widgetActions = widgetSlice.actions;

export default widgetSlice.reducer;
