export const DECIMAL_REGEX = /^-?[0-9]*([0-9]\.[0-9]*)?$/;
export const DECIMAL_REGEX_COMPLETED = /^-?[0-9]+(\.[0-9]+)?$/;
export const CARDINAL_REGEX = /^(N|S|E|W)([0-9]{0,2}(°|°[0-9]{0,2}((\.[0-9]*)?'?|'[0-9]{0,2}(\.[0-9]*)?"?)?)?)?$/;
export const CARDINAL_REGEX_COMPLETED = /^(N|S|E|W)[0-9]{1,2}°([0-9]{1,2}((\.[0-9]+)?'|'[0-9]{1,2}(\.[0-9]+)?"))?$/;

/** Returns a value formated in a degree minute second manner correponding to the given syntax
 * Syntax corresponds to an ordered sequence of keywords separated by '-', corresponding to the position of each part of the value (parts being integers).
 * Possible keywords are : 
 *  - signed : indicates that value's sign is given by the presence or abscence of '-' at the beggining of the value (must be first in the sequence)
 *  - cardinal : indicates that value's sign is fiven by a cardinal direction (N, S, E or W) at the beggining of the value (must be first in the sequence)
 *  - degree : indicates the position of the 'degree' part
 *  - minute : indicates the position of the 'minute' part
 *  - second : indicates the position of the 'second' part
 *  - decimal : indicates the position of the 'decimal' part (should always be last and is mainly used to assess whether the decimal part should be included in the final value or not)
 */
export function degreesToSyntax(value: any, orientation: string, syntax: string, unit=false, doTrim=true) {
    if (syntax === "signed-degree-decimal") {
        return (doTrim ? trim(value, 6) : limitPrecision(value, 6)) + (unit ? "°" : ""); // Removing trailing zeros and adding the unit
    }
    const units = syntax.split("-");
    let val = Math.abs(Number(value));
    let result = "";
    units.forEach((u: string) => {
        switch (u) {

            case "signed":
                result = value < 0 ? "-" : "";
                break;

            case "cardinal":
                result = orientation === "lat" ?
                    value < 0 ? "S" : "N"
                :
                    value < 0 ? "W" : "E";
                break;

            case "degree":
                result = result + String(Math.floor(val)) + "°";
                val = val - Math.floor(val); // Keeping only the decimal part
                val = Number(val.toFixed(3)); // Preventing javascript from rounding down values like 0.999999
                break;
            
            case "minute":
                result = result + String(Math.floor(val * 60)) + "'";
                val = val * 60 - Math.floor(val * 60);
                val = Number(val.toFixed(3));
                break;

            case "second":
                result = result + String(Math.floor(val * 60)) + '"';
                val = val * 60 - Math.floor(val * 60);
                val = Number(val.toFixed(3));
                break;

            case "decimal":
                result = result.slice(0,-1) + trim(val, 6).slice(1) + result.slice(-1)[0]; // Removing trailing zeros
                break;

            default:
                break;
        }
    });
    return result;
};

// Returns the value in degree from a in a degree minute second format along with a description of it's syntax
export function syntaxToDegrees(value: any, syntax: string) {
    const units = syntax.split("-");
    const temp = String(value).split(/[°'".]/);
    const vals = isNaN(Number(temp[0][0])) ? [temp[0][0], temp[0].slice(1), ...temp.slice(1)] : ["+", ...temp]; // splitting the sign indicator from the first number
    let sign = 1;
    let mult = 1;
    let result = 0;
    units.forEach((u: string, index: number) => {
        if (vals[index] !== undefined) {
            switch (u) {
            
                case "signed":
                    sign = vals[index] === "+" ? 1 : -1;
                    break;
    
                case "cardinal":
                    sign = vals[index] === "N" || vals[index] === "E" ? 1 : -1;
                    break;
    
                case "degree":
                    result = result + Number(vals[index]);
                    break;
    
                case "minute":
                    mult = 60;
                    result = result + Number(vals[index]) / mult;
                    break;
    
                case "second":
                    mult = 3600;
                    result = result + Number(vals[index]) / mult;
                    break;
    
                case "decimal":
                    const size = vals[index].length;
                    result = result + (Number(vals[index]) / (10**size)) / mult;
                    break;
    
                default:
                    break;
            }
        } else {
            /*console.log("syntax indicated more values than available at index ", index);
            console.log("syntax : ", syntax);
            console.log("input : ", value);
            console.log("values : ", vals);*/
        }
    });
    return result * sign;
};

export function isCoordinate(value: any) {
    return value.match(DECIMAL_REGEX) !== null || value.match(CARDINAL_REGEX) !== null;
};

export function isCompleteCoordinate(value: any) {
    return value.match(DECIMAL_REGEX_COMPLETED) !== null || value.match(CARDINAL_REGEX_COMPLETED) !== null;
};

// Return a decimal degree value from either a decimal value or a degree minute second value
export function makeCoordinate(value: string) {
    let result = null;
    if (value.match(DECIMAL_REGEX_COMPLETED) !== null) {
        result = Number(value);
    } else if (value.match(CARDINAL_REGEX_COMPLETED) !== null) {
        let syntax = "cardinal-degree";
        if (value.includes("'")) syntax = syntax + "-minute";
        if (value.includes('"')) syntax = syntax + "-second";
        if (value.includes(".")) syntax = syntax + "-decimal";
        result = syntaxToDegrees(value, syntax);
    }
    return result;
};

// Limits the value's decimal precision and removes trailing zeros
export function trim(value: any, precision: number) {
    return String(Number(Number(value).toFixed(precision)));
};

// Limits the value's decimal precision and keeps any trailing zeros the value had without adding new ones
export function limitPrecision(value: any, precision: number) {
    const val = String(value);
    let res = "";
    if (val.includes('.')) {
        res = parseFloat(val).toFixed(Math.min(val.split('.')[1].length, precision));
    }
    else {
        res = String(value);
    }
    return res;
};