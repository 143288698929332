import React, { forwardRef } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const ToastMissionChanged = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ id, ...props }, ref) => {
        const { closeSnackbar } = useSnackbar();

        const onClose = () => {
            closeSnackbar(id)
        };

        return (
            <SnackbarContent ref={ref}>
                <Alert
                    severity="warning"
                    sx={{ alignItems: 'center' }}
                >
                    <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">
                            {props.message}
                        </Typography>
                        <IconButton
                            size="large"
                            component={Link}
                            onClick={onClose}
                            to='/getMissions'
                        >
                            <RefreshIcon fontSize='inherit' />
                        </IconButton>
                    </Stack>
                </Alert>
            </SnackbarContent>
        );
    }
);

export default ToastMissionChanged;