import { OnMessageCallback } from 'mqtt';
import { store } from '../../app/store';
import { toDate } from '../../utils/TimeUtils';
import { geomarkersActions, GeomarkerType } from './geomarkersSlice';

export const geomarkersMqttParser: OnMessageCallback = (topic: any, payload: any, packet: any): void => {
    const geomarker = JSON.parse(payload.toString());
    if (toDate(geomarker.delete) <= toDate(Date.now() / 1000)) {
        store.dispatch(geomarkersActions.update({
            id: geomarker.id,
            groupId: geomarker.feature.properties?.group,
            remove: true
        }))
    } else {
        const index = store.getState().nav.geomarkers.geomarkers.findIndex((gm: GeomarkerType) => gm.id === geomarker.id);
        if (index !== -1) {
            store.dispatch(geomarkersActions.update({
                id: geomarker.id,
                groupId: geomarker.feature.properties?.group,
                geomarker,
                remove: false
            }));
        } else {
            store.dispatch(geomarkersActions.add({
                id: geomarker.id,
                groupId: geomarker.feature.properties?.group,
                geomarker,
            }));
        }
    };
};