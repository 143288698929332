import axios from "axios";
import { API_CONFIG } from '../../config/api';

export const AERO_API = {
    getAirports: (token: string, extent: any, types: any[]) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/navigation/airports/${extent}/?type=${types.join('&&type=')}`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getNavaids: (token: string, extent: any, types: any[]) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/navigation/navaids/${extent}/?type=${types.join('&&type=')}`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    getMetar: (token: string, missionId: string, icao: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/weather/${missionId}/product/metar_world/?properties={"station_id": "${icao}"}&inline=true`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
};