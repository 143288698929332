import * as React from 'react';
import { MQTT_URL, APP_ID } from '../../../config/const';
const { connect } = require('mqtt/dist/mqtt'); // https://github.com/mqttjs/MQTT.js/issues/1020

const FleetStatusContext = React.createContext({});

function FleetStatusProvider({ children }: { children: React.ReactNode }) {
    const mqttClientRef = React.useRef<any>(null);
    const [status, setStatus] = React.useState({});

    React.useEffect(() => {
        if (mqttClientRef.current === null) {
            const client = connect(MQTT_URL, { clientId: `missionSelector-${APP_ID}` });
            client.on('message', (topic: any, message: any) => {
                const msg = JSON.parse(message.toString());
                setStatus(status => ({
                    ...status,
                    [msg.actor]: msg
                }));
            });
            client.on('connect', () => {
                client.subscribe('planet/+/connection/+');
            });
            mqttClientRef.current = client;
        }
        return () => {
            if (mqttClientRef.current) {
                setStatus({});
                mqttClientRef.current.end();
                mqttClientRef.current = null;
            }
        }
    }, []);

    return <FleetStatusContext.Provider value={status}>{children}</FleetStatusContext.Provider>
};

function useVehicleStatus({ vehicleId }: { vehicleId: string }) {
    const context: any = React.useContext(FleetStatusContext);
    if (context === undefined) {
        throw new Error('useFleetStatus must be used within a FleetStatusProvider');
    }
    if (context[vehicleId] === undefined) return 'unknown';
    else return context[vehicleId].status;
};

export { FleetStatusProvider, useVehicleStatus };