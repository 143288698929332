import { Fill, Stroke, Style, Text } from "ol/style";
import { Polygon } from "ol/geom";
import { transform } from "ol/proj";
import { shift_point, NM_TO_M } from '../../../../utils/GeomUtils';
import { store } from "../../../../app/store";
import { isBackgroundDark } from "../../../../config/map";

export const circlesStyle = (feature: any, resolution: any) => {

    const mapProjection = store.getState().map.projection;
    const dataProjection = "EPSG:4326";

    const color = isBackgroundDark()
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.7)";

    const computedRadius = window.innerHeight * (resolution ** 0.95) / 17500; // a 0.95 is applied to the resolution to prevent the circles from growing out of proportions due to the projection on the map on lower zoom levels
    const roundedRadius10 = Math.floor(computedRadius / 10) * 10; // rounded to tens
    const roundedRadius1 = Math.floor(computedRadius); // rounded to units
    const roundedRadius01 = Math.floor(computedRadius * 10) / 10; // rounded to tenth

    const baseRadius = roundedRadius10 ? roundedRadius10 : roundedRadius1 ? roundedRadius1 : roundedRadius01 ? roundedRadius01 : 0.1;
    const CIRCLES = [{ radius: baseRadius }, { radius: 2 * baseRadius }, { radius: 3 * baseRadius }];
    const NB_SEGMENTS = 64;

    const center = transform(
        feature.getGeometry().getCoordinates(),
        mapProjection,
        dataProjection
    );
    const styles: Style[] = [];

    CIRCLES.forEach((c, i) => {
        styles.push(
            new Style({
                geometry: new Polygon([
                    Array(NB_SEGMENTS + 1)
                        .fill(null)
                        .map((_, i) => (360 * i) / NB_SEGMENTS) // angles in degrees
                        .map(angle =>
                            transform(
                                shift_point(
                                    center,
                                    angle,
                                    c.radius * NM_TO_M
                                ),
                                dataProjection,
                                mapProjection
                            )
                        )
                ]),
                stroke: new Stroke({
                    color: color,
                    lineDash: [4, 4],
                    width: 1
                }),
                text: new Text({
                    text:
                        c.radius
                            .toFixed(roundedRadius1 ? 0 : 1)
                            .toString() + " NM",
                    font: "bold 10px sans-serif",
                    textBaseline: "bottom",
                    placement: "line",
                    fill: new Fill({ color: color })
                })
            })
        );
    });

    return styles;
};