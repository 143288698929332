import { Middleware } from 'redux';
import { MqttClient } from 'mqtt';
import match from 'mqtt-match';
import { mqttActions } from './mqttSlice';
import { Subscription } from './mqttSlice';
import {
    MQTT_TELEMETRY_MSG_KEY,
    MQTT_MISSIONS_CHANGE_KEY,
    MQTT_TELEMETRY_STOP_KEY,
    MQTT_CHAT_MESSAGE_MSG_KEY,
    MQTT_CHAT_CONVERSATION_MSG_KEY,
    MQTT_GEOMARKERS_MSG_KEY,
    MQTT_TRACKING_CHANGE_KEY,
    MQTT_TRANSER_MSG_KEY,
    MQTT_WEATHER_MSG_KEY,
    MQTT_CUSTOM_MSG_KEY,
    MQTT_ADSB_MSG_KEY
} from '../../config/mqtt';
import { telemetryMqttParser } from '../telemetry/telemetryMqttParser';
import { telemetryStopMqttParser } from '../telemetry/telemetryStopMqttParser';
import { chatMessageMqttParser, chatConversationMqttParser } from '../chat/chatMqttParser';
import { geomarkersMqttParser } from '../geomarkers/geomarkersMqttParser';
import { trackingMqttParser } from '../tracking/trackingMqttParser';
import { transferMqttParser } from '../transfer/transferMqttParser';
import { missionMqttParser } from '../mission/missionMqttParser';
import { weatherMqttParser } from '../weather/weatherMqttParser';
import { customMqttParser } from '../custom/customMqttParser';
import { adsbMqttParser } from '../aero/adsbMqttParser';
const { connect } = require('mqtt/dist/mqtt'); // https://github.com/mqttjs/MQTT.js/issues/1020

const mqttMiddleware: Middleware = store => {
    let client: MqttClient;

    return next => action => {
        
        if (mqttActions.connect.match(action)) {
            const { url, onConnect, ...rest } = action.payload;

            client = connect(url, rest);

            client.on('connect', () => {
                store.dispatch(mqttActions.connectionEstablished());
                if (onConnect) {
                    const { topic, payload, qos, retain } = onConnect;
                    client.publish(topic, payload, { qos, retain });
                }
            });

            client.on('error', (error) => {
                store.dispatch(mqttActions.connectionError({ name: error.name, message: error.message }));
            });

            client.on('close', (e: any) => {
                // This makes an issue when changing the endpoint to which we connect.
                //store.dispatch(mqttActions.connectionEnd());
            });

            client.on('disconnect', () => {
                store.dispatch(mqttActions.connectionEnd());
            });

            client.on('offline', () => {
                store.dispatch(mqttActions.connectionEnd());
            });

            client.on('end', () => {
                store.dispatch(mqttActions.connectionEnd());
            });

            client.on('message', (topic, payload, packet) => {
                // Here we should call all the callback functions of the store for which the topic match
                store.getState().mqtt.subscriptions.forEach((s: Subscription) => {
                    if (match(s.topic, topic)) {
                        switch (s.callback_key) {
                            case MQTT_CHAT_MESSAGE_MSG_KEY:
                                chatMessageMqttParser(topic, payload, packet);
                                break;
                            case MQTT_CHAT_CONVERSATION_MSG_KEY:
                                chatConversationMqttParser(topic, payload, packet);
                                break;
                            case MQTT_TELEMETRY_MSG_KEY:
                                telemetryMqttParser(topic, payload, packet);
                                break;
                            case MQTT_GEOMARKERS_MSG_KEY:
                                geomarkersMqttParser(topic, payload, packet);
                                break;
                            case MQTT_TRANSER_MSG_KEY:
                                transferMqttParser(topic, payload, packet);
                                break;
                            case MQTT_CUSTOM_MSG_KEY:
                                customMqttParser(topic, payload, packet);
                                break;
                            case MQTT_WEATHER_MSG_KEY:
                                weatherMqttParser(topic, payload, packet);
                                break;
                            case MQTT_TRACKING_CHANGE_KEY:
                                trackingMqttParser(topic, payload, packet);
                                break;
                            case MQTT_MISSIONS_CHANGE_KEY:
                                missionMqttParser(topic, payload, packet);
                                break;
                            case MQTT_TELEMETRY_STOP_KEY:
                                telemetryStopMqttParser(topic, payload, packet);
                                break;
                            case MQTT_ADSB_MSG_KEY:
                                adsbMqttParser(topic, payload, packet);
                                break;
                            default:
                                break;
                        }
                    }
                });
            });
        }

        if (mqttActions.end.match(action)) {
            client.end();
        }

        if (mqttActions.subscribe.match(action)) {
            const index = store.getState().mqtt.subscriptions.findIndex((s: Subscription) => s.id === action.payload.id);
            if (index === -1) client.subscribe(action.payload.topic, action.payload.options);
            else return; // do not dispatch the action if the subscription already exists
        }

        if (mqttActions.unsubscribe.match(action)) {
            const index = store.getState().mqtt.subscriptions.findIndex((s: Subscription) => s.id === action.payload);
            if (index !== -1) client.unsubscribe(store.getState().mqtt.subscriptions[index].topic);
        }

        if (mqttActions.publish.match(action)) {
            const { topic, qos, payload, retain } = action.payload;
            client.publish(topic, payload, { qos, retain });
        }

        next(action);
    }
}

export default mqttMiddleware;