// Inspired from https://tiagohorta1995.medium.com/dynamic-list-virtualization-using-react-window-ab6fbf10bfb2
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { useConfirm } from 'material-ui-confirm';
import { v4 as uuidv4 } from 'uuid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Messages from './Messages';
import BottomBar from './BottomBar';
import { ConversationType, MessageType, chatActions } from '../../redux/chat/chatSlice';
import { widgetActions } from '../../redux/widget/widgetSlice';
import { RootState } from '../../app/store';
import { ALL_MESSAGES_CHANNEL_ID } from '../../config/chat';
import { NAVIGATION_GRID_ID, CHAT_WIDGET_ID } from '../../config/widget';
import { CONVERSATION_KIND_PUBLIC } from '../../config/chat';
import { store } from "../../app/store";
import isEqual from 'lodash.isequal';

export const messagesSelector = createSelector(
    (state: RootState) => state.chat.messages,
    (state: RootState) => state.chat.conversations,
    (_: RootState, properties: { conversation: ConversationType | undefined }) => properties,
    (messages, conversations, { conversation }) => {
        let to_return: MessageType[] = [];
        if (conversation === undefined) return to_return;
        if (conversation.id === ALL_MESSAGES_CHANNEL_ID) to_return = messages;
        else {
            to_return = messages.filter((m: MessageType) => {
                if (m.conversation === conversation.id) return true;
                const index = conversations.broadcastChannels.findIndex((b: ConversationType) => b.id === m.conversation);
                if (index !== -1 && conversation.created && m.created > conversation.created) return true; // display broadcast messages only if the conversation has been created before the broadcast message is created
                return false;
            });
        }
        return to_return;
    }
);

function MoreActions({ id, name, conv_kind }: { id: string, name: string, conv_kind: string }) {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const handleOpen = useCallback((): void => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback((): void => {
        setOpen(false);
    }, [setOpen]);

    const onArchiveConvClick = useCallback(() => {
        confirm({
            confirmationButtonProps: { color: "inherit" },
            cancellationButtonProps: { color: "inherit" },
            description: `Please confirm archiving of conversation ${name}.`
        })
            .then(() => {
                dispatch(chatActions.archive_conversation({ conversationId: id }));
                handleClose();
            })
            .catch(() => {
                handleClose();
            });
    }, [name, id, confirm, handleClose, dispatch]);

    const onExportConvClick = useCallback(() => {
        let messages = store.getState().chat.messages;
        if (id !== ALL_MESSAGES_CHANNEL_ID) {
            messages = messages.filter((m: any) => m.conversation === id)
        };
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(messages, null, 4)]));
        const a = document.createElement('a');
        a.href = url;
        a.download = name + '-conversation.json';
        a.click();
        handleClose();
    }, [id, name, handleClose]);

    return (
        <>
            <IconButton ref={ref} onClick={handleOpen}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav">
                    <ListItemButton onClick={onExportConvClick}>
                        <ListItemIcon>
                            <FileDownloadIcon />
                        </ListItemIcon>
                        <ListItemText primary="Export" />
                    </ListItemButton>
                    <ListItemButton
                        disabled={conv_kind !== CONVERSATION_KIND_PUBLIC}
                        onClick={onArchiveConvClick}
                    >
                        <ListItemIcon>
                            <ArchiveIcon />
                        </ListItemIcon>
                        <ListItemText primary="Archive" />
                    </ListItemButton>
                </List>
            </Popover>
        </>
    );
};

function Header({ active, messagesCount, openSide, mobile }: { active: ConversationType, messagesCount: number, openSide: () => void, mobile: boolean }) {
    const dispatch = useDispatch();
    const medium = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

    const addWidget = () => {
        dispatch(widgetActions.add_widget({ gridId: NAVIGATION_GRID_ID, id: uuidv4(), kind: CHAT_WIDGET_ID, params: { conversationId: active.id }, medium }))
    };

    return (
        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ width: '100%', padding: (theme) => theme.spacing(1), backgroundColor: (theme) => theme.palette.primary.dark }}>
            <Box>
                <Typography variant="h5">{`# ${active.name}`}</Typography>
                <Typography variant="h6" color="textSecondary">{`Total messages: ${messagesCount}`}</Typography>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
                {!mobile &&
                    <Button variant="contained" onClick={addWidget} startIcon={<WebAssetIcon />}>
                        ADD WIDGET
                    </Button>
                }
                <MoreActions id={active.id} name={active.name} conv_kind={active.conv_kind} />
                {mobile &&
                    <IconButton onClick={openSide}>
                        <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                }
            </Stack>
        </Stack>
    );
};

export default function ChatRoom({ active, mobile, openSide }: { active: ConversationType | undefined, mobile: boolean, openSide: () => void }) {
    const messages = useSelector((state: RootState) => messagesSelector(state, { conversation: active }), isEqual);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}
        >
            {
                active !== undefined &&
                <>
                    <Stack sx={{ width: '100%', height: '100%' }} direction="column" spacing={1}>
                        <Stack sx={{ width: '100%' }} direction="column" spacing={0}>
                            <Header active={active} messagesCount={messages.length} openSide={openSide} mobile={mobile} />
                            <Divider />
                        </Stack>
                        <Messages key={active.id} conversation={active} messages={messages} />
                        <BottomBar conversation={active} mobile={mobile} />
                    </Stack>
                </>
            }
        </Box>
    );
};
