/* Port of strftime(). Compatibility notes:
 *
 * %c - formatted string is slightly different
 * %D - not implemented (use "%m/%d/%y" or "%d/%m/%y")
 * %e - space is not added
 * %E - not implemented
 * %h - not implemented (use "%b")
 * %k - space is not added
 * %n - not implemented (use "\n")
 * %O - not implemented
 * %r - not implemented (use "%I:%M:%S %p")
 * %R - not implemented (use "%H:%M")
 * %t - not implemented (use "\t")
 * %U - not implemented
 * %W - not implemented
 * %+ - not implemented
 * %% - not implemented (use "%")
 *
 * strftime() reference:
 * http://man7.org/linux/man-pages/man3/strftime.3.html
 *
 * Day of year (%j) code based on Joe Orost's answer:
 * http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
 *
 * Week number (%V) code based on Taco van den Broek's prototype:
 * http://techblog.procurios.nl/k/news/view/33796/14863/calculate-iso-8601-week-and-year-in-javascript.html
 */
export function strftime(sFormat: string, date: Date) {
    if (!(date instanceof Date)) date = new Date();
    var nDay = date.getDay(),
        nDate = date.getDate(),
        nMonth = date.getMonth(),
        nYear = date.getFullYear(),
        nHour = date.getHours(),
        aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
        isLeapYear = function () {
            return (nYear % 4 === 0 && nYear % 100 !== 0) || nYear % 400 === 0;
        },
        getThursday = function () {
            var target = new Date(date);
            target.setDate(nDate - ((nDay + 6) % 7) + 3);
            return target;
        },
        zeroPad = function (nNum: number, nPad: number) {
            return ('' + (Math.pow(10, nPad) + nNum)).slice(1);
        };
    return sFormat.replace(/%[a-z]/gi, function (sMatch) {
        return {
            '%a': aDays[nDay].slice(0, 3),
            '%A': aDays[nDay],
            '%b': aMonths[nMonth].slice(0, 3),
            '%B': aMonths[nMonth],
            '%c': date.toUTCString(),
            '%C': String(Math.floor(nYear / 100)),
            '%d': zeroPad(nDate, 2),
            '%e': String(nDate),
            '%F': date.toISOString().slice(0, 10),
            '%G': String(getThursday().getFullYear()),
            '%g': ('' + getThursday().getFullYear()).slice(2),
            '%H': zeroPad(nHour, 2),
            '%I': zeroPad((nHour + 11) % 12 + 1, 2),
            '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth > 1 && isLeapYear()) ? 1 : 0), 3),
            '%k': String('' + nHour),
            '%l': String((nHour + 11) % 12 + 1),
            '%m': zeroPad(nMonth + 1, 2),
            '%M': zeroPad(date.getMinutes(), 2),
            '%p': (nHour < 12) ? 'AM' : 'PM',
            '%P': (nHour < 12) ? 'am' : 'pm',
            '%s': String(Math.round(date.getTime() / 1000)),
            '%S': zeroPad(date.getSeconds(), 2),
            '%T': (function () {
                return (`${zeroPad(date.getUTCHours(), 2)}:${zeroPad(date.getUTCMinutes(), 2)}:${zeroPad(date.getUTCSeconds(), 2)}`)
            })(),
            '%N': (function () {
                return (`${date.getUTCFullYear()}-${zeroPad(date.getUTCMonth() + 1, 2)}-${zeroPad(date.getUTCDate(), 2)}T${zeroPad(date.getUTCHours(), 2)}:${zeroPad(date.getUTCMinutes(), 2)}:${zeroPad(date.getUTCSeconds(), 2)}Z`)
            })(), // not standard but used internally
            '%u': String(nDay || 7),
            '%V': (function () {
                var target = getThursday(),
                    n1stThu = target.valueOf();
                target.setMonth(0, 1);
                var nJan1 = target.getDay();
                if (nJan1 !== 4) target.setMonth(0, 1 + ((4 - nJan1) + 7) % 7);
                return zeroPad(1 + Math.ceil((n1stThu - target.getTime()) / 604800000), 2);
            })(),
            '%w': String('' + nDay),
            '%x': date.toLocaleDateString(),
            '%X': date.toLocaleTimeString(),
            '%y': ('' + nYear).slice(2),
            '%Y': String(nYear),
            '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
            '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
        }[sMatch] || sMatch;
    });
};

/**
 * Equivalent to  `(n) -> sprintf "%02d", n`
 */
const _2digits = (n: number) => {
    let s = n.toString();
    if (s.length < 2) s = "0" + s;
    return s;
};

/**
 * Create a date from timestamps / strings / dates.
 * Numbers are interpreted as second-based timestamps
 */
export const toDate = (d: number | string | Date) => {
    if (typeof d === "number") return new Date(d * 1000);
    else if (typeof d === "string") {
        if (isNaN(Number(d))) {
            return new Date(d);
        } else {
            return new Date(Number(d) * 1000);
        }
    } else if (d instanceof Date) return d;
    else {
        let error = new Error("Invalid date argument");
        throw error;
    }
};

/**
     Return a string "hh:mm:ss"  
   */
export const hour = (d: number | string | Date) => {
    d = toDate(d);
    if (!d.getTime()) return "-";
    const hm =
        _2digits(d.getUTCHours()) + ":" + _2digits(d.getUTCMinutes());
    const s = d.getUTCSeconds();
    if (s) return hm + ":" + _2digits(s);
    else return hm + ":00";
}

/**
   * Return the hour if today or the number of days ago
   */
export const hourOrNbDays = (d: number | string | Date) => {
    const now = Math.round(Date.now() / 1000);
    const date = toDate(d);
    const nb_days = Math.round((now - date.getTime() / 1000) / (60 * 60 * 24));
    if (nb_days) {
        const dayString = nb_days > 1 ? `${nb_days} days ago` : "Yesterday";
        return dayString;
    } else {
        return hour(date);
    }
};

export const timestampToString = (unixTs: number) => {
    const date = new Date(unixTs * 1000);
    const now = Date.now();
    const nb_days = Math.round((now - date.getTime()) / (60 * 60 * 1000 * 24));
    if (nb_days) {
        const dayString = nb_days > 1 ? `${nb_days} days ago` : "Yesterday";
        return dayString;
    } else {
        return strftime('%T', date)
    }
};

/**
   * Return a string "hh:mm"
   */
export const short_hour = (d: number | string | Date) => {
    d = toDate(d);
    if (!d.getTime()) return "-";
    return (
        _2digits(d.getUTCHours()) + ":" + _2digits(d.getUTCMinutes())
    );
};

/**
 * Return true if same day
 */
export const sameDay = (d1: number | string | Date, d2: number | string | Date) => {
    d1 = toDate(d1);
    d2 = toDate(d2);

    return (
        d1.getUTCFullYear() === d2.getUTCFullYear() &&
        d1.getUTCMonth() === d2.getUTCMonth() &&
        d1.getUTCDate() === d2.getUTCDate()
    );
};

/**
   * Return a string "Week Day, Month Day Number"
   */
export const longDay = (d: number | string | Date) => {
    const DAYS = [
        "Sunday",
        "Monday",
        "Thuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];
    const MONTHS = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    d = toDate(d);
    if (!d.getTime()) return "-";
    return (
        DAYS[d.getDay()] +
        ", " +
        MONTHS[d.getMonth()] +
        " " +
        d.getDate() +
        (d.getDate() % 10 === 1
            ? "st"
            : d.getDate() % 10 === 2
                ? "nd"
                : d.getDate() % 10 === 3
                    ? "rd"
                    : "th")
    );
};

/**
   * Display a date:
   *
   * * if <1h old, hours-minutes-seconds
   * * if >1h old but from today, hours-minutes
   * * if from another day, year-month-day
   */
export const displayDate = (d: number | string | Date) => {
    if (d === null || d === undefined || d === "") return "-";
    else d = toDate(d);
    let s = d.toISOString();
    const now = new Date();
    const underAnHour = Math.abs(now.getTime() - d.getTime()) <= 3.6e6;
    if (underAnHour) {
        return s.slice(11, 19);
    }
    const fromToday = now.toISOString().slice(0, 10) === s.slice(0, 10);
    if (fromToday) {
        return s.slice(11, 16);
    }
    return s.slice(0, 10);
};

export const getDate = (d: number | string | Date) => {
    if (d === null || d === undefined || d === "") return "-";
    else d = toDate(d);
    let s = d.toISOString();
    return s.slice(0, 10);
};

export const formatDuration = (duration: number) => {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + "h" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + "m" + (secs < 10 ? "0" : "");
    ret += "" + secs + "s";

    return ret;
};

export const getHourlyIntervals = (startDate: number | string | Date, endDate: number | string | Date) => {

    let intervals: any[] = [];

    const start_ts = Math.floor(toDate(startDate).getTime() / 1000);
    const end_ts = Math.ceil(toDate(endDate).getTime() / 1000);

    const min = Math.floor(start_ts / 3600);
    const max = Math.floor(end_ts / 3600);

    for (let i = min; i <= max; i++) {
        if (i === min && i !== max) intervals.push([strftime('%N', toDate(start_ts)), strftime('%N', toDate(3600 * (min + 1)))]);
        else if (i !== min && i !== max) intervals.push([strftime('%N', toDate(3600 * i)), strftime('%N', toDate(3600 * (i + 1)))]);
        else if (i === max && i !== min) intervals.push([strftime('%N', toDate(3600 * max)), strftime('%N', toDate(end_ts))]);
        else if (i === min && i === max) intervals.push([strftime('%N', toDate(start_ts)), strftime('%N', toDate(end_ts))]);
    };

    return intervals;
};

export const subtractSecondsToDate = (d: string, seconds: number) => {
    let date = toDate(d);
    return (strftime('%N', toDate(date.getTime() / 1000 - seconds)));
};

export const getCurrentDayDate = () => {
    let currentDayDate = new Date();
    currentDayDate.setUTCHours(0, 0, 0, 0);
    return strftime('%N', currentDayDate);
};