import React, { useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { AppRouteType, APP_ROUTES } from "../../config/routes";
import { AUTH_LEVELS, AuthLevelObject } from "../../config/auth";
import { RootState } from "../../app/store";

export const rightsCheckSelector = createSelector(
    (state: RootState) => state.auth.level,
    (_: RootState, properties: { accessRights: AuthLevelObject }) => properties,
    (level, { accessRights }) => {
        if (level) return (AUTH_LEVELS[level].value >= accessRights.value)
        else return (!accessRights.value);
    },
);

function CheckedRoute({ component: Component, path, accessRights, ...rest }: { component: () => JSX.Element, path: string, accessRights: AuthLevelObject }) {
    const rightsCheck = useSelector((state: RootState) => rightsCheckSelector(state, { accessRights }));

    return (
        <Route
            path={path}
            render={({ location }) => {
                if (rightsCheck) return (<Component {...rest} />);
                else return (
                    <Redirect to={{
                        pathname: '/login',
                        state: { from: location }
                    }} />
                );
            }}
        />
    );
};

const NotFound = () => {
    return (<div>Page Not Found</div>);
};

function reduceRoutes(routes: AppRouteType[], props?: any) {
    let routesList: any[] = [];

    routes.forEach(r => {
        const { id, path, component, default: defRoute, children, exact, ...rest } = r;
        if (path && component) {
            routesList.push(<CheckedRoute exact={exact} key={id} path={path} component={component} {...rest} {...props} />);

            if (defRoute) {
                routesList.push(<Route exact key={id + "default-route"} path="/" {...props}>
                    <Redirect to={path} />
                </Route>);
            }
        }

        if (children && children.length > 0) {
            const childrenRoutes = reduceRoutes(children);
            routesList.push(...childrenRoutes);
        }
    })

    return routesList;
};

export default function AppRoutes(props: any) {

    const routes = useMemo(() => reduceRoutes(APP_ROUTES, props), [props]);

    return (
        <Switch>
            {routes}
            <Route component={NotFound} />
        </Switch>
    );
};