import React, { useRef } from 'react';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import useDeepCompareEffect from 'use-deep-compare-effect';
import uPlot from 'uplot';
import Box from '@mui/material/Box';
import 'uplot/dist/uPlot.min.css';

type Props = {
    width: number;
    height: number;
    series: uPlot.AlignedData;
    options: uPlot.Options;
};

function isAscending(arr: number[]) {
    for (let i = arr.length - 1; i > 0; i--) {
        if (arr[i] <= arr[i - 1]) return false;
    };
    return true;
};

export function Graph({ width, height, series, options }: Props) {

    const targetRef = useRef<HTMLDivElement>();
    const uplotRef = useRef<uPlot | null>(null);
    const prevOptions = useRef({ value: options }).current;


    function destroy(plot: uPlot | null) {
        if (plot) {
            plot.destroy();
            uplotRef.current = null;
        }
    };

    function create() {
        const plot = new uPlot(options, [], targetRef.current);
        uplotRef.current = plot;
    };

    useEffectOnce(() => {
        create();
        return () => {
            destroy(uplotRef.current);
        };
    });

    useDeepCompareEffect(() => {
        if (!uplotRef.current) return;

        if (series.length < uplotRef.current.series.length) {
            console.warn("data length can't be > to options length");
            return;
        };

        if (!isAscending(series[0] as number[])) {
            console.warn("data is not properly ordered");
            return;
        };

        if (!series.every((s: any) => s.length === series[0].length)) {
            console.warn("all data arrays must be of same length");
            return;
        };

        uplotRef.current.setData(series);

    }, [series]);

    useUpdateEffect(() => {
        if (!uplotRef.current) return;

        const forceRedraw = options.series.length <= uplotRef.current.series.length;

        // https://github.com/leeoniya/uPlot/issues/182#issuecomment-1049978889
        for (let j = uplotRef.current.series.length - 1; j > 0; j--) {
            try {
                uplotRef.current.delSeries(j); // may return an error if the serie does not exists yet
            } catch { };
        };

        for (let i = 1; i < options.series.length; i++) {
            uplotRef.current.addSeries(options.series[i], i);
        };

        if (forceRedraw) uplotRef.current.redraw(); // can't call redraw if we just added a serie and data is not yet set

        return () => {
            prevOptions.value = options;
        };

    }, [options]);

    useUpdateEffect(() => {
        uplotRef.current?.setSize({ width, height });
    }, [width, height]);

    return (
        <Box style={{ minHeight: height }} ref={targetRef} />
    );

};