import React, { useCallback } from 'react';
import { useElementSize } from 'usehooks-ts';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Graph } from './Graph';
import { ValueBoxes, ValueBoxParam } from './ValueBoxes';
import { GraphType } from './GraphContext';
import isEqual from 'lodash.isequal';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    borderRadius: 0
}));


const StyledCardContent = styled(CardContent)(({ theme }) => ({
    '&.MuiCardContent-root': {
        paddingBottom: 0
    }
}));

export const GraphItem = React.memo(function GraphItem({ id, size, maxHeight, timespan, series, options, isLoading = false, onValueBoxClick }: GraphType) {
    const [elRef, { width }] = useElementSize();
    const params: ValueBoxParam[] = options.series.reduce((result: any, s: any, i: number) => {
        if (i !== 0) {
            let value = null;
            if (series[i]) {
                let index = series[i].length;
                while (index-- && (series[i][index] === undefined)); // This will get the last value of the series which is not undefined
                value = series[i][index];
            };

            result.push({
                label: s.label as string,
                color: s.stroke as string,
                unit: s.unit as string,
                shown: s.show as boolean,
                toFixed: s.toFixed as number,
                value
            });
        }
        return result;
    }, []);

    const onClick = useCallback(() => {
        onValueBoxClick?.((options.series[1] as any).serieId as string)
    }, [options, onValueBoxClick]);

    return (
        <Grid key={id} item xs={size}>
            <StyledCard sx={{ width: '100%' }}>
                <StyledCardContent ref={elRef} sx={{ padding: 0, position: 'relative' }}>
                    {
                        maxHeight &&
                        <>
                            <ValueBoxes
                                params={params}
                                onClick={onClick}
                            />
                            <Graph
                                width={width}
                                height={maxHeight}
                                series={series}
                                options={options}
                            />
                            <Backdrop
                                sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </>
                    }
                </StyledCardContent>
            </StyledCard>
        </Grid>
    );
}, isEqual);