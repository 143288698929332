import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'usehooks-ts';
import { GeoJSON, KML } from "ol/format";
import isEqual from "lodash/isEqual";
import VectorLayer from '../map/layers/VectorLayer';
import { RLayerImage } from 'rlayers';
import { RootState } from '../../../app/store';

const CustomLayer = React.memo(function CustomLayer({ component: Component, extent, ...rest }: { component: any, extent: any }) {
    const [key, setKey] = useState(0);
    const prevExtent = useRef({ value: null }).current;

    useUpdateEffect(() => {
        /* 
            We have to force component remount via the key update on extent value change,
            because RLayerImage is not updated on extent change
        */
        if (!isEqual(extent, prevExtent.value)) setKey(key => key += 1);

        return () => {
            prevExtent.value = extent
        }
    }, [extent]);

    return (<Component key={key} extent={extent} {...rest} />)
}, isEqual);


export default function Custom({ zIndex }: { zIndex: number }) {
    const customs = useSelector((state: RootState) => state.nav.custom.files);

    return (
        <>
            {
                Object.entries(customs).map(([id, custom]) => {
                    let { data_type, file_name, visible, ...rest } = custom;
                    let component = null;
                    let opts: any = {
                        projection: rest.source_projection
                    };

                    if (!visible) return null;

                    switch (data_type) {
                        case "vector":
                            const ext = file_name.split(".").slice(-1)[0];
                            if (ext.includes('json') || ext.includes('JSON')) {
                                component = VectorLayer;
                                opts.image = true;
                                opts.format = new GeoJSON();
                            } else if (ext.includes('kml') || ext.includes('KML')) {
                                component = VectorLayer;
                                opts.image = true;
                                opts.format = new KML({
                                    iconUrlFunction: function (href: string) {
                                        return href.replace('maps.google.com', window.location.host);
                                    }
                                });
                            }
                            break;
                        case "raster":
                            component = RLayerImage;
                            opts.extent = [rest.min_long, rest.min_lat, rest.max_long, rest.max_lat];
                            break;
                        default:
                            break;
                    }

                    if (!component) return null;
                    return <CustomLayer key={id} component={component} {...rest} zIndex={zIndex + rest.zIndex}  {...opts} />
                })
            }
        </>
    );
};