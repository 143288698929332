import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Box from '@mui/material/Box';
import { DashboardRouteProvider } from "../context/DashboardRouteProvider";
import DashboardRoutes from "../routes/DashboardRoutes";
import Topbar from "./topbar/Topbar";
import { DASHBOARD_ROUTES } from "../../config/routes";
import { RootState } from "../../app/store";
import { mqttActions } from "../../redux/mqtt/mqttSlice";
import {
    MQTT_CHAT_MESSAGE_MSG_KEY,
    MQTT_CHAT_CONVERSATION_MSG_KEY,
    MQTT_TELEMETRY_MSG_KEY,
    MQTT_MISSIONS_CHANGE_KEY,
    MQTT_TELEMETRY_STOP_KEY,
    MQTT_GEOMARKERS_MSG_KEY,
    MQTT_TRACKING_CHANGE_KEY,
    MQTT_TRANSER_MSG_KEY,
    MQTT_WEATHER_MSG_KEY,
    MQTT_CUSTOM_MSG_KEY,
    MQTT_ADSB_MSG_KEY
} from "../../config/mqtt";
import {
    CHAT_MESSAGE_DISTRIBUTED_CLASS,
    CHAT_CONVERSATION_DISTRIBUTED_CLASS,
    TELEMETRY_DISTRIBUTED_CLASS,
    GEOMARKER_DISTRIBUTED_CLASS,
    TRANSFER_DISTRIBUTED_CLASS,
    CUSTOM_DISTRIBUTED_CLASS,
    WEATHER_DISTRIBUTED_CLASS,
    ADSB_DISTRIBUTED_CLASS
} from "../../config/distributed";
import { missionActions } from "../../redux/mission/missionSlice";
import ToastContainer from '../toast/Container';
import Toast from './toasts/Toast';
import ToastInfo from './toasts/Info';
import ToastMissionChanged from './toasts/MissionChanged';
import ToastInvalidToken from './toasts/InvalidToken';
import ToastApiError from './toasts/ApiError';
import {
    DASHBOARD_TOAST_CONTAINER_ID,
    DASHBOARD_TOAST_INFO_ID,
    DASHBOARD_TOAST_MISSION_CHANGED_ID,
    DASHBOARD_TOAST_INVALID_TOKEN,
    DASHBOARD_TOAST_API_ERROR
} from '../../config/toast';
import Helmet from '../helmet/Helmet';
import { useUpdateEffect } from "usehooks-ts";

export default function Dashboard() {

    const dispatch = useDispatch();
    const { url, params }: { url: string, params: any } = useRouteMatch();
    const isMqttConnected = useSelector((state: RootState) => state.mqtt.isConnected);
    const active = useSelector((state: RootState) => state.mission.active);
    const isActive = useSelector((state: RootState) => state.replay.isActive);
    const prevMqttStatus = useRef({ isMqttConnected }).current;

    useEffect(() => {
        if (isMqttConnected && !isActive) {
            dispatch(mqttActions.subscribe({ id: `planet/${active}/chat/message`, topic: `planet/${active}/${CHAT_MESSAGE_DISTRIBUTED_CLASS}/+`, options: {}, callback_key: MQTT_CHAT_MESSAGE_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/chat/conversation`, topic: `planet/${active}/${CHAT_CONVERSATION_DISTRIBUTED_CLASS}/+`, options: {}, callback_key: MQTT_CHAT_CONVERSATION_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/telemetry`, topic: `planet/${active}/${TELEMETRY_DISTRIBUTED_CLASS}/+`, options: {}, callback_key: MQTT_TELEMETRY_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/geomarkers`, topic: `planet/${active}/${GEOMARKER_DISTRIBUTED_CLASS}/+`, options: {}, callback_key: MQTT_GEOMARKERS_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/transfer`, topic: `planet/${active}/${TRANSFER_DISTRIBUTED_CLASS}/+`, options: {}, callback_key: MQTT_TRANSER_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/weather`, topic: `planet/${active}/${WEATHER_DISTRIBUTED_CLASS}/#`, options: {}, callback_key: MQTT_WEATHER_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/custom`, topic: `planet/${active}/${CUSTOM_DISTRIBUTED_CLASS}/#`, options: {}, callback_key: MQTT_CUSTOM_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/adsb`, topic: `planet/${active}/${ADSB_DISTRIBUTED_CLASS}/+`, options: {}, callback_key: MQTT_ADSB_MSG_KEY }));
            dispatch(mqttActions.subscribe({ id: `planet/${active}/connection`, topic: `planet/${active}/connection/+`, options: {}, callback_key: MQTT_TRACKING_CHANGE_KEY }));
            dispatch(mqttActions.subscribe({ id: "planet/mission", topic: "planet/+/mission", options: {}, callback_key: MQTT_MISSIONS_CHANGE_KEY }));
            dispatch(mqttActions.subscribe({ id: "telemetry/stop", topic: "telemetry/stop/+", options: {}, callback_key: MQTT_TELEMETRY_STOP_KEY }));
        }
        return function cleanup() {
            dispatch(mqttActions.unsubscribe(`planet/${active}/chat/message`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/chat/conversation`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/telemetry`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/geomarkers`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/transfer`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/weather`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/custom`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/adsb`));
            dispatch(mqttActions.unsubscribe(`planet/${active}/connection`));
            dispatch(mqttActions.unsubscribe("planet/mission"));
            dispatch(mqttActions.unsubscribe("telemetry/stop"));
        };
    }, [dispatch, active, isMqttConnected, isActive]);

    useEffect(() => {
        if (params.missionId !== active) dispatch(missionActions.set_active_mission({ missionId: params.missionId }));
    }, [active, params.missionId, dispatch]);

    useUpdateEffect(() => {
        if (prevMqttStatus.isMqttConnected === false && isMqttConnected === true) {
            // If mqtt disconnects, we want to trigger api requests when it reconnects so we reset the active mission
            dispatch(missionActions.set_active_mission({ missionId: active }));
        }

        return () => {
            prevMqttStatus.isMqttConnected = isMqttConnected;
        };
    }, [isMqttConnected, active]);

    /* TODO : find a way to manage CHANGED and NEWMISSION as done in Dashboard.jsx */
    if (params.missionId !== active) return null;
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <ToastContainer
                id={DASHBOARD_TOAST_CONTAINER_ID}
                components={{
                    [DASHBOARD_TOAST_INFO_ID]: ToastInfo,
                    [DASHBOARD_TOAST_MISSION_CHANGED_ID]: ToastMissionChanged,
                    [DASHBOARD_TOAST_INVALID_TOKEN]: ToastInvalidToken,
                    [DASHBOARD_TOAST_API_ERROR]: ToastApiError,
                }}
                maxSnack={3}
                toastComponent={Toast}
            />
            <DashboardRouteProvider>
                <Helmet />
                <Topbar tabs={DASHBOARD_ROUTES} prefix={url} />
                <Box id='main' sx={{
                    display: 'flex',
                    position: 'relative',
                    flexGrow: 1,
                    overflow: 'auto',
                    marginTop: (theme) => theme.mixins.toolbar.minHeight,
                    zIndex: 1,
                }}>
                    <DashboardRoutes url={url} />
                </Box>
            </DashboardRouteProvider>
        </Box>
    );
};
