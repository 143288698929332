import GeoJSON from "ol/format/GeoJSON.js";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Polygon } from "ol/geom";

export const KNOTS_TO_KMH = 1.852; // Knots to km/h
export const KNOTS_TO_MS = 0.514444; // Knots to m/s
export const MPS_TO_KNOTS = 1.94384; // m/s to knots
export const MPS_TO_FTMN = 196.85039; // m/s to feet/min
export const FEET_TO_METERS = 0.3048;
export const METERS_TO_FEET = 1 / 0.3048;
export const NM_TO_M = 1852;
export const M_TO_NM = 1 / NM_TO_M;
export const NM_TO_KM = NM_TO_M / 1000;
export const KM_TO_NM = 1 / NM_TO_KM;
export const EARTH_RADIUS = 6371; // in km

/**
     * Return the distance in km between two points, whose coordinates are given in degrees (EPSG:4326).
     *
     * @param {Number} lon1 longitude of first point
     * @param {Number} lat1 latitude of first point
     * @param {Number} lon2 longitude of 2nd point
     * @param {Number} lat2 latitude of 2nd point
     * @return distance between point in km.
     */
export function distance([lon1, lat1]: number[], [lon2, lat2]: number[]) {
    const [delta_x, y1, y2] = Array.from(
        [lon2 - lon1, lat1, lat2].map(x => (x * Math.PI) / 180)
    );
    return (
        Math.acos(
            Math.sin(y1) * Math.sin(y2) +
            Math.cos(y1) * Math.cos(y2) * Math.cos(delta_x)
        ) * EARTH_RADIUS
    );
};

/**
     * Return the size of the rectangle displayed on the screen, in km.
     *
     * @param {ol.Map} a map
     * @return {Array} approximate displayed number of km, horizontally and vertically.
     */
export function screen_geographic_size(screen_extent: number[]) {
    if (screen_extent) {
        const [xmin, ymin, xmax, ymax] = screen_extent;
        const nw = [xmin, ymin];
        const se = [xmax, ymax];
        const ne = [xmax, ymin];
        const width = distance(nw, se);
        const height = distance(ne, se);

        return [width, height];
    } else {
        return null;
    }
};

/**
     * Round a number so that it only keeps one significant digit, e.g. 1234 is rounded to 1000
     *
     * @param {Number} n number to round
     * @return {Number} rounded number
     */
export function one_significant_digit(n: number) {
    const n_digits = String(Math.round(n)).length;
    const power = 10 ** (n_digits - 1);
    return Math.round(n / power) * power;
};

/**
    * Create a geojson point from coordinates in standard lon/lat format
    *
    * @param {*} longitude
    * @param {*} latitude
    * @return {*} a geojson linestring Feature
    */
export function createGeojsonPoint(longitude: number, latitude: number) {
    const point = new Feature({
        geometry: new Point([longitude, latitude])
    });

    return new GeoJSON().writeFeatureObject(point);
};

/**
     * Shift a position by a given angle and distance
     */
export function shift_point(coordinates: number[], angle_degrees: number, distance_meters: number) {
    const pi180 = Math.PI / 180;
    const [λ1, φ1] = coordinates.map(n => n * pi180);
    const dR = distance_meters / (1000 * EARTH_RADIUS);
    const brng = angle_degrees * pi180;

    // Geometry from https://www.movable-type.co.uk/scripts/latlong.html
    const φ2 = Math.asin(
        Math.sin(φ1) * Math.cos(dR) + Math.cos(φ1) * Math.sin(dR) * Math.cos(brng)
    );
    const λ2 =
        λ1 +
        Math.atan2(
            Math.sin(brng) * Math.sin(dR) * Math.cos(φ1),
            Math.cos(dR) - Math.sin(φ1) * Math.sin(φ2)
        );
    return [λ2, φ2].map(n => n / pi180);
};

/**
     * Create a geojson circle from a center in standard lat/lon coordinates and a radius
     *
     * @param {*} center
     * @param {*} diameter in meters
     * @return {*} a geojson circle
     */
export function createGeojsonCircle(center: number[], diameter: number) {
    const n = 32;
    const coordinates = Array(n + 1)
        .fill(null)
        .map((_, i) => (360 * i) / n) // angles in degrees
        .map(angle => shift_point(center, angle, diameter / 2));
    const circleFeature = new Feature({
        geometry: new Polygon([coordinates])
    });
    return new GeoJSON().writeFeatureObject(circleFeature, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:4326"
    });
};

export function createTrackingCircles(screen_extent: number[], center: number[]) {
    let circleFeatures = [];
    const numCircles = 3;
    const circle_ratio = 0.1;
    const screen_size_km = screen_geographic_size(screen_extent);
    if (screen_size_km) {
        const min_size_km = Math.min(screen_size_km[0], screen_size_km[1]);
        const diameter_nm = one_significant_digit(
            min_size_km * circle_ratio * KM_TO_NM * 2
        );
        if (diameter_nm > 0) {
            const diameter_m = diameter_nm * NM_TO_KM * 1000;
            for (let i = numCircles; i > 0; --i) {
                let geojsonCircle = createGeojsonCircle(center, i * diameter_m);
                geojsonCircle["properties"] = {
                    distance: (i * diameter_nm) / 2
                };
                circleFeatures.push(geojsonCircle);
            }
        }
        return circleFeatures;
    } else {
        return null;
    }
};