import React, { useState, useEffect, createContext, useContext } from 'react';
import { DASHBOARD_ROUTES } from '../../config/routes';
import { useLocation } from "react-router-dom";

const RouteContext = createContext();

function findActiveTab(routes, path) {

    const activeTab = routes.find((r) => {
        return path.includes(r.path);
    });

    if (activeTab) return activeTab.id;
    return null;
};

export function DashboardRouteProvider(props) {

    const [activeTab, setActiveTab] = useState(null);
    let location = useLocation();

    useEffect(() => {
        const active = findActiveTab(DASHBOARD_ROUTES, location.pathname);
        setActiveTab(active);
    }, [location.pathname]);

    return (
        <RouteContext.Provider value={{ activeTab, routes: DASHBOARD_ROUTES }}>
            {props.children}
        </RouteContext.Provider>
    );
};

export const useRoute = () => useContext(RouteContext);
