import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const APP_ID = uuidv4();

export const GROUND_PLID = "PLNT"; // TODO: To be retrieved from backend

export const MQTT_URL = `${window.location.protocol.includes("https") ? "wss" : "ws"}://${window.location.hostname}:${window.location.port}/mqtt`;

export const TELEMETRY_GAP = 120; // duration in seconds after which we consider a gap (for either track or plots)

export const AIRCRAFT_KIND = "ACF";
export const UAV_KIND = "UAV";
export const BALLOON_KIND = "BLN";
export const AIRDROPPED_LOAD_KIND = "ADL";
export const TRACKER_KIND = "TRK";
export const ROTORCRAFT_KIND = "RCF";

export const CODECS = ['json', 'v1'];

export interface VehicleKindType {
  name: string;
  icon: {
    svg_string: (fill: string, stroke: string) => string,
    svg_jsx: (fill: string, stroke: string) => React.ReactNode,
    viewbox: string
  };
};

export const VEHICLE_KINDS: {
  [key: string]: VehicleKindType
} = {
  [AIRCRAFT_KIND]: {
    name: "Aircraft",
    icon: {
      svg_string: (fill: string, stroke: string) => {
        return (`<svg xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" x="0px" y="0px" width="305px" height="313px" viewBox="0 0 305 313">
                  <g fill="${fill}" stroke="${stroke}">
                    <path style="stroke-width:15" d="m 135.48136,23.698782 c 0.0388,-22.063854 33.29588,-22.063854 33.27358,0.622062 v 92.595316 l 129.63036,77.91192 v 34.20959 L 169.37604,186.57752 v 69.20595 l 29.85612,23.32445 v 27.05586 l -46.02587,-14.30551 -46.02587,14.30551 v -27.05586 l 29.5441,-23.32445 V 186.57752 L 7.7017037,229.03767 V 194.82808 L 135.48136,116.91616 Z"/>
                  </g>
                </svg>`);
      },
      svg_jsx: (fill: string, stroke: string) => {
        return (
          <g fill={fill} stroke={stroke}>
            <path strokeWidth="15" d="m 135.48136,23.698782 c 0.0388,-22.063854 33.29588,-22.063854 33.27358,0.622062 v 92.595316 l 129.63036,77.91192 v 34.20959 L 169.37604,186.57752 v 69.20595 l 29.85612,23.32445 v 27.05586 l -46.02587,-14.30551 -46.02587,14.30551 v -27.05586 l 29.5441,-23.32445 V 186.57752 L 7.7017037,229.03767 V 194.82808 L 135.48136,116.91616 Z" />
          </g>);
      },
      viewbox: "0 0 305 313"
    }
  },
  [UAV_KIND]: {
    name: "UAV",
    icon: {
      svg_string: (fill: string, stroke: string) => {
        return (`<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="0 0 300 300">
                  <g fill="${fill}" stroke="${stroke}" transform="matrix(0.0570694,-6.1560904,3.971324,0.08846531,6.8527545,147.22633)">
                    <path style="stroke-width:1.5" d="m 14.252153,31.374128 c -3.34159,0 -5.7718363,0.987287 -10.2526047,0.987287 h -0.835397 l -1.139179,-5.771837 v 0 l -0.379726,-1.670794 v 0 L -2.4557957,2.7427789 c -0.455671,-1.518905 -3.873206,-0.531617 -3.873206,-0.151891 l 1.74674,22.4038411 v 0 0 l 0.167051,1.685284 c 0.01498,0.15115 0,0 0,0 l -0.01516,-0.01449 v 0 0 l 0.379726,5.164275 -1.518904,0.531617 c -0.531617,0.07594 -0.987288,0.303781 -0.987288,0.835397 v 0.227836 l -11.6955643,0.531616 -2.430247,-10.02477 c -0.07595,-0.379726 -0.379726,-0.531616 -0.683507,-0.531616 h -2.050521 c -0.455672,0 -0.835398,0.455671 -0.759452,0.835397 l 1.215123,11.9234 -1.139178,11.847455 c -0.07595,0.531616 0.303781,0.835397 0.683507,0.835397 l 2.050521,0.07595 c 0.303781,0 0.607562,-0.30378 0.683507,-0.531616 l 2.430247,-9.872879 11.7715103,0.531616 v 0.07595 c 0,0.455671 0.379726,0.759452 0.987288,0.835397 l 1.518904,0.379726 -0.455671,5.392111 v 0 0 0 l -0.07595,1.670795 v 0 0 l -1.74674,22.176005 c 0,0.455671 3.417535,1.442959 3.797261,-0.07595 l 4.176987,-22.10006 v 0 l 0.379726,-1.974576 v 0 l 1.139178,-6.151563 h 0.835398 c 4.4807684,0.759452 6.9110147,1.367014 10.2526047,1.367014 5.544001,0 9.872879,-1.59485 9.872879,-4.632658 C 24.125034,32.96898 19.796151,31.37413 14.25215,31.37413 Z m -17.1636197,1.974575 c -0.227836,-0.455671 0.455671,-0.455671 0.531616,0 0.683507,1.74674 0.607562,3.417535 0,5.012385 -0.07595,0.455671 -0.683507,0.455671 -0.531616,0 0.531616,-1.59485 0.607562,-3.189699 0,-5.012385 z m 24.0746347,5.468056 c -4.101042,1.139179 -8.353974,1.139179 -12.4550153,0 -0.303781,-0.07595 -0.303781,-0.531616 0,-0.531616 4.1010413,1.139178 8.2780283,1.139178 12.4550153,0 0.531617,-0.07595 0.607562,0.303781 0,0.531616 z"/>
                  </g>
              </svg>`);
      },
      svg_jsx: (fill: string, stroke: string) => {
        return (
          <g fill={fill} stroke={stroke} transform="matrix(0.0570694,-6.1560904,3.971324,0.08846531,6.8527545,147.22633)">
            <path strokeWidth="1.5" d="m 14.252153,31.374128 c -3.34159,0 -5.7718363,0.987287 -10.2526047,0.987287 h -0.835397 l -1.139179,-5.771837 v 0 l -0.379726,-1.670794 v 0 L -2.4557957,2.7427789 c -0.455671,-1.518905 -3.873206,-0.531617 -3.873206,-0.151891 l 1.74674,22.4038411 v 0 0 l 0.167051,1.685284 c 0.01498,0.15115 0,0 0,0 l -0.01516,-0.01449 v 0 0 l 0.379726,5.164275 -1.518904,0.531617 c -0.531617,0.07594 -0.987288,0.303781 -0.987288,0.835397 v 0.227836 l -11.6955643,0.531616 -2.430247,-10.02477 c -0.07595,-0.379726 -0.379726,-0.531616 -0.683507,-0.531616 h -2.050521 c -0.455672,0 -0.835398,0.455671 -0.759452,0.835397 l 1.215123,11.9234 -1.139178,11.847455 c -0.07595,0.531616 0.303781,0.835397 0.683507,0.835397 l 2.050521,0.07595 c 0.303781,0 0.607562,-0.30378 0.683507,-0.531616 l 2.430247,-9.872879 11.7715103,0.531616 v 0.07595 c 0,0.455671 0.379726,0.759452 0.987288,0.835397 l 1.518904,0.379726 -0.455671,5.392111 v 0 0 0 l -0.07595,1.670795 v 0 0 l -1.74674,22.176005 c 0,0.455671 3.417535,1.442959 3.797261,-0.07595 l 4.176987,-22.10006 v 0 l 0.379726,-1.974576 v 0 l 1.139178,-6.151563 h 0.835398 c 4.4807684,0.759452 6.9110147,1.367014 10.2526047,1.367014 5.544001,0 9.872879,-1.59485 9.872879,-4.632658 C 24.125034,32.96898 19.796151,31.37413 14.25215,31.37413 Z m -17.1636197,1.974575 c -0.227836,-0.455671 0.455671,-0.455671 0.531616,0 0.683507,1.74674 0.607562,3.417535 0,5.012385 -0.07595,0.455671 -0.683507,0.455671 -0.531616,0 0.531616,-1.59485 0.607562,-3.189699 0,-5.012385 z m 24.0746347,5.468056 c -4.101042,1.139179 -8.353974,1.139179 -12.4550153,0 -0.303781,-0.07595 -0.303781,-0.531616 0,-0.531616 4.1010413,1.139178 8.2780283,1.139178 12.4550153,0 0.531617,-0.07595 0.607562,0.303781 0,0.531616 z" />
          </g>);
      },
      viewbox: "0 0 300 300"
    }
  },
  [BALLOON_KIND]: {
    name: "Balloon",
    icon: {
      svg_string: (fill: string, stroke: string) => {
        return (`<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="0 0 300.00001 300.00001">
                  <g fill="${fill}" transform="matrix(0.51976479,0,0,0.51976479,47.659026,70.801012)">
                    <path d="m 213.333,106.667 c -58.88,0 -106.667,47.787 -106.667,106.667 0,58.88 47.787,106.666 106.667,106.666 C 272.213,320 320,272.213 320,213.333 320,154.453 272.213,106.667 213.333,106.667 Z"/>
                    <path fill="${stroke}" d="M 213.333,0 C 95.467,0 0,95.467 0,213.333 0,331.199 95.467,426.666 213.333,426.666 331.199,426.666 426.667,331.2 426.667,213.333 426.667,95.466 331.2,0 213.333,0 Z m 0,384 C 119.04,384 42.666,307.627 42.666,213.333 42.666,119.039 119.04,42.667 213.333,42.667 307.626,42.667 384,119.04 384,213.333 384,307.626 307.627,384 213.333,384 Z"/>
                  </g>
                <path fill="${stroke}" d="M 93.811261,64.934944 C 149.00205,33.775653 214.51123,62.313881 214.51123,62.313881 L 175.66459,22.540054 163.06857,11.204909 C 150.41419,-0.1423191 150.68454,2.664615 150.68454,2.664615 Z"/>
              </svg>`);
      },
      svg_jsx: (fill: string, stroke: string) => {
        return (
          <>
            <g fill={fill} transform="matrix(0.51976479,0,0,0.51976479,47.659026,70.801012)">
              <path d="m 213.333,106.667 c -58.88,0 -106.667,47.787 -106.667,106.667 0,58.88 47.787,106.666 106.667,106.666 C 272.213,320 320,272.213 320,213.333 320,154.453 272.213,106.667 213.333,106.667 Z" />
              <path fill={stroke} d="M 213.333,0 C 95.467,0 0,95.467 0,213.333 0,331.199 95.467,426.666 213.333,426.666 331.199,426.666 426.667,331.2 426.667,213.333 426.667,95.466 331.2,0 213.333,0 Z m 0,384 C 119.04,384 42.666,307.627 42.666,213.333 42.666,119.039 119.04,42.667 213.333,42.667 307.626,42.667 384,119.04 384,213.333 384,307.626 307.627,384 213.333,384 Z" />
            </g>
            <path fill={stroke} d="M 93.811261,64.934944 C 149.00205,33.775653 214.51123,62.313881 214.51123,62.313881 L 175.66459,22.540054 163.06857,11.204909 C 150.41419,-0.1423191 150.68454,2.664615 150.68454,2.664615 Z" />
          </>);
      },
      viewbox: "0 0 300.00001 300.00001"
    }
  },
  [AIRDROPPED_LOAD_KIND]: {
    name: "Airdropped Load",
    icon: {
      svg_string: (fill: string, stroke: string) => {
        return (`<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="0 0 300.00001 300.00001">
                  <g fill="${fill}" transform="matrix(0.51976479,0,0,0.51976479,47.659026,70.801012)">
                    <path d="m 213.333,106.667 c -58.88,0 -106.667,47.787 -106.667,106.667 0,58.88 47.787,106.666 106.667,106.666 C 272.213,320 320,272.213 320,213.333 320,154.453 272.213,106.667 213.333,106.667 Z"/>
                    <path fill="${stroke}" d="M 213.333,0 C 95.467,0 0,95.467 0,213.333 0,331.199 95.467,426.666 213.333,426.666 331.199,426.666 426.667,331.2 426.667,213.333 426.667,95.466 331.2,0 213.333,0 Z m 0,384 C 119.04,384 42.666,307.627 42.666,213.333 42.666,119.039 119.04,42.667 213.333,42.667 307.626,42.667 384,119.04 384,213.333 384,307.626 307.627,384 213.333,384 Z"/>
                  </g>
                <path fill="${stroke}" d="M 93.811261,64.934944 C 149.00205,33.775653 214.51123,62.313881 214.51123,62.313881 L 175.66459,22.540054 163.06857,11.204909 C 150.41419,-0.1423191 150.68454,2.664615 150.68454,2.664615 Z"/>
              </svg>`);
      },
      svg_jsx: (fill: string, stroke: string) => {
        return (
          <>
            <g fill={fill} transform="matrix(0.51976479,0,0,0.51976479,47.659026,70.801012)">
              <path d="m 213.333,106.667 c -58.88,0 -106.667,47.787 -106.667,106.667 0,58.88 47.787,106.666 106.667,106.666 C 272.213,320 320,272.213 320,213.333 320,154.453 272.213,106.667 213.333,106.667 Z" />
              <path fill={stroke} d="M 213.333,0 C 95.467,0 0,95.467 0,213.333 0,331.199 95.467,426.666 213.333,426.666 331.199,426.666 426.667,331.2 426.667,213.333 426.667,95.466 331.2,0 213.333,0 Z m 0,384 C 119.04,384 42.666,307.627 42.666,213.333 42.666,119.039 119.04,42.667 213.333,42.667 307.626,42.667 384,119.04 384,213.333 384,307.626 307.627,384 213.333,384 Z" />
            </g>
            <path fill={stroke} d="M 93.811261,64.934944 C 149.00205,33.775653 214.51123,62.313881 214.51123,62.313881 L 175.66459,22.540054 163.06857,11.204909 C 150.41419,-0.1423191 150.68454,2.664615 150.68454,2.664615 Z" />
          </>);
      },
      viewbox: "0 0 300.00001 300.00001"
    }
  },
  [TRACKER_KIND]: {
    name: "Tracker",
    icon: {
      svg_string: (fill: string, stroke: string) => {
        return (`<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300" height="300" viewBox="0 0 300 300">
                    <g fill="${fill}" stroke="${stroke}">
                    <path style="stroke-width:15" d="M234.69409,219.61572a15.86649,15.86649,0,0,1-12.15625,5.69141,16.171,16.171,0,0,1-5.44531-.95117l-81.21094-29.00391V120a8,8,0,1,0-16,0v75.352L38.67065,224.356a16.00042,16.00042,0,0,1-19.3418-22.88575l94.5918-168.91455a16.00119,16.00119,0,0,1,27.92188,0l94.59179,168.915A15.87045,15.87045,0,0,1,234.69409,219.61572Z"/>
                    </g>
                </svg>`);
      },
      svg_jsx: (fill: string, stroke: string) => {
        return (
          <g fill={fill} stroke={stroke}>
            <path d="M234.69409,219.61572a15.86649,15.86649,0,0,1-12.15625,5.69141,16.171,16.171,0,0,1-5.44531-.95117l-81.21094-29.00391V120a8,8,0,1,0-16,0v75.352L38.67065,224.356a16.00042,16.00042,0,0,1-19.3418-22.88575l94.5918-168.91455a16.00119,16.00119,0,0,1,27.92188,0l94.59179,168.915A15.87045,15.87045,0,0,1,234.69409,219.61572Z" />
          </g>);
      },
      viewbox: "0 0 300 300"
    }
  },
  [ROTORCRAFT_KIND]: {
    name: "Rotorcraft",
    icon: {
      svg_string: (fill: string, stroke: string) => {
        return (`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="314px" height="314px" viewBox="0 0 314.478 314.478">
                  <g fill="${fill}" stroke="${stroke}">
                    <path style="stroke-width:12" d="M268.783,2.995c-3.107-3.106-8.348-2.899-11.708,0.461L204.854,55.68C198.547,23.335,180.822,0,159.893,0
                      c-21.888,0-40.268,25.53-45.739,60.198L57.407,3.451c-3.36-3.361-8.601-3.568-11.708-0.461c-3.107,3.106-2.9,8.352,0.456,11.713
                      l66.309,66.306c0,0.202-0.018,0.404-0.018,0.605c0,20.902,4.608,39.911,12.122,54.35l-78.413,78.41
                      c-3.361,3.355-3.568,8.596-0.461,11.702c3.107,3.107,8.347,2.9,11.708-0.455l76.142-76.147c3.221,3.703,6.729,6.678,10.437,8.945
                      v105.82l-47.722,32.192v18.046l50.197-22.308h26.88l50.186,22.308v-18.041L175.8,264.244v-105.82
                      c2.744-1.68,5.369-3.759,7.845-6.235l73.431,73.432c3.36,3.365,8.601,3.572,11.708,0.466c3.106-3.107,2.899-8.348-0.461-11.713
                      l-74.943-74.948c8.622-14.776,13.96-35.219,13.96-57.812c0-1.946-0.088-3.85-0.17-5.758l61.158-61.153
                      C271.683,11.342,271.89,6.097,268.783,2.995z"/>
                  </g>
                </svg>`);
      },
      svg_jsx: (fill: string, stroke: string) => {
        return (
          <g fill={fill} stroke={stroke}>
            <path strokeWidth="10" d="M268.783,2.995c-3.107-3.106-8.348-2.899-11.708,0.461L204.854,55.68C198.547,23.335,180.822,0,159.893,0
                  c-21.888,0-40.268,25.53-45.739,60.198L57.407,3.451c-3.36-3.361-8.601-3.568-11.708-0.461c-3.107,3.106-2.9,8.352,0.456,11.713
                  l66.309,66.306c0,0.202-0.018,0.404-0.018,0.605c0,20.902,4.608,39.911,12.122,54.35l-78.413,78.41
                  c-3.361,3.355-3.568,8.596-0.461,11.702c3.107,3.107,8.347,2.9,11.708-0.455l76.142-76.147c3.221,3.703,6.729,6.678,10.437,8.945
                  v105.82l-47.722,32.192v18.046l50.197-22.308h26.88l50.186,22.308v-18.041L175.8,264.244v-105.82
                  c2.744-1.68,5.369-3.759,7.845-6.235l73.431,73.432c3.36,3.365,8.601,3.572,11.708,0.466c3.106-3.107,2.899-8.348-0.461-11.713
                  l-74.943-74.948c8.622-14.776,13.96-35.219,13.96-57.812c0-1.946-0.088-3.85-0.17-5.758l61.158-61.153
                  C271.683,11.342,271.89,6.097,268.783,2.995z"
            />
          </g>
        )
      },
      viewbox: "0 0 314.478 314.478"
    }
  }
};

export const IWG1_FIELDS = [
  { name: "gps_msl_alt", unit: "m" },
  { name: "wgs84_alt", unit: "m" },
  { name: "press_alt", unit: "feet" },
  { name: "radar_alt", unit: "feet" },
  { name: "ground_speed", unit: "m/s" },
  { name: "true_airspeed", unit: "m/s" },
  { name: "indicated_airspeed", unit: "knots" },
  { name: "mach_number" },
  { name: "vert_velocity", unit: "m/s" },
  { name: "true_heading", unit: "degrees" },
  { name: "track", unit: "degrees" },
  { name: "drift", unit: "degrees" },
  { name: "pitch", unit: "degrees" },
  { name: "roll", unit: "degrees" },
  { name: "side_slip", unit: "degrees" },
  { name: "angle_of_attack", unit: "degrees" },
  { name: "ambient_temp", unit: "degrees_C" },
  { name: "dew_point", unit: "degrees_C" },
  { name: "total_temp", unit: "degrees_C" },
  { name: "static_press", unit: "mbar" },
  { name: "dynamic_press", unit: "mbar" },
  { name: "cabin_press", unit: "mbar" },
  { name: "wind_speed", unit: "m/s" },
  { name: "wind_dir", unit: "degrees" },
  { name: "vert_wind_speed", unit: "m/s" },
  { name: "solar_zenith", unit: "degrees" },
  { name: "sun_elev_ac", unit: "degrees" },
  { name: "sun_az_grd", unit: "degrees" },
  { name: "sun_az_ac", unit: "degrees" }
];