import { createSlice } from '@reduxjs/toolkit';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { RootState } from '../../app/store';
import { WEATHER_DATA_HISTORY_LENGTH } from '../../config/weather';

export interface WeatherProductType {
    type: string;
    visible: boolean;
    zIndex: number;
    error: string | null;
    data: any[];
    dataIndex: number;
    loading: boolean;
    progress: number;
    available: boolean;
    opacity: number;
    info?: {
        source: string;
        refresh_rate: string | number;
        palette?: {
            unit: string;
            img: string;
            width: string | number;
            height: string | number;
        }
    }
};

export interface WeatherState {
    [key: string]: WeatherProductType;
};

function mod(n: number, m: number) {
    return ((n % m) + m) % m;
}; //https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers


const initialState: WeatherState = {};

const weatherSlice = createSlice({
    name: 'weather',
    initialState,
    reducers: {
        add_product: ((state, action) => {
            state[action.payload.id] = action.payload.product;
        }),
        get_product: ((state, action) => {
            state[action.payload.id].loading = true;
            state[action.payload.id].error = null;
        }),
        get_product_success: ((state, action) => {
            state[action.payload.id].loading = !action.payload.available;
            state[action.payload.id].error = null;
        }),
        get_product_failure: ((state, action) => {
            state[action.payload.id].loading = false;
            state[action.payload.id].error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        product_progress: ((state, action) => {
            if (!(action.payload.product_id in state)) return;
            state[action.payload.product_id].progress = action.payload.progress;
        }),
        product_response: ((state, action) => {
            if (!(action.payload.product_id in state)) return;
            state[action.payload.product_id].loading = !action.payload.available;
            if (action.payload.status === "error") {
                state[action.payload.product_id].error = action.payload.message;
            } else {
                state[action.payload.product_id].error = null;
            };
        }),
        product_available: ((state, action) => {
            if (!(action.payload.product_id in state)) return;
            if (action.payload.payloads.length > 0) {
                const product_url = `${window.location.protocol}//${window.location.host}/transfer/hash/${action.payload.payloads[0].split('/').pop()}`;
                const index = state[action.payload.product_id].data.findIndex(d => d?.url === product_url);
                if (index === -1) {
                    state[action.payload.product_id].data.unshift({
                        extent: action.payload.bbox,
                        dates: action.payload.dates,
                        url: product_url,
                        projection: action.payload.proj
                    });
                    state[action.payload.product_id].data.pop();
                    state[action.payload.product_id].available = true;
                    state[action.payload.product_id].loading = false;
                } else { // If URL already exists (this happens if the content of the new file is the same, for instance empty lightning file), we only update other metadata.
                    if (state[action.payload.product_id].data[index]) {
                        state[action.payload.product_id].data[index].extent = action.payload.bbox;
                        state[action.payload.product_id].data[index].dates = action.payload.dates;
                        state[action.payload.product_id].data[index].projection = action.payload.proj;
                    };
                };
            };
        }),
        product_click: ((state, action) => { }),
        set_visibility: ((state, action) => {
            state[action.payload.id].visible = action.payload.visible;
        }),
        set_opacity: ((state, action) => {
            state[action.payload.id].opacity = action.payload.opacity;
        }),
        move_up: ((state, action) => {
            const currentIdx = state[action.payload.id].zIndex;
            if (!(currentIdx >= Object.keys(state).length)) { // if product_id is not already on top of the list
                const moveDownProductId = Object.keys(state).filter((key) => state[key].zIndex === currentIdx + 1)[0];
                state[action.payload.id].zIndex = currentIdx + 1;
                state[moveDownProductId].zIndex = currentIdx;
            }
        }),
        move_down: ((state, action) => {
            const currentIdx = state[action.payload.id].zIndex;
            if (!(currentIdx <= 1)) { // if product_id is not already on bottom of the list
                const moveUpProductId = Object.keys(state).filter((key) => state[key].zIndex === currentIdx - 1)[0];
                state[action.payload.id].zIndex = currentIdx - 1;
                state[moveUpProductId].zIndex = currentIdx;
            }
        }),
        previous: ((state, action) => {
            const newIndex = mod((state[action.payload.id].dataIndex - 1), WEATHER_DATA_HISTORY_LENGTH);
            if (state[action.payload.id].data?.[newIndex]) state[action.payload.id].dataIndex = newIndex;
        }),
        next: ((state, action) => {
            const newIndex = mod((state[action.payload.id].dataIndex - 1), WEATHER_DATA_HISTORY_LENGTH);
            if (state[action.payload.id].data?.[newIndex]) state[action.payload.id].dataIndex = newIndex;
        }),
        unwind: ((state, action) => {
            for (let i = 1; i <= WEATHER_DATA_HISTORY_LENGTH; i++) {
                let newIndex = mod((state[action.payload.id].dataIndex - i), WEATHER_DATA_HISTORY_LENGTH);
                if (state[action.payload.id].data?.[newIndex]) {
                    state[action.payload.id].dataIndex = newIndex;
                    break;
                }
            };
        }),
        reset_index: ((state, action) => {
            state[action.payload.id].dataIndex = 0;
        }),
        refresh: ((state, action) => {

        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.stop, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
    }
});

export const selectWeather = (state: RootState) => state.nav.weather;

export const weatherActions = weatherSlice.actions;

export default weatherSlice.reducer;