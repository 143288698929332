import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RootState } from "../../../app/store";

export default function MqttStatus() {
    const isMqttConnected = useSelector((state: RootState) => state.mqtt.isConnected);

    return (
        <Box
            sx={{
                zIndex: 'inherit',
                display: isMqttConnected ? "none" : "flex",
                backgroundColor: (theme) => isMqttConnected ? "none" : theme.palette.error.main,
                color: "yellow"
            }}
            justifyContent="center"
        >
            <Typography variant="h6" style={{ fontWeight: "bold" }}>You are disconnected. Retrying connection...</Typography>
        </Box>
    );
}