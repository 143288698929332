import { call, put, all, select, fork, cancel, take, takeEvery, takeLatest } from "redux-saga/effects";
import { RootState } from "../../app/store";
import merge from "deepmerge";
import { computeModules, moduleKeyType } from "../../utils/computeModules/Modules";
import { TELEMETRY_API } from './telemetryApi';
import { toDate, subtractSecondsToDate } from '../../utils/TimeUtils';
import { DEFAULT_TELEMETRY_TAB_ID } from "../../config/telemetry";
import { TabType, ComputeModuleType, telemetryActions } from "./telemetrySlice";
import { OriginType, mapActions } from "../map/mapSlice";
import { trackingActions } from "../tracking/trackingSlice";
import { missionActions } from '../mission/missionSlice';
import { replayActions } from "../replay/replaySlice";
import { mqttActions } from "../mqtt/mqttSlice";
import { toastActions } from "../toast/toastSlice";
import { DASHBOARD_TOAST_CONTAINER_ID, DASHBOARD_TOAST_INFO_ID } from "../../config/toast";

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getTelemetryTabsFromLs(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const tabs = JSON.parse(localStorage.getItem("_telemetry_tabs") || "[]");
    yield put(telemetryActions.set_tabs({ tabs }))
};

function* watchSetActiveMission() {
    yield takeLatest([missionActions.set_active_mission, replayActions.stop], getTelemetryTabsFromLs);
};

function* getLabelsProcess(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const duration = 6; /* history duration in hours */
        let res: any;
        if (action.payload.replay) {
            res = yield call(TELEMETRY_API.getLabelsWithDates, token, missionId, action.payload.plid, action.payload.from, action.payload.to);
        } else {
            res = yield call(TELEMETRY_API.getLabels, token, missionId, action.payload.plid, `${duration}h`);
        };
        yield all(res.data.map((obs: any) => put(telemetryActions.add_label({
            plid: action.payload.plid,
            label: { label: obs.label, is_source: obs.source === obs.label },
            replay: action.payload.replay,
            from: action.payload.from || subtractSecondsToDate(obs.created, duration * 3600),
            to: action.payload.to || obs.created
        }))));

        yield put(telemetryActions.get_labels_success);

        const source: any = yield select((state: RootState) => state.nav.tracking[action.payload.plid]?.source);
        if (!source || source === "FILE") { // tracker's source will still be registered as 'FILE' after stoping a replay from a file, so it must be re-evaluated
            yield put(trackingActions.set_tracker_source({ plid: action.payload.plid, source: res.data.find((obs: any) => obs.source === obs.label)?.label || null }));
        }

    } catch (error: any) {
        console.log("Error in 'getLabels' saga", error);
        yield put(telemetryActions.get_labels_fail({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    }
};

let ongoingLabelCalls: any[] = [];

function* getLabels(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    if (action.type === "telemetry/get_labels") {
        const process: any = yield fork(getLabelsProcess, action);
        ongoingLabelCalls.push(process);
        yield take((success: any) =>
            (success.type === "telemetry/get_labels_success" || success.type === "telemetry/get_labels_failure")
            && success.payload.plid === action.payload.plid
        );
        const processIndex = ongoingLabelCalls.indexOf(process);
        if (processIndex >= 0) ongoingLabelCalls.splice(processIndex, 1);
    } else {
        yield all(ongoingLabelCalls.map((call: any) => cancel(call)));
        ongoingLabelCalls = [];
    }
};

function* watchGetLabels() { // Triggers a 'get_labels' DB call
    yield takeEvery(telemetryActions.get_labels, getLabels);
};

function* cancelLabelCalls() { // Cancels ongoing 'get_labels' DB calls
    yield takeEvery([missionActions.set_active_mission, replayActions.stop, replayActions.start], getLabels);
};

function* getHistoryProcess(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const token: string = yield select((state: RootState) => state.auth.token);
    const missionId: string = yield select((state: RootState) => state.mission.active);
    const label: string = action.payload.label.label;

    try {
        let res: any;
        res = yield call(TELEMETRY_API.getHistoryWithDates, token, missionId, action.payload.plid, action.payload.from, action.payload.to, label);
        const is_source = res.data[0]?.source === label;
        const telemetry_config = yield select((state: RootState) => state.mission.allowed?.[missionId]?.config?.telemetry);
        const stream_config = (merge(telemetry_config?.default?.streams || {}, telemetry_config?.[action.payload.plid]?.streams || {}) as any)[label] || {};
        let timestamps: number[] = [];
        let values: any[] = [];
        res.data.forEach((obs: any) => {
            const ts = toDate(obs.created).getTime() / 1000;
            timestamps.push(ts);
            const entries = Object.entries(obs.data).map(([key, value]: [string, any]) => {
                return ([stream_config[key]?.name || key, value]);
            });
            const data = Object.fromEntries(entries);
            values.push({ position: obs.position, ...data });
        });

        yield put(telemetryActions.get_history_success({ plid: action.payload.plid, label: label, data: [timestamps, values], is_source }));

        if (is_source) {
            const source: string | null = yield select((state: RootState) => state.nav.tracking[action.payload.plid]?.source);
            if (!source) {
                yield put(trackingActions.set_tracker_source({ plid: action.payload.plid, source: label }));
            }
        };

    } catch (error: any) {
        console.log("error in 'getHistory' saga", error);
        yield put(telemetryActions.get_history_fail({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    }
};

let ongoingHistoryCalls: any[] = [];

function* getHistory(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    if (action.type === "telemetry/add_label") {
        const process: any = yield fork(getHistoryProcess, action);
        ongoingHistoryCalls.push(process);
        yield take((success: any) =>
            (success.type === "telemetry/get_history_success" || success.type === "telemetry/get_history_failure")
            && success.payload.plid === action.payload.plid
        );
        const processIndex = ongoingHistoryCalls.indexOf(process);
        if (processIndex >= 0) ongoingHistoryCalls.splice(processIndex, 1);
    } else {
        yield all(ongoingHistoryCalls.map((call: any) => cancel(call)));
        ongoingHistoryCalls = [];
    }
};

function* watchAddLabel() { // Triggers a telemetry history DB call
    yield takeEvery(telemetryActions.add_label, getHistory);
};

function* cancelHistoryCalls() { // Cancels ongoing telemetry history DB calls
    yield takeEvery([missionActions.set_active_mission, replayActions.stop, replayActions.start], getHistory);
};

function* initComputed(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const missionId: string = yield select((state: RootState) => state.mission.active);
    const origin: OriginType = yield select((state: RootState) => state.map.localOrigin);
    const toCompute: any = [];
    const toToast: any = [];

    if (action.type === telemetryActions.get_history_success.type) {
        const telemetry_config = yield select((state: RootState) => state.mission.allowed?.[missionId]?.config?.telemetry);
        //const compute_config: any = merge(telemetry_config?.default?.doCompute || {}, telemetry_config?.[action.payload.plid]?.doCompute || {});
        // ^ merging with arrays seems prone to unintuitive behaviours...
        const compute_config: any = telemetry_config?.[action.payload.plid]?.doCompute || telemetry_config?.default?.doCompute || null;
        // 'config' will be an array if multiple 'doCompute' modules are configured
        if (compute_config !== null) {
            const configArray: { module: string, input: { label?: string, backups?: string[], params: { [internalName: string]: string } | undefined }, output: { label: string } }[] = Array.isArray(compute_config) ? compute_config : [compute_config];

            configArray.forEach(c => {
                if (Object.keys(computeModules).includes(c.module)) {
                    const key = c.module as moduleKeyType;
                    const last = action.payload.data[1]?.slice(-1)?.[0] || {};
                    if (c.input?.label === action.payload.label || action.payload.label === "FILE" || c.input?.backups?.includes(action.payload.label)) {
                        if (computeModules[key].params.every((param: string) => (c.input?.params?.[param] && Object.keys(last).includes(c.input.params[param])) || Object.keys(last).includes(param))) {
                            // ^ checking that at least the last data frame contains each needed parameter
                            // TODO : allow for multi-frame compute modules ?
                            const params = c.input?.params ? c.input.params : {};

                            const history: [number[], any[]] = computeModules[key].init(action.payload.data, params, origin);

                            toCompute.push({
                                plid: action.payload.plid,
                                module: c.module,
                                label: c.output?.label ? c.output.label : `${action.payload.label}-${c.module}`,
                                source: action.payload.label,
                                params,
                                history
                            });
                        } else {
                            // either this stream is not the correct one for the module, or it is missing parameters
                            console.log("missing param for module " + c.module + " in stream " + action.payload.label + ", computed init aborted for this stream");
                        }
                    } else {
                        if (c.input?.label === undefined) {
                            console.error("mission configuration is missing input label for module " + c.module);
                            toToast.push({
                                plid: action.payload.plid,
                                module: c.module,
                            });
                        }
                    }
                } else {
                    console.error("unknown compute module", c.module);
                    console.error("available compute modules are", Object.keys(computeModules));
                }
            });
        }
    } else if (action.type === mapActions.set_origin.type) {
        const modules: [string, ComputeModuleType][] = yield select((state: RootState) => Object.entries(state.telemetry.computed));
        const streams = yield select((state: RootState) => state.telemetry.streams);

        modules.forEach(([plid, mods]) => {
            Object.entries(mods).forEach(([name, module]) => {
                if (Object.keys(computeModules).includes(name)) {
                    const past = streams[plid][module.source];
                    const history: [number[], any[]] = computeModules[name as moduleKeyType].init(past, module.params, origin);

                    toCompute.push({
                        plid,
                        module: name,
                        label: module.label ? module.label : `${module.source}-${name}`,
                        source: module.source,
                        params: module.params,
                        history
                    });
                } else {
                    console.log("unknown compute module", name);
                    console.log("available compute modules are", Object.keys(computeModules));
                }
            });
        });
    }

    yield all(toToast.map((toast: any) => put(toastActions.add_toast({
        containerId: DASHBOARD_TOAST_CONTAINER_ID,
        toast: {
            id: `compute-${toast.plid}-${toast.module}`,
            variant: DASHBOARD_TOAST_INFO_ID,
            severity: "warning",
            autoHideDuration: null,
            message: `Compute configuration for module '${toast.module}' on plid '${toast.plid}' is outdated or incomplete.`,
            vertical: 'bottom',
            horizontal: 'left'
        }
    }))));

    yield all(toCompute.map((c: any) => put(telemetryActions.init_computed(c))));
};

function* watchGetHistorySuccess() {
    yield takeEvery([telemetryActions.get_history_success, mapActions.set_origin], initComputed);
};

function* compute(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    let toCompute: { plid: string, timestamp: number, value: any, label: string }[] = [];
    const plid: string = action.payload.plid;
    const modules: ComputeModuleType = yield select((state: RootState) => state.telemetry.computed[plid]);

    if (modules && Object.keys(modules).length > 0) {
        const origin: OriginType = yield select((state: RootState) => state.map.localOrigin);
        const timestamp = action.payload.timestamp;
        const value = action.payload.value;

        Object.entries(modules).forEach(([name, module]) => {
            if (module.source === action.payload.label) {
                if (Object.keys(computeModules).includes(name)) {
                    const key = name as moduleKeyType;

                    if (computeModules[key].params.every((param: string) => Object.keys(value).includes(param))) {
                        const computed = computeModules[key].compute(value, module.params, origin);
                        toCompute.push({ plid, timestamp, value: computed, label: module.label });
                    }
                    else {
                        console.log("missing param for module " + name + " in stream " + action.payload.label + ", live computation bypassed");
                    }

                } else {
                    console.log("unknown compute module", name);
                    console.log("available compute modules are", Object.keys(computeModules));
                }
            }
        });
    };

    yield all(toCompute.map(computed => put(telemetryActions.add_computed(computed))));
};

function* watchCompute() {
    yield takeEvery(telemetryActions.add_value, compute);
};

function* updateStop(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const label = action.payload.label;
    const stop = action.payload.stop;
    yield put(mqttActions.publish({
        topic: `telemetry/stop/${label}`,
        payload: JSON.stringify({ stop, label }),
        retain: true
    }));
    yield put(telemetryActions.new_stop({ label, stop }));
};

function* watchUpdateStop() {
    yield takeEvery(telemetryActions.update_stop, updateStop);
};

function* seTelemetryTabsLS(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const tabs = yield select((state: RootState) => state.telemetry.tabs);
    localStorage.setItem('_telemetry_tabs', JSON.stringify(tabs.filter((t: TabType) => t.id !== DEFAULT_TELEMETRY_TAB_ID)))
};

function* watchActions() {
    yield takeLatest([
        telemetryActions.add_tab,
        telemetryActions.remove_tab,
        telemetryActions.set_tabs,
        telemetryActions.set_tab_params,
    ], seTelemetryTabsLS);
};

export default function* watchTelemetry() {
    yield all([
        watchSetActiveMission(),
        watchActions(),
        watchGetLabels(),
        cancelLabelCalls(),
        watchAddLabel(),
        cancelHistoryCalls(),
        watchGetHistorySuccess(),
        watchCompute(),
        watchUpdateStop()
    ]);
};