import { trackStyleByAlt, trackStyleByTime, trackStyleNone } from "../components/navigation/tracking/styles/track";
import { Style } from "ol/style";

interface ColorTrackingFunctionsProps {
    [key: string]: {
        name: string,
        styleFunc: (feature: any, resolution: any) => Style[] | null,
        default?: boolean
    }
};

export const COLOR_TRACK_NONE = "COLOR_TRACK_NONE";
export const COLOR_TRACKING_BY_ALT = "COLOR_TRACKING_BY_ALT";
export const COLOR_TRACKING_BY_TIME = "COLOR_TRACKING_BY_TIME";

export const COLOR_TRACKING_FUNCTIONS: ColorTrackingFunctionsProps = {
    [COLOR_TRACK_NONE]: {
        name: 'None',
        styleFunc: trackStyleNone,
        default: true
    },
    [COLOR_TRACKING_BY_ALT]: {
        name: 'Altitude',
        styleFunc: trackStyleByAlt
    },
    [COLOR_TRACKING_BY_TIME]: {
        name: 'Time',
        styleFunc: trackStyleByTime
    }
};