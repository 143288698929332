import { call, put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { missionActions } from '../mission/missionSlice';
import { GEOMARKERS_API } from "./geomarkersApi";
import { RootState } from "../../app/store";
import { geomarkersActions, GeomarkerType } from "./geomarkersSlice";
import { GEOMARKER_DEFAULT_GROUP } from '../../config/geomarker';
import { NAVIGATION_TOAST_CONTAINER_ID, GEOMARKERS_TOAST_EDIT_ID, GEOMARKERS_TOAST_INFO_ID, GEOMARKERS_TOAST_DRAWING_ID } from '../../config/toast';
import { toastActions } from "../toast/toastSlice";
import { replayActions } from "../replay/replaySlice";
import { toDate } from "../../utils/TimeUtils";

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getGeomarkers(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(geomarkersActions.get_geomarkers({}));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(GEOMARKERS_API.getGeomarkers, token, missionId);

        const groups = res.data.reduce((result: any, gm: any) => {
            let groupId = gm.feature.properties?.group || GEOMARKER_DEFAULT_GROUP;
            if (!(groupId in result)) {
                result = {
                    ...result,
                    [groupId]: {
                        visible: true
                    }
                }
            }
            return result;
        }, {});

        yield put(geomarkersActions.get_geomarkers_success({ groups, geomarkers: res.data }));
    } catch (error: any) {
        yield put(geomarkersActions.get_geomarkers_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchSetActiveMission() {
    yield takeLatest([missionActions.set_active_mission, replayActions.stop], getGeomarkers);
};

function* getGeomarkersHistory(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const token: string = yield select((state: RootState) => state.auth.token);
    const missionId: string = yield select((state: RootState) => state.mission.active);
    const res: any = yield call(GEOMARKERS_API.getGeomarkersHistory, token, missionId, action.payload.from, action.payload.to);

    /* Retrieve only the initial object for each GM */
    const current_gm_objects = res.data.reduce((acc: any[], curr: any) => {
        const first = curr.history[0].content_object;
        if (first.created <= action.payload.from) acc.push(first);
        return acc;
    }, []);

    const groups = current_gm_objects.reduce((result: any, gm: any) => {
        let groupId = gm.feature.properties?.group || GEOMARKER_DEFAULT_GROUP;
        if (!(groupId in result)) {
            result = {
                ...result,
                [groupId]: {
                    visible: true
                }
            }
        }
        return result;
    }, {});

    const history = res.data.map((action: any) => {
        let h: any = {};
        action.history.forEach((ac: any, i: number) => {
            h[ac.received] = ac.content_object
        });
        return h;
    });

    yield put(geomarkersActions.get_geomarkers_success({ groups, geomarkers: current_gm_objects, history }));

};

function* watchReplayStart() {
    yield takeLatest((action: any) => (action.type === replayActions.start.type && !action.payload.fromFile), getGeomarkersHistory);
};

function* replayUpdate(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const currentTime: string = yield select((state: RootState) => state.replay.currentTime);
    const history: any[] = yield select((state: RootState) => state.nav.geomarkers.history);
    const geomarkers: any[] = yield select((state: RootState) => state.nav.geomarkers.geomarkers);
    const currentGroups: any[] = yield select((state: RootState) => state.nav.geomarkers.groups);

    let gm_objects = history.reduce((acc: any[], curr: any) => {
        const ret: any = Object.entries(curr).reverse().find(([time, el]: [string, any]) => {
            return (time < currentTime);
        });
        if (ret) {
            const current = geomarkers.find((gm: any) => gm.id === ret[1].id);
            acc.push({
                ...ret[1],
                feature: {
                    ...ret[1].feature,
                    properties: {
                        ...ret[1].feature.properties,
                        clicked: current?.feature.properties.clicked || false,
                    }
                }
            });
        }
        return acc;
    }, []);

    // We remove deleted GMs from the list
    gm_objects = gm_objects.filter((gm: any) => {
        const deleteTS = toDate(gm.delete).getTime() + 3 * 60000; // We are adding 3 minutes to get the real delete TS because when deleting an object, we are removing 3 minutes.
        const currentTS = toDate(currentTime).getTime();
        return !(deleteTS <= currentTS);
    });

    const groups = gm_objects.reduce((result: any, gm: any) => {
        let groupId = gm.feature.properties?.group || GEOMARKER_DEFAULT_GROUP;
        if (!(groupId in result)) {
            result = {
                ...result,
                [groupId]: currentGroups[groupId] ? {...currentGroups[groupId]} : { visible: true }
            }
        }
        return result;
    }, {});

    yield put(geomarkersActions.get_geomarkers_success({ groups, geomarkers: gm_objects, history }));
};

function* watchReplayUpdate() {
    yield takeLatest([replayActions.increment_time, replayActions.set_currentTime], replayUpdate);
};

function* showInfo(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const toasts: any = yield select((state: RootState) => state.toast[NAVIGATION_TOAST_CONTAINER_ID]);
    const index = toasts ? toasts.findIndex((t: any) => t.id === action.payload.id) : -1;

    if (index === -1) {
        const gm: GeomarkerType = yield select((state: RootState) => state.nav.geomarkers.geomarkers.find((gm: GeomarkerType) => gm.id === action.payload.id));
        yield put(toastActions.add_toast({
            containerId: NAVIGATION_TOAST_CONTAINER_ID,
            toast: {
                id: `geomarker-info-${action.payload.id}`,
                variant: GEOMARKERS_TOAST_INFO_ID,
                gm,
                persist: true,
                vertical: 'bottom',
                horizontal: 'center'
            }
        }));
    };
};

function* handleClick(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    if (action.type === geomarkersActions.click.type) {
        yield showInfo(action);
    }
    if (action.type === geomarkersActions.dblClick.type) {
        yield showEdit(action);
    }
};

function* watchClicks() {
    yield takeLatest([geomarkersActions.click, geomarkersActions.dblClick], handleClick);
};

function* showEdit(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    yield put(geomarkersActions.edit({ id: action.payload.id }));
    const gm: GeomarkerType = yield select((state: RootState) => state.nav.geomarkers.geomarkers.find((gm: GeomarkerType) => gm.id === action.payload.id));
    yield put(toastActions.add_toast({
        containerId: NAVIGATION_TOAST_CONTAINER_ID,
        toast: {
            id: `geomarker-edit-${action.payload.id}`,
            variant: GEOMARKERS_TOAST_EDIT_ID,
            gm,
            persist: true,
            vertical: 'bottom',
            horizontal: 'center'
        }
    }));
};

function* watchStartEdit() {
    yield takeLatest(geomarkersActions.start_edit, showEdit);
};

function* commitEdit(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const geomarker: GeomarkerType = yield select((state: RootState) => state.nav.geomarkers.edit.find((gm: GeomarkerType) => gm.id === action.payload.id));
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(GEOMARKERS_API.editGeomarker, token, missionId, geomarker);
        yield put(geomarkersActions.commit_edit_success({ id: action.payload.id, geomarker: res.data }));
    } catch (error: any) {
        yield put(geomarkersActions.commit_edit_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchCommitEdit() {
    yield takeEvery(geomarkersActions.commit_edit, commitEdit);
};

function* deleteGm(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(GEOMARKERS_API.deleteGeomarker, token, missionId, action.payload.id);
        yield put(geomarkersActions.delete_success({ id: action.payload.id }));
    } catch (error: any) {
        yield put(geomarkersActions.delete_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchDelete() {
    yield takeEvery(geomarkersActions.delete, deleteGm);
};

function* deleteGmGroup(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const groupId = action.payload.groupId || GEOMARKER_DEFAULT_GROUP;
    const geomarkers: GeomarkerType[] = yield select((state: RootState) => state.nav.geomarkers.geomarkers.filter((gm: GeomarkerType) => {
        if (gm.feature.properties?.group) return (gm.feature.properties?.group === groupId);
        else return (groupId === GEOMARKER_DEFAULT_GROUP);
    }));
    yield all(geomarkers.map((gm: GeomarkerType) => put(geomarkersActions.delete({ id: gm.feature.id }))));
};

function* watchDeleteGroup() {
    yield takeEvery(geomarkersActions.delete_group, deleteGmGroup);
};

function* openDrawnigToast(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    yield put(toastActions.add_toast({
        containerId: NAVIGATION_TOAST_CONTAINER_ID,
        toast: {
            id: `geomarker-drawing-${action.payload.id}`,
            variant: GEOMARKERS_TOAST_DRAWING_ID,
            feature: action.payload.feature,
            persist: true,
            vertical: 'bottom',
            horizontal: 'center'
        }
    }));
};

function* watchEndDrawing() {
    yield takeEvery(geomarkersActions.end_drawing, openDrawnigToast);
};

function* createGm(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const feature: any = yield select((state: RootState) => state.nav.geomarkers.draw.feature);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(GEOMARKERS_API.createGeomarker, token, missionId, feature);
        yield put(geomarkersActions.commit_drawing_success({}));
    } catch (error: any) {
        yield put(geomarkersActions.commit_drawing_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchCommitDrawing() {
    yield takeEvery(geomarkersActions.commit_drawing, createGm);
};

function* dropPoint(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(GEOMARKERS_API.createGeomarker, token, missionId, action.payload.feature);
        yield put(geomarkersActions.drop_point_success({}));
    } catch (error: any) {
        yield put(geomarkersActions.drop_point_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchDropPoint() {
    yield takeEvery(geomarkersActions.drop_point, dropPoint);
};

function* uploadGeomarkers(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(GEOMARKERS_API.uploadGeomarkers, token, missionId, action.payload.file);
        yield put(geomarkersActions.upload_geomarkers_success({}));
    } catch (error: any) {
        yield put(geomarkersActions.upload_geomarkers_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchUploadGeomarkers() {
    yield takeEvery(geomarkersActions.upload_geomarkers, uploadGeomarkers);
};

function* downloadGeomarkers(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res = yield call(GEOMARKERS_API.downloadGeomarkers, token, missionId);
        yield put(geomarkersActions.download_geomarkers_success({}));
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(res.data, null, 4)]));
        const a = document.createElement('a');
        a.href = url;
        a.download = missionId + '-geomarkers.json';
        a.click();
    } catch (error: any) {
        yield put(geomarkersActions.download_geomarkers_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchDownloadGeomarkers() {
    yield takeLatest(geomarkersActions.download_geomarkers, downloadGeomarkers);
};

export default function* watchGeomarker() {
    yield all([
        watchSetActiveMission(),
        watchReplayStart(),
        watchReplayUpdate(),
        watchClicks(),
        watchStartEdit(),
        watchCommitEdit(),
        watchDelete(),
        watchDeleteGroup(),
        watchEndDrawing(),
        watchCommitDrawing(),
        watchDropPoint(),
        watchUploadGeomarkers(),
        watchDownloadGeomarkers(),
    ]);
};