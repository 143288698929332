import React from "react";
import { useSelector } from "react-redux";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { RootState } from "../../../../../app/store";
import WidgetTemplate from "../../../../widgets/WidgetTemplate";
import Attitude from "../Attitude";
import { lastSelector } from "../../../tracking/Control";

const AttitudeWidget = React.memo((props: any) => {
    const { id, gridId, reduced, params, width, height } = props;
    const vehicle = useSelector((state: RootState) => state.nav.tracking[params.trackerId]);
    const last = useSelector((state: RootState) => lastSelector(state, { id: params.trackerId, source: vehicle.source }));
    const roll: number | undefined = last?.position.roll;
    const pitch: number | undefined = last?.position.pitch;

    return (
        <WidgetTemplate
            id={id}
            gridId={gridId}
            header={`${vehicle?.name} - Attitude`}
            width={width}
            height={height}
            reduced={reduced}
        >
            {
                (roll === undefined || pitch === undefined) &&
                <Alert severity="error">
                    {`Missing roll and/or pitch in ${vehicle.source} position frame`}
                </Alert>
            }
            {roll !== undefined && pitch !== undefined &&
                <Stack direction="column" spacing={1}>
                    <Attitude roll={-roll} pitch={pitch} /> {/*roll=-roll because it seems that the widget reverses the roll angle.*/}
                    <Stack direction="row" spacing={1}>
                        <Box sx={{ marginLeft: '10px' }}>{`Roll: ${roll}`}</Box>
                        <Box>{`Pitch: ${pitch}`}</Box>
                    </Stack>
                </Stack>
            }
        </WidgetTemplate>
    );
});

export default AttitudeWidget;
