import React from "react";
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useRoute } from '../../context/DashboardRouteProvider';
import { DashboadRouteType } from '../../../config/routes';

export default function TabSelector({ tabs, prefix }: { prefix: string, tabs: DashboadRouteType[] }) {
    const { activeTab } = useRoute();

    return (
        <ButtonGroup disableElevation variant="text" color="primary">
            {tabs.map((tab) => {
                const { id, label, path, icon: Icon, disabled } = tab;
                return (
                    <Button
                        key={id}
                        size="large"
                        component={Link}
                        to={`${prefix}${path}`}
                        startIcon={Icon && <Icon />}
                        color={activeTab === id ? "secondary" : "inherit"}
                        disabled={disabled}
                    >
                        <Typography variant="h6"
                            sx={{
                                maxWidth: '10em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'block'
                                },
                            }}>{label}</Typography>
                    </Button>
                );
            })}
        </ButtonGroup>
    );
}
