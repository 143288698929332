import React from "react";
import Badge from '@mui/material/Badge';
import NavigationIcon from '@mui/icons-material/Navigation';

export default function NavIcon() {
    return (
        <Badge
            color="error"
            variant="dot"
            invisible={true}
        >
            <NavigationIcon />
        </Badge>
    );
};