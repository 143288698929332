import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { formatError } from '../../utils/error';
export interface AuthState {
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    logged: boolean;
    loggingInError: string | null;
    loggingOutError: string | null;
    token: string | null;
    username: string | null;
    user_pk: number | null;
    customerId: string | null;
    level: string | null;
    myPlid: string | null;
};

const initialState: AuthState = {
    isLoggingIn: false,
    isLoggingOut: false,
    logged: false,
    loggingInError: null,
    loggingOutError: null,
    token: localStorage.getItem('token'),
    username: null,
    user_pk: null,
    customerId: null,
    level: null,
    myPlid: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: ((state, action) => {
            state.isLoggingIn = true;
            state.loggingInError = null;
            state.username = null;
            state.user_pk = null;
            state.level = null;
            state.customerId = null;
            state.token = null;
        }),
        login_success: ((state, action) => {
            state.isLoggingIn = false;
            state.loggingInError = null;
            state.logged = false; // we consider login_success thus logged = true after the get_user_info_success
            state.token = action.payload.token;
        }),
        login_fail: ((state, action) => {
            state.isLoggingIn = false;
            state.loggingInError = formatError(action.payload);
            state.logged = false;
            state.token = null;
        }),
        logout: ((state, action) => {
            state.isLoggingOut = true;
            state.loggingOutError = null;
        }),
        logout_success: ((state, action) => {
            state.isLoggingOut = false;
            state.logged = false;
            state.loggingOutError = null;
            state.username = null;
            state.user_pk = null;
            state.level = null;
            state.customerId = null;
            state.token = null;
        }),
        logout_fail: ((state, action) => {
            state.isLoggingOut = false;
            state.loggingOutError = formatError(action.payload);
            state.logged = false;
            state.username = null;
            state.user_pk = null;
            state.level = null;
            state.customerId = null;
            state.token = null;
        }),
        get_user_info: ((state, action) => {
            state.username = null;
            state.user_pk = null;
            state.level = null;
            state.customerId = null;
            state.myPlid = null;
        }),
        get_user_info_success: ((state, action) => {
            state.logged = true;
            state.username = action.payload.username;
            state.user_pk = action.payload.id;
            state.level = action.payload.status;
            state.customerId = action.payload.customer;
            state.myPlid = action.payload.plid;
        }),
        get_user_info_fail: ((state, action) => {
            state.isLoggingOut = false;
            state.loggingOutError = 'failed to get user info';
            state.logged = false;
            state.username = null;
            state.user_pk = null;
            state.level = null;
            state.customerId = null;
            state.token = null;
        }),
        notSupported: (state => {
            return;
        })
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const authActions = authSlice.actions;

export default authSlice.reducer;
