import { createSlice } from '@reduxjs/toolkit';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { RootState } from '../../app/store';

export interface TransferType {
    id: string;
    created: string;
    creator_id: string;
    recipient_id: string;
    status: string;
    file_name: string | null;
    file_size: number;
    hash: string;
    progress: number;
    started: string | null;
    received: string | null;
};

export interface TransferState {
    loading: boolean;
    loaded: boolean;
    error: string | null;
    list: TransferType[];
};

const initialState: TransferState = {
    loading: false,
    loaded: false,
    error: null,
    list: []
};

const transferSlice = createSlice({
    name: 'transfer',
    initialState,
    reducers: {
        get_transfers: ((state, action) => {
            state.loading = true;
            state.loaded = false;
            state.error = null;
            state.list = [];
        }),
        get_transfers_success: ((state, action) => {
            state.loading = false;
            state.loaded = true;
            state.list = action.payload.list.reverse();
        }),
        get_transfers_failure: ((state, action) => {
            state.loading = false;
            state.loaded = true;
            state.error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        post_transfer: ((state, action) => {
        }),
        post_transfer_success: ((state, action) => {
        }),
        post_transfer_failure: ((state, action) => {
        }),
        new_transfer_messages: ((state, action) => {
            const index = state.list.findIndex((t: TransferType) => t.id === action.payload.id);
            if (index !== -1) {
                state.list[index] = action.payload;
            } else {
                state.list.unshift(action.payload);
            };
        }),
        update_transfer: ((state, action) => {
        }),
        update_transfer_success: ((state, action) => {
        }),
        update_transfer_error: ((state, action) =>{
        })
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, state => {
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.stop, state => {
            Object.assign(state, initialState);
        });
    }
});

export const selectTransfer = (state: RootState) => state.transfer;

export const transferActions = transferSlice.actions;

export default transferSlice.reducer;