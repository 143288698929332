import { call, put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { transferActions } from "./transferSlice";
import { TRANSFER_API } from "./transferApi";
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { RootState } from "../../app/store";

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getTransfers(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(transferActions.get_transfers({}));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(TRANSFER_API.getTransfers, token, missionId);
        yield put(transferActions.get_transfers_success({ list: res.data }));
    } catch (error: any) {
        yield put(transferActions.get_transfers_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchSetActiveMission() {
    yield takeLatest([missionActions.set_active_mission, replayActions.stop], getTransfers);
};

function* postTransfer(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(TRANSFER_API.postTransfer, token, missionId, action.payload.file, action.payload.destination);
        yield put(transferActions.post_transfer_success({}));
    } catch (error: any) {
        yield put(transferActions.post_transfer_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchPostTransfer() {
    yield takeEvery(transferActions.post_transfer, postTransfer);
};

function* updateTransfer(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(TRANSFER_API.updateTransfer, token, missionId, action.payload.transferId, action.payload.status);
        yield put(transferActions.update_transfer_success({}));
    } catch (error: any) {
        yield put(transferActions.update_transfer_error({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data}));
    };
};

function* watchUpdateTransfer() {
    yield takeEvery(transferActions.update_transfer, updateTransfer);
};

export default function* watchTransfer() {
    yield all([
        watchSetActiveMission(),
        watchPostTransfer(),
        watchUpdateTransfer()
    ]);
};