import { Fill, Stroke, Style, Circle as CircleStyle, Text, RegularShape } from "ol/style";
import { LineString, Point } from "ol/geom";
import { transform } from "ol/proj";
import { getDistance } from "ol/sphere";
import bearing from "@turf/bearing";
import { amber } from '@mui/material/colors';
import { M_TO_NM } from "../../../../utils/GeomUtils";
import { createPolygonFromCircle } from "./geomarker";
import { isBackgroundDark } from "../../../../config/map";
import { store } from "../../../../app/store";

const formatInfos = function (line: any) {
    const proj = store.getState().map.projection;
    const first = transform(line.getFirstCoordinate(), proj, "EPSG:4326");
    const last = transform(line.getLastCoordinate(), proj, "EPSG:4326");
    const d = (getDistance(first, last) * M_TO_NM).toPrecision(4);
    const b = bearing(first, last).toPrecision(4);

    if (d === "0.000") return null;
    return (`distance: ${d} nm\nbearing: ${b} °`);
};

export function getSegmentsStyles(feature: any, fill: any, stroke: any) {
    const geometry = feature.getGeometry();
    const type = geometry.getType();
    let line;

    if (type === "Polygon") {
        line = new LineString(geometry.getCoordinates()[0]);
    } else if (type === "LineString") {
        line = geometry;
    } else {
        return [];
    }

    const segmentStyle = new Style({
        text: new Text({
            font: '14px Calibri,sans-serif',
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            backgroundFill: new Fill({
                color: 'rgba(0, 0, 0, 0.4)',
            }),
            padding: [2, 2, 2, 2],
            textBaseline: 'bottom',
            offsetY: -12,
        }),
        image: new RegularShape({
            radius: 6,
            points: 3,
            angle: Math.PI,
            displacement: [0, 8],
            fill: new Fill({
                color: 'rgba(0, 0, 0, 0.4)',
            }),
        }),
    });

    const getArrowStyle = (start: any, end: any) => {
        let dx = end[0] - start[0];
        let dy = end[1] - start[1];
        let rotation = Math.atan2(dy, dx);

        return new Style({
            image: new RegularShape({
                fill: new Fill({ color: fill }),
                stroke: new Stroke({ color: stroke, width: 1 }),
                points: 3,
                radius: 6,
                angle: -rotation + (90 * Math.PI) / 180,
                rotateWithView: true,
            })
        })
    };

    const segmentStyles = [segmentStyle];
    const arrowStyles: any = [];
    const styles: any = [];
    let count = 0;

    line.forEachSegment(function (a: any, b: any) {
        const segment = new LineString([a, b]);
        const label = formatInfos(segment);
        if (segmentStyles.length - 1 < count) {
            segmentStyles.push(segmentStyle.clone());
        };
        if (arrowStyles.length - 1 < count) {
            arrowStyles.push(getArrowStyle(a, b));
        };
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles[count].setGeometry(segmentPoint);
        segmentStyles[count].getText().setText(label || "");
        styles.push(segmentStyles[count]);
        const arrowPoint = new Point(segment.getCoordinateAt(0.5));
        arrowStyles[count].setGeometry(arrowPoint);
        styles.push(arrowStyles[count]);
        count++;
    });

    return styles;
};


function circleDrawerStyleFunction(feature: any, _resolution: any, measures: boolean) {
    const inColor = amber[500];
    const outColor =
        isBackgroundDark()
            ? "rgba(255, 255, 255, 0.7)"
            : "rgba(0, 0, 0, 0.8)";
    const width = 2;

    const geom = feature.getGeometry();
    const center = geom.getCenter();
    const radius = geom.getRadius();

    const styleArray = [
        new Style({
            stroke: new Stroke({
                color: outColor,
                width: width + 2,
                lineDash: [4, 8]
            }),
            geometry: createPolygonFromCircle(center, radius)
        }),
        new Style({
            stroke: new Stroke({
                color: inColor,
                width: width,
                lineDash: [4, 8]
            }),
            geometry: createPolygonFromCircle(center, radius)
        }),
        new Style({
            image: new RegularShape({
                fill: new Fill({ color: inColor }),
                stroke: new Stroke({ color: inColor, width: 2 }),
                points: 4,
                radius: 4,
                radius2: 0,
                angle: Math.PI / 4,
            }),
            geometry: new Point(center)
        }),
        ...(measures ? [new Style({
            text: new Text({
                text: `radius: ${(radius * M_TO_NM).toPrecision(4)} nm`,
                font: '12px Calibri,sans-serif',
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 1)',
                }),
                backgroundFill: new Fill({
                    color: 'rgba(0, 0, 0, 0.4)',
                }),
                padding: [2, 2, 2, 2],
                textBaseline: 'bottom',
                offsetY: -12,
            }),
            image: new RegularShape({
                radius: 6,
                points: 3,
                angle: Math.PI,
                displacement: [0, 8],
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.4)',
                }),
            }),
        })] : [])
    ];

    return styleArray;
};

export default function drawStyle(feature: any, resolution: any, measures: boolean) {

    const geometry = feature.getGeometry();
    const type = geometry.getType();
    const inColor = amber[500];
    const outColor =
        isBackgroundDark()
            ? "rgba(255, 255, 255, 0.2)"
            : "rgba(0, 0, 0, 0.8)";
    const width = 2;

    const defaultStylesArray = [
        new Style({
            stroke: new Stroke({
                color: outColor,
                width: width + 2,
                lineDash: [4, 8]
            })
        }),
        new Style({
            stroke: new Stroke({
                color: inColor,
                width: width,
                lineDash: [4, 8]
            })
        })
    ];

    switch (type) {
        case 'Point':
            return new Style({
                image: new CircleStyle({
                    radius: (width + 1) * 2,
                    fill: new Fill({
                        color: inColor
                    }),
                    stroke: new Stroke({
                        color: outColor,
                        width: (width + 1) / 2
                    })
                })
            });
        case 'LineString':
            /* Note: Polygon is styled via LineString already ... */
            return [...defaultStylesArray, ...(measures ? getSegmentsStyles(feature, inColor, outColor) : [])];
        case 'Circle':
            return circleDrawerStyleFunction(feature, resolution, measures);
        default:
            return undefined;
    };
};