import { createSlice } from '@reduxjs/toolkit';
import { MQTT_URL } from '../../config/const';

export interface Subscription {
    id: string;
    topic: string;
    options: object;
    callback_key: string;
}

export interface MqttState {
    settings: object;
    isEstablishingConnection: boolean;
    isConnected: boolean | null;
    subscriptions: Subscription[];
    error: object;
}

const initialState: MqttState = {
    settings: {
        url: MQTT_URL,
    },
    isEstablishingConnection: false,
    isConnected: null,
    subscriptions: [],
    error: {}
};

const mqttSlice = createSlice({
    name: 'mqtt',
    initialState,
    reducers: {
        setSettings: ((state, action) => {
            state.settings = action.payload;
        }),
        connect: ((state, action) => {
            state.isEstablishingConnection = true;
        }),
        connectionEstablished: (state => {
            state.isEstablishingConnection = false;
            state.isConnected = true;
            state.error = {};
        }),
        connectionEnd: (state => {
            state.isEstablishingConnection = false;
            state.isConnected = false;
            state.error = {};
            state.subscriptions = [];
        }),
        connectionError: ((state, error) => {
            state.isConnected = false;
            state.error = error;
        }),
        subscribe: ((state, action) => {
            state.subscriptions.push(action.payload);
        }),
        unsubscribe: ((state, action) => {
            state.subscriptions = state.subscriptions.filter(s => s.id !== action.payload);
        }),
        publish: ((state, action) => {
            return;
        }),
        end: (state => {
            state = initialState;
        }),
    },
});

export const mqttActions = mqttSlice.actions;

export default mqttSlice.reducer;