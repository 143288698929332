import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DashboadRouteType } from '../../../config/routes';
import MissionSelector from "./MissionSelector";
import TabSelector from "./TabSelector";
import Clock from './Clock';
import UserBox from './UserBox';
import MoreActions from "./MoreActions";
import MqttStatus from './MqttStatus';
import Replayer from '../../replay/Replayer';

export default function Topbar({ prefix, tabs }: { prefix: string, tabs: DashboadRouteType[] }) {
    const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

    return (
        <AppBar sx={{
            opacity: 0.9,
            overflow: 'auto',
        }}
            position="fixed"
        >
            <Toolbar sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                maxHeight: (theme) => theme.mixins.toolbar.minHeight,
                backgroundColor: (theme) => theme.palette.primary.dark,
            }}>
                <Stack sx={{ width: '100%' }} direction="column" spacing={1}>
                    <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <MissionSelector />
                        <TabSelector tabs={tabs.filter(t => !(t.more))} prefix={prefix} />
                        <Stack direction="row" spacing={2}>
                            <Clock mobile={mobile} />
                            {!mobile && <UserBox />}
                            <MoreActions actions={tabs.filter(t => t.more)} prefix={prefix} user={mobile} />
                        </Stack>
                    </Stack>
                    <Replayer />
                </Stack>
            </Toolbar>
            <MqttStatus />
        </AppBar>
    );
};