import { useRef } from 'react';
import { createSelector } from "reselect";
import uPlot from 'uplot';
import { useEffectOnce, useDebounce, useUpdateEffect } from 'usehooks-ts';
import { useGraphActionContext } from '../../graph/GraphContext';
import { options as defaultOptions } from '../../graph/defaultOptions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { PlotType, SerieType } from './TimeSeriesTab';
import { toDate } from '../../../utils/TimeUtils';
import { isEqual, findLastIndex } from 'lodash';

interface PlotProps extends SerieType {
    index: number;
    serieId: string;
    onError?: () => void;
    onHover?: (props: any) => void;
};

function getOptions(serieId: string, main: PlotType, others: PlotType[], onHover?: (props: any) => void) {
    return (
        uPlot.assign(defaultOptions([], false, onHover),
            {
                series: [
                    {},
                    {
                        id: `${main.source}-${main.label}-${main.param}`,
                        serieId,
                        show: true,
                        label: `${main.label}-${main.param}`,
                        width: 2,
                        stroke: main.color,
                        unit: main.unit,
                        toFixed: main.toFixed
                    },
                    ...others.map((p: PlotType) => ({
                        id: `${p.source}-${p.label}-${p.param}`,
                        serieId,
                        show: p.shown,
                        label: `${p.label}-${p.param}`,
                        width: 2,
                        stroke: p.color,
                        dash: [5, 5],
                        unit: p.unit,
                        toFixed: p.toFixed
                    }))
                ],
                scales: {
                    y: {
                        distr: main.scale === 'linear' ? 1 : main.scale === 'log' ? 3 : 1,
                        ...!main.autoscale && { range: uPlot.rangeNum(main.min, main.max, 0.1, true) }
                    }
                }
            })
    );
};

const paramsSelector = createSelector(
    (state: RootState) => state.telemetry.streams,
    (state: RootState) => state.replay.currentTime,
    (_: RootState, properties: { paramsList: PlotType[] }) => properties,
    (streams, currentTime, { paramsList }) => {
        let to_return: any[] = [];
        const currentTS = toDate(currentTime).getTime() / 1000;
        paramsList.forEach((p: PlotType) => {
            const stream = streams[p.source]?.[p.label];
            if (stream) {
                const lastIndex = currentTS ? findLastIndex(stream[0], (ts: number) => ts <= currentTS) : stream[0].length - 1;
                if (lastIndex >= 0) {
                    const timestamps = stream[0].slice(0, lastIndex + 1);
                    const values = stream[1].slice(0, lastIndex + 1);
                    to_return.push([
                        timestamps,
                        values.map((frame: any) => (frame[p.param] !== null && frame[p.param] !== undefined) ? Number(frame[p.param]) : null),
                        values.map((frame: any) => frame['position']),
                    ]);
                };
            };
        });
        return to_return;
    }
);

export default function Plot({ id, serieId, index, main, timespan, others, onError, onHover }: PlotProps) {

    const ctx = useGraphActionContext();
    const graphRef = useRef(false);
    const params = useSelector((state: RootState) => paramsSelector(state, { paramsList: [main, ...others] }), isEqual);
    const debouncedParams = useDebounce(params, 200);
    const prevProps = useRef({ params: debouncedParams }).current;

    useUpdateEffect(() => {
        if (graphRef.current) {
            const graphOptions = getOptions(serieId, main, others, onHover);
            ctx?.setOptions(id, graphOptions);
        }
    }, [id, main, others]);

    useUpdateEffect(() => {
        if (graphRef.current) {
            ctx?.move(id, index);
        };
    }, [id, index]);

    useUpdateEffect(() => {
        if (graphRef.current) {
            ctx?.setTimespan(id, timespan * 60);
            try {
                let data: any = uPlot.join(debouncedParams as uPlot.AlignedData[]);
                // Here we reorder the data arrays so that all the position arrays are at the end of data
                let plot_arrays = data.reduce((result: any, current: any, index: number) => {
                    if (index === 0) result.push(current);
                    if (index % 2 === 1) result.push(current);
                    return result;
                }, []);
                let position_arrays = data.reduce((result: any, current: any, index: number) => {
                    if (index === 0) return result;
                    if (index % 2 === 0) result.push(current);
                    return result;
                }, []);
                ctx?.setData(id, [...plot_arrays, ...position_arrays]);
            } catch { onError?.(); }
        };
        return () => {
            prevProps.params = debouncedParams;
        };
    }, [id, debouncedParams, timespan]);

    useEffectOnce(() => {
        const graphOptions = getOptions(serieId, main, others, onHover);
        ctx?.add(id, 12, timespan * 60, graphOptions);
        try {
            let data: any = uPlot.join(debouncedParams as uPlot.AlignedData[]);
            // Here we reorder the data arrays so that all the position arrays are at the end of data
            let plot_arrays = data.reduce((result: any, current: any, index: number) => {
                if (index === 0) result.push(current);
                if (index % 2 === 1) result.push(current);
                return result;
            }, []);
            let position_arrays = data.reduce((result: any, current: any, index: number) => {
                if (index === 0) return result;
                if (index % 2 === 0) result.push(current);
                return result;
            }, []);
            ctx?.setData(id, [...plot_arrays, ...position_arrays]);
        } catch { onError?.(); }
        graphRef.current = true;

        return () => {
            ctx?.remove(id);
            graphRef.current = false;
        }
    });

    return null;
};