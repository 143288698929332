import React from 'react';
import AirportsLayer from './layers/AirportsLayer';
import NavaidsLayer from './layers/NavaidsLayer';
import AdsbLayer from './layers/AdsbLayer';

export default function Aero({ zIndex }: { zIndex: number }) {
    return (
        <>
            <AirportsLayer zIndex={zIndex} />
            <NavaidsLayer zIndex={zIndex} />
            <AdsbLayer zIndex={zIndex} />
        </>
    )
};