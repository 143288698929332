import { call, put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { aeroActions, AirportType, MetarType } from "./aeroSlice";
import { RootState } from '../../app/store';
import { AERO_API } from './aeroApi';
import { mapActions, OriginType } from "../map/mapSlice";
import { toastActions } from "../toast/toastSlice";
import { computeModules, moduleKeyType } from "../../utils/computeModules/Modules";
import { NAVAIDS_MIN_ZOOM_LEVEL, AIRPORTS_MIN_ZOOM_LEVEL } from '../../config/aero';
import { NAVIGATION_TOAST_CONTAINER_ID, AIRPORT_TOAST_INFO_ID, ADSB_TOAST_INFO_ID } from '../../config/toast';
import { FEET_TO_METERS } from "../../utils/GeomUtils";

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getAirports(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const visible: boolean = yield select((state: RootState) => state.nav.aero.airports.visible);

    if (visible) {
        try {
            yield put(aeroActions.get_airports({}));
            const token: string = yield select((state: RootState) => state.auth.token);
            const view: any = yield select((state: RootState) => state.map.view);
            const types: string[] = yield select((state: RootState) => {
                if (state.map.view.zoom >= AIRPORTS_MIN_ZOOM_LEVEL) return state.nav.aero.airports.displayedTypes;
                return [];
            });
            const res: any = yield call(AERO_API.getAirports, token, view.extent, types);
            yield put(aeroActions.get_airports_success({ airports: res.data }));
        } catch (error: any) {
            yield put(aeroActions.get_airports_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
        };
    };
};

function* watchGetAirports() {
    yield takeLatest([mapActions.set_view, aeroActions.set_airports_visibility, aeroActions.add_airport_type, aeroActions.remove_airport_type], getAirports);
};

function* getNavaids(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const visible: boolean = yield select((state: RootState) => state.nav.aero.navaids.visible);

    if (visible) {
        try {
            yield put(aeroActions.get_navaids({}));
            const token: string = yield select((state: RootState) => state.auth.token);
            const view: any = yield select((state: RootState) => state.map.view);
            const types: string[] = yield select((state: RootState) => {
                if (state.map.view.zoom >= NAVAIDS_MIN_ZOOM_LEVEL) return state.nav.aero.navaids.displayedTypes;
                return [];
            });
            const res: any = yield call(AERO_API.getNavaids, token, view.extent, types);
            yield put(aeroActions.get_navaids_success({ navaids: res.data }));
        } catch (error: any) {
            yield put(aeroActions.get_navaids_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
        };
    };
};

function* watchGetNavaids() {
    yield takeLatest([mapActions.set_view, aeroActions.set_navaids_visibility, aeroActions.add_navaid_type, aeroActions.remove_navaid_type], getNavaids);
};

function* handleAirportClick(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    if (action.type === aeroActions.airport_click.type) {
        const toasts: any = yield select((state: RootState) => state.toast[NAVIGATION_TOAST_CONTAINER_ID]);
        const index = toasts ? toasts.findIndex((t: any) => t.id === `airport-${action.payload.icao}`) : -1;

        if (index === -1) {
            const airport: AirportType | undefined = yield select((state: RootState) => state.nav.aero.airports.list.find((a: AirportType) => a.icao === action.payload.icao));
            const metar: MetarType | undefined = yield select((state: RootState) => state.nav.aero.airports.metars.find((m: MetarType) => m.icao === action.payload.icao));
            yield put(toastActions.add_toast({
                containerId: NAVIGATION_TOAST_CONTAINER_ID,
                toast: {
                    id: `airport-info-${action.payload.icao}`,
                    variant: AIRPORT_TOAST_INFO_ID,
                    airport,
                    metar,
                    persist: true,
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            }));
        };
    }
    if (action.type === aeroActions.airport_dblClick.type) {
        yield put(aeroActions.get_metar({ icao: action.payload.icao }));
    }
};

function* watchAirportClicks() {
    yield takeLatest([aeroActions.airport_click, aeroActions.airport_dblClick], handleAirportClick);
};

function* getMetar(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(AERO_API.getMetar, token, missionId, action.payload.icao);
        let metar = {};
        if (res.data?.payloads[0]?.features[0]) metar = res.data.payloads[0].features[0].properties;
        else metar = { station_id: action.payload.icao, raw_text: 'no METAR available' }
        yield put(aeroActions.get_metar_success({ icao: action.payload.icao, metar }));
    } catch (error: any) {
        yield put(aeroActions.get_metar_failure({ icao: action.payload.icao, status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchGetMetar() {
    yield takeLatest(aeroActions.get_metar, getMetar);
};

function* addAdsbFeature(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const computeConf: {module: string} | undefined = action.payload.config?.["aero"]?.["adsb"]?.["default"]?.["doCompute"]; // TODO : handle ID specific configurations ?
    const configArray: {module: string}[] = computeConf === undefined ? [] : Array.isArray(computeConf) ? computeConf : [computeConf];

    if (configArray !== undefined && configArray.length && configArray.every(config => config.module !== undefined)) {
        const index: number = yield select((state: RootState) => state.nav.aero.adsb.list.findIndex((f: any) => f.id === action.payload.feature.id));

        if (index !== -1) {
            const position: [number, number] = [action.payload.feature.properties.longitude || 0, action.payload.feature.properties.latitude || 0];
            const origin: OriginType = yield select((state: RootState) => state.map.localOrigin);
            const alt = action.payload.feature.properties.altitude || 0;

            if (configArray.length && configArray.every(config => Object.keys(computeModules).includes(config.module))) {
                const computedArray = configArray.map(config => {
                    const key = config.module as moduleKeyType;
                    const value = {...action.payload.feature.properties};
                    value["position"] = position;
                    value["gps_msl_alt"] = alt * FEET_TO_METERS;
                    const params = {
                        position: "position",
                        gps_msl_alt: "gps_msl_alt"
                    };
                    // TODO : check for possible params in conf for adsb parameters ?
                    if (computeModules[key].params.every((param: string) => Object.keys(value).includes(param))) {
                        return computeModules[key].compute(value, params, origin);
                    } else {
                        console.log("missing param for module " + config.module + " in adsb stream, live computation bypassed");
                        return {};
                    }
                });
                const computed = computedArray.reduce((current, next) => Object.assign(current, next));
                yield put(aeroActions.add_adsb_computed({computed, index: index}));
            }
        }
    }
};

function* watchAddAdsbFeature() {
    yield takeEvery(aeroActions.add_adsb_feature, addAdsbFeature);
};

function* handleAdsbAircraftClick(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    if (action.type === aeroActions.adsb_aircraft_click.type) {
        yield put(aeroActions.adsb_toggle_hud({ id: action.payload.id }));
    }
    if (action.type === aeroActions.adsb_aircraft_dblClick.type) {
        yield put(toastActions.add_toast({
            containerId: NAVIGATION_TOAST_CONTAINER_ID,
            toast: {
                id: `adsb-aircraft-${action.payload.properties.callsign}`,
                variant: ADSB_TOAST_INFO_ID,
                properties: action.payload.properties,
                persist: true,
                vertical: 'bottom',
                horizontal: 'center'
            }
        }));
    }
};

function* watchAdsbAircraftClick() {
    yield takeLatest([aeroActions.adsb_aircraft_click, aeroActions.adsb_aircraft_dblClick], handleAdsbAircraftClick);
};

function* setLocalStorage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const aero = yield select((state: RootState) => state.nav.aero);
    localStorage.setItem('_aero', JSON.stringify({
        airports: {
            visible: aero.airports.visible,
            displayedTypes: aero.airports.displayedTypes
        },
        navaids: {
            visible: aero.navaids.visible,
            displayedTypes: aero.navaids.displayedTypes
        },
        adsb: {
            visible: aero.adsb.visible,
            filters: aero.adsb.filters
        }
    }));
};

function* watchAdsbChange() {
    yield takeLatest([
        aeroActions.set_airports_visibility,
        aeroActions.add_airport_type,
        aeroActions.remove_airport_type,
        aeroActions.set_navaids_visibility,
        aeroActions.add_navaid_type,
        aeroActions.remove_navaid_type,
        aeroActions.set_adsb_visibility,
        aeroActions.add_adsb_filter,
        aeroActions.moveup_adsb_filter,
        aeroActions.movedown_adsb_filter,
        aeroActions.delete_adsb_filter,
        aeroActions.modify_adsb_filter
    ], setLocalStorage);
};

export default function* watchAero() {
    yield all([
        watchGetAirports(),
        watchGetNavaids(),
        watchAirportClicks(),
        watchAdsbAircraftClick(),
        watchGetMetar(),
        watchAddAdsbFeature(),
        watchAdsbChange(),
    ]);
};