import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import { geomarkersActions } from "../../../redux/geomarkers/geomarkersSlice";

export default function UploadFile({ open, handleClose }: { open: boolean, handleClose: () => void }) {
    const dispatch = useDispatch();
    const [file, setFile] = useState<any | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleFileSelect = (props: any) => {
        const { target } = props;
        if (target.files[0].size > 1000000) {
            setError(`${target.files[0].name} size exceed 1MB`);
            setFile(null);
            return;
        } else {
            setError(null);
        }
        setFile(target.files[0]);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (file?.name !== undefined) {
            dispatch(geomarkersActions.upload_geomarkers({ file }));
        };
        handleClose();
    };

    useEffect(() => {
        setError(null);
        setFile(null);
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Upload Geomarker File</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <Typography variant="h6" style={{ fontSize: "1rem" }}>Selected File:</Typography>
                        <div>
                            <input
                                id="select-button-file"
                                multiple
                                onChange={handleFileSelect}
                                type="file"
                            />
                            <label htmlFor="select-button-file">
                                <Button component="span" variant="text">
                                    {file?.name ? file.name : '...'}
                                </Button>
                            </label>
                        </div>
                    </Stack>
                </Stack>
                {error && <Alert severity="error" variant="outlined">{error}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button
                    color="inherit"
                    variant="outlined"
                    disabled={!(file?.name !== undefined)}
                    onClick={handleSubmit}
                    size="small"
                >
                    Upload
                </Button>
                <Button
                    color="inherit"
                    variant="outlined"
                    size="small"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};