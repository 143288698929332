import React from "react";
import { RFeature } from "rlayers";
import VectorLayer from '../../map/layers/VectorLayer';
import Point from "ol/geom/Point";
import { transform } from "ol/proj";
import { store } from "../../../../app/store";
import { circlesStyle } from '../styles/circles';
import isEqual from "lodash/isEqual";

const Circles = React.memo(function Circles({ position, properties }: { position: number[], properties: any }) {
    const mapProjection = store.getState().map.projection;
    
    return (
        <RFeature
            geometry={new Point(transform(position, "EPSG:4326", mapProjection))}
            properties={properties}
        />
    );
}, isEqual);

const CirclesLayer = (props: any) => {

    const { id, visible, last, zIndex } = props;

    if (!last) return null;
    return (
        <VectorLayer
            id={`${id}-cirlces-layer`}
            zIndex={zIndex}
            visible={visible}
            style={circlesStyle}
            rulingSnap={true}
        >
            <Circles position={last.position} properties={{ ...last.properties, plid: id, color: "grey" }} />
        </VectorLayer>
    );
};

export default CirclesLayer;
