import React from 'react';
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { authActions } from '../../redux/auth/authSlice';

export default function Error({ location }: { location: any }) {

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(authActions.logout({}));
        localStorage.clear();
    };

    return (
        <Box sx={{ margin: 'auto', width: '50%'}}>
            <Box sx={{padding: (theme) => theme.spacing(2), margin: (theme) => theme.spacing(2)}}>
                <Alert severity="error"
                    action={
                        <IconButton
                            onClick={onClick}
                            size="small"
                            color="inherit"
                        >
                            <ExitToAppIcon />
                        </IconButton>
                    }
                >
                    <AlertTitle>{location.state ? location.state.title : "undefined"}</AlertTitle>
                    {location.state ? location.state.message : "undefined"}
                </Alert>
            </Box>
        </Box>
    );
}
