import { Fill, Icon, Stroke, Style, Text, RegularShape } from "ol/style";
import Point from "ol/geom/Point";
import { Polygon } from "ol/geom";
import { shift_point } from "../../../../utils/GeomUtils";
import { transform } from "ol/proj";
import { store } from "../../../../app/store";
import { getPointSVG } from "./edit";
import { getSegmentsStyles } from "./draw";
import { isBackgroundDark } from "../../../../config/map";

/**
 *
 * @param {*} center
 * @param {*} radius in meters
 */
export const createPolygonFromCircle = function (center: number[], radius: number): any {
    const mapProjection = store.getState().map.projection;
    const dataProjection = "EPSG:4326";

    center = transform(center, mapProjection, dataProjection);
    const n = 128;
    const coordinates = Array(n + 1)
        .fill(null)
        .map((_, i) => (360 * i) / n) // angles in degrees
        .map(angle =>
            transform(
                shift_point(center, angle, radius),
                dataProjection,
                mapProjection
            )
        );
    return new Polygon([coordinates]);
};

export default function geomarkerStyle(feature: any, _resolution: any, measures: boolean) {

    const type = feature.getGeometry().getType();
    const radius = feature.get("radius");
    const center = feature.get("center");
    const label = feature.get("label");
    const color = feature.get("color") ? feature.get("color") : "white";
    const width = 2;
    const font = "bold 10px sans-serif";
    const outColor =
        isBackgroundDark()
            ? "rgba(255, 255, 255, 0.2)"
            : "rgba(0, 0, 0, 0.8)";

    let styles = [
        new Style({
            stroke: new Stroke({
                color: outColor,
                width: width + 1
            })
        }),
        new Style({
            stroke: new Stroke({
                color: color,
                width: width,
            }),
        }),
        new Style({
            text: new Text({
                text: label,
                font: font,
                textBaseline: "bottom",
                placement: "line",
                offsetY: type === "Point" ? 25 : 0,
                fill: new Fill({ color: color }),
                stroke: new Stroke({
                    color: outColor,
                    width: 1.5,
                }),
            })
        })
    ];

    if (radius) {
        styles.push(new Style({
            geometry: new Point(center),
            image: new RegularShape({
                fill: new Fill({ color }),
                stroke: new Stroke({ color, width: 2 }),
                points: 4,
                radius: 4,
                radius2: 0,
                angle: Math.PI / 4,
            })
        }));
    } else {
        styles.push(new Style({
            image: new Icon({
                opacity: 1,
                src:
                    "data:image/svg+xml;charset=utf-8," +
                    encodeURIComponent(getPointSVG(color, outColor)),
                scale: 0.03 * width
            })
        }));
        if (measures) styles = [...styles, ...getSegmentsStyles(feature, color, outColor)];
    }

    return styles;
};