import { OnMessageCallback } from 'mqtt';
import { store } from '../../app/store';
import { toDate } from '../../utils/TimeUtils';
import { customActions } from './customSlice';

export const customMqttParser: OnMessageCallback = (topic: any, payload: any, packet: any): void => {
    const custom = JSON.parse(payload.toString());
    if (toDate(custom.delete) <= toDate(Date.now() / 1000)) {
        store.dispatch(customActions.remove({ id: custom.id }));
    } else {
        store.dispatch(customActions.append({ custom }));
    };
};