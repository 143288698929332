import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import WidgetTemplate from "../../widgets/WidgetTemplate";
import { TabContent, SelectTimespan, SerieType } from "./TimeSeriesTab";
import { GraphContextProvider } from '../../graph/GraphContext';
import { widgetActions } from "../../../redux/widget/widgetSlice";
import { telemetryActions } from "../../../redux/telemetry/telemetrySlice";

const GraphWidget = React.memo((props: any) => {

    const dispatch = useDispatch();
    const { id, gridId, reduced, params, width, height } = props;

    const setParams = (widgetId: string, series: any[], sideOpen: boolean | undefined) => {
        dispatch(widgetActions.set_widget_params({ gridId, id: widgetId, params: { series, sideOpen } }));
    };

    const handleTimespanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let timespan: number;
        timespan = parseInt(event.target.value);
        const newSeries = params.series.map((s: SerieType) => ({ ...s, timespan }));
        setParams(id, newSeries, params.sideOpen);
    };

    const onPlotError = () => {
        dispatch(widgetActions.remove_widget({ gridId, id }));
    };

    const onPlotHover = (props: any) => {
        if (!props) dispatch(telemetryActions.clear_map_markers({}));
        else dispatch(telemetryActions.add_map_marker(props));
    };

    useEffect(() => {
        if (params.series === undefined || params.series.length === 0) dispatch(widgetActions.remove_widget({ gridId, id }));
    }, [params.series, gridId, id, dispatch]);

    return (
        <WidgetTemplate
            id={id}
            gridId={gridId}
            header={"Time Series"}
            actions={[
                <SelectTimespan value={params.series?.[0]?.timespan} onChange={handleTimespanChange} />
            ]}
            width={width}
            height={height}
            reduced={reduced}
        >
            <GraphContextProvider>
                <TabContent
                    tabId={id}
                    series={params.series}
                    setParams={setParams}
                    sideOpen={params.sideOpen !== undefined ? params.sideOpen : false}
                    dimensions={[width, height]}
                    onPlotError={onPlotError}
                    onPlotHover={onPlotHover}
                />
            </GraphContextProvider>
        </WidgetTemplate>
    );
});

export default GraphWidget;