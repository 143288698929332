import { put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from 'uuid';
import { widgetActions } from "./widgetSlice";
import { missionActions } from "../mission/missionSlice";
import { ConversationType } from "../chat/chatSlice";
import { RootState } from '../../app/store';
import { toastActions } from "../toast/toastSlice";
import { CHAT_WIDGET_ID, TIMESERIES_WIDGET_ID } from '../../config/widget';
import { DASHBOARD_TOAST_CONTAINER_ID, DASHBOARD_TOAST_INFO_ID } from '../../config/toast';

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getWidgetsFromLs(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const widgetLS = JSON.parse(localStorage.getItem("_widgets") || "{}");
    yield all(Object.entries(widgetLS).map(([gridId, widgets]: [string, any]) => put(widgetActions.restore_grid({gridId, widgets}))))
};

function* watchSetActiveMission() {
    yield takeLatest(missionActions.set_active_mission, getWidgetsFromLs);
};

function* setWidgetLS(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const widget = yield select((state: RootState) => state.widget);
    // TODO :  if (!replayIsOn) localStorage.setItem('_widgets', JSON.stringify(widget))
    localStorage.setItem('_widgets', JSON.stringify(widget))
};

function* watchActions() {
    yield takeLatest([
        widgetActions.add_widget,
        widgetActions.remove_widget,
        widgetActions.reduce_widget,
        widgetActions.expand_widget,
        widgetActions.update_widget_layout,
        widgetActions.set_widget_params,
    ], setWidgetLS);
};

function* addChatWidgetAddToast(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {

    const conversation = yield select((state: RootState) => state.chat.conversations.list.find((c: ConversationType) => c.id === action.payload.params.conversationId));

    yield put(toastActions.add_toast({
        containerId: DASHBOARD_TOAST_CONTAINER_ID,
        toast: {
            id: `widget-${uuidv4()}`,
            variant: DASHBOARD_TOAST_INFO_ID,
            severity: "success",
            autoHideDuration: 6000,
            message: `Widget successfully added for #${conversation.name}`,
            vertical: 'bottom',
            horizontal: 'left'
        }
    }));
};

function* watchAddChatWidget() {
    yield takeEvery((action: any) => {
        return (action.type === widgetActions.add_widget.type && action.payload.kind === CHAT_WIDGET_ID);
    }, addChatWidgetAddToast);
};

function* addTimeSeriesWidgetAddToast(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {

    yield put(toastActions.add_toast({
        containerId: DASHBOARD_TOAST_CONTAINER_ID,
        toast: {
            id: `widget-${uuidv4()}`,
            variant: DASHBOARD_TOAST_INFO_ID,
            severity: "success",
            autoHideDuration: 6000,
            message: `Widget timeseries successfully added`,
            vertical: 'bottom',
            horizontal: 'left'
        }
    }));
};

function* watchAddTimeSeriesWidget() {
    yield takeEvery((action: any) => {
        return (action.type === widgetActions.add_widget.type && action.payload.kind === TIMESERIES_WIDGET_ID);
    }, addTimeSeriesWidgetAddToast);
};

export default function* watchWidget() {
    yield all([
        watchSetActiveMission(),
        watchActions(),
        watchAddChatWidget(),
        watchAddTimeSeriesWidget()
    ]);
};