import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function Loading({ backgroundColor, color, text }: { backgroundColor?: string, color?: string, text?: string }) {
    return (
        <Backdrop open={true}>
            <Typography variant="h4" style={{ margin: '10px' }}>{text}</Typography>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};