import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'usehooks-ts';
import { RootState } from '../../../app/store';
import Lightning from './layers/Lightning';
import VectorLayer from '../map/layers/VectorLayer';
import { weatherStyles } from './styles/weather';
import { RLayerVectorImage, RLayerImage } from 'rlayers';
import GeoJSON from 'ol/format/GeoJSON';
import { weatherActions } from '../../../redux/weather/weatherSlice';
import isEqual from "lodash/isEqual";

const WeatherLayer = React.memo(function WeatherLayer({ component: Component, productId, extent, ...rest }: { component: any, productId: string, extent: any }) {
    const dispatch = useDispatch();
    const [key, setKey] = useState(0);
    const prevExtent = useRef({ value: null }).current;

    const onClick = useCallback((e: any) => {
        const { geometry, ...properties } = e.target.getProperties();
        dispatch(weatherActions.product_click({ id: productId, properties }));
    }, [dispatch, productId]);

    useUpdateEffect(() => {
        /* 
            We have to force component remount via the key update on extent value change,
            because RLayerImage is not updated on extent change
        */
        if (!isEqual(extent, prevExtent.value)) setKey(key => key += 1);

        return () => {
            prevExtent.value = extent
        }
    }, [extent]);

    return (<Component {...rest} id={`${productId}-weather-layer-${key}`} extent={extent} component={RLayerVectorImage} onClick={onClick} />)
}, isEqual);

export default function Weather({ zIndex: baseIndex }: { zIndex: number }) {
    const weather = useSelector((state: RootState) => state.nav.weather);

    return (
        <>
            {
                Object.keys(weather).map((productId) => {
                    let { type, data, dataIndex, zIndex, ...rest } = weather[productId];
                    let component = null;
                    let opts: any = {};

                    if (!data?.[dataIndex]?.url) return null;

                    if (productId.includes('lightning')) return <Lightning key={productId} productId={productId} zIndex={baseIndex + zIndex} {...data[dataIndex]} {...rest} style={weatherStyles['lightning']} />

                    switch (type) {
                        case "vector":
                            component = VectorLayer;
                            opts.component = RLayerVectorImage;
                            opts.image = true;
                            opts.style = weatherStyles[productId];
                            opts.format = new GeoJSON();
                            break;
                        case "image":
                            component = RLayerImage;
                            break;
                        default:
                            break;
                    }
                    if (!component) return null;
                    return <WeatherLayer key={productId} productId={productId} component={component} zIndex={baseIndex + zIndex} {...data[dataIndex]} {...rest} {...opts} />
                })
            }
        </>
    );
};