import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from 'material-ui-confirm';
import { useElementSize } from 'usehooks-ts';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import Slider, { SliderValueLabelProps } from '@mui/material/Slider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Accordion, AccordionSummary, AccordionDetails } from '../../utils/accordion/Accordion';
import CustomSettings from './CustomSettings';
import { RootState } from "../../../app/store";
import { CustomProductType, customActions } from '../../../redux/custom/customSlice';

export function CustomControlActions(props: any) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpen} startIcon={<AddCircleIcon />}>
                ADD
            </Button>
            <CustomSettings open={open} handleClose={handleClose} />
        </>
    );
};

function ValueLabelComponent(props: SliderValueLabelProps) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={`opacity: ${value}`}>
            {children}
        </Tooltip>
    );
};

const Custom = React.memo(function Custom({ id, custom, expanded, handleChange }: { id: string, custom: CustomProductType, expanded: string | boolean, handleChange: (groupId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void }) {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const [editOpen, setEditOpen] = useState(false);
    const [opacity, setOpacity] = useState(custom.opacity * 100);
    const [elRef, { width }] = useElementSize();

    const toggleLayer = useCallback((e: any) => {
        dispatch(customActions.set_visibility({ id, visible: !custom.visible }));
    }, [dispatch, id, custom]);

    const onClick = (e: any) => {
        e.stopPropagation();
    };

    const moveUp = useCallback((e: any) => {
        e.stopPropagation();
        dispatch(customActions.move_up({ id }));
    }, [dispatch, id]);

    const moveDown = useCallback((e: any) => {
        e.stopPropagation();
        dispatch(customActions.move_down({ id }));
    }, [dispatch, id]);

    const onSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
        if (typeof value === 'number') setOpacity(value);
    };

    const onSliderChangeCommitted = useCallback((event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
        if (typeof value === 'number') dispatch(customActions.set_opacity({ id, opacity: value / 100 }));
    }, [dispatch, id]);

    const onSliderClick = (e: any) => {
        e.stopPropagation();
    };

    const onEdit = useCallback((e: any) => {
        e.stopPropagation();
        setEditOpen(true);
    }, [setEditOpen]);

    const handleEditClose = useCallback(() => {
        setEditOpen(false);
    }, [setEditOpen]);

    const onDelete = useCallback((e: any) => {
        e.stopPropagation();
        confirm({
            confirmationButtonProps: { color: "secondary" },
            description: `Please confirm deletion of custom layer for ${custom.file_name}.`
        })
            .then(() => {
                dispatch(customActions.delete({ id }));
            })
            .catch(() => { });
    }, [custom, id, confirm, dispatch]);

    return (
        <Box ref={elRef} sx={{ width: '100%' }}>
            <Accordion expanded={expanded === id} onChange={handleChange(id)} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Typography variant="h6" sx={{ fontSize: '0.9rem', maxWidth: `${width / 1.9}px`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{custom.file_name}</Typography>
                        <Stack
                            direction="row"
                            spacing={0}
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <IconButton onClick={moveUp} size="small">
                                <ArrowUpwardIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={moveDown} size="small">
                                <ArrowDownwardIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={onDelete} size="small">
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            <Switch
                                size="small"
                                checked={custom.visible}
                                onClick={onClick}
                                onChange={toggleLayer}
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ width: '100%', maxHeight: '250px', overflow: 'auto', padding: 0 }}>
                    <Stack
                        direction="row"
                        spacing={0}
                        alignItems="center"
                        justifyContent="space-evenly"
                    >
                        <IconButton onClick={onEdit} size="small">
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <Slider
                            sx={{ width: 100, margin: 2 }}
                            size="small"
                            valueLabelDisplay="auto"
                            slots={{
                                valueLabel: ValueLabelComponent,
                            }}
                            color='secondary'
                            value={opacity}
                            onClick={onSliderClick}
                            onChange={onSliderChange}
                            onChangeCommitted={onSliderChangeCommitted}
                        />
                    </Stack>
                </AccordionDetails>
                <CustomSettings open={editOpen} handleClose={handleEditClose} custom={custom} />
            </Accordion>
        </Box>
    );
});

export function CustomControl(props: any) {

    const customs = useSelector((state: RootState) => state.nav.custom.files);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (customId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? customId : false);
        };

    return (
        <Stack direction="column" spacing={0}>
            {Object.entries(customs)
                .sort((a: [string, CustomProductType], b: [string, CustomProductType]) => {
                    if (a[1].zIndex > b[1].zIndex) return -1;
                    if (a[1].zIndex < b[1].zIndex) return 1;
                    return 0;
                })
                .map(([customId, custom]) =>
                    <Custom
                        key={customId}
                        id={customId}
                        custom={custom}
                        expanded={expanded}
                        handleChange={handleChange}
                    />
                )}
        </Stack>
    );
};