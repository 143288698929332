import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { strftime, toDate } from '../../utils/TimeUtils';
import { missionActions } from '../mission/missionSlice';

export interface ReplayState {
    currentTime: string;
    speed: number;
    play: boolean;
    isActive: boolean;
    error: string | null;
    startTime: string;
    endTime: string;
};

const initialState: ReplayState = {
    currentTime: '',
    play: false,
    speed: 1,
    isActive: false,
    error: null,
    startTime: '',
    endTime: ''
};

const replaySlice = createSlice({
    name: 'replay',
    initialState,
    reducers: {
        start: ((state, action) => {
            state.isActive = true;
            state.play = false;
            state.startTime = action.payload.from;
            state.endTime = action.payload.to;
            state.currentTime = action.payload.from;
        }),
        stop: ((state, action) => {
            state.isActive = false;
            state.play = false;
            state.startTime = '';
            state.endTime = '';
            state.currentTime = '';
            state.speed = 1;
        }),
        play: ((state, action) => {
            state.play = true;
        }),
        pause: ((state, action) => {
            state.play = false;
        }),
        set_speed: ((state, action) => {
            state.speed = action.payload.speed;
        }),
        set_currentTime: ((state, action) => {
            state.currentTime = strftime('%N', toDate(action.payload.time));
        }),
        increment_time: ((state, action) => {
            if (!state.play) return;
            const newTime = toDate(state.currentTime);
            newTime.setSeconds(newTime.getSeconds() + state.speed);
            if (strftime('%N', newTime) > state.endTime) {
                state.currentTime = state.endTime;
                state.play = false;
            } else {
                state.currentTime = strftime('%N', newTime);
            };
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            Object.assign(state, initialState);
        });
    }
});

export const selectReplay = (state: RootState) => state.replay;

export const replayActions = replaySlice.actions;

export default replaySlice.reducer;