import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { aeroActions } from '../../../../redux/aero/aeroSlice';
import { ColorPalette } from '../../../telemetry/timeSeriesTab/Side';

interface AdsbToastProps extends CustomContentProps {
    properties: any;
};

export const OPERATORS = {
    number: ["<", "≤", "=", "≠", "≥", ">"],
    string: ["=", "≠"],
    boolean: ["="],
    bigint: [],
    symbol: [],
    undefined: [],
    object: [],
    function: []
};
const IGNORED_PROPS = ["color", "geometry", "time", "last_contact"];

const ToastContent = React.memo(function ToastContent({ properties, onClose }: { properties: any, onClose: () => void }) {
    const dispatch = useDispatch();
    const keys = Object.keys(properties).filter(
        key =>
            !IGNORED_PROPS.includes(key) &&
            Object.keys(OPERATORS).includes(typeof properties[key])
    );

    const [state, setState] = useState({
        key: "callsign",
        operator: "=",
        value: properties.callsign,
        color: "red"
    });

    const handleChangeKey = useCallback((e: any) => {
        setState(state => ({
            ...state,
            key: e.target.value,
            value: properties[e.target.value],
            operator: "="
        }))
    }, [properties]);

    const handleChange = (key: string, value: any) => {
        setState(state => ({
            ...state,
            [key]: value
        }));
    };

    const onCommit = () => {
        dispatch(aeroActions.add_adsb_filter({ filter: state }));
        onClose();
    };

    const renderInput = (value: any) => {
        switch (typeof value) {
            case "number":
                return (
                    <TextField
                        size="small"
                        type="number"
                        name="value"
                        value={value}
                        onChange={(event: any) => handleChange(event.target.name, parseFloat(event.target.value))}
                    />
                );
            case "boolean":
                return (
                    <Checkbox
                        name="value"
                        checked={value}
                        onChange={(event: any) => handleChange(event.target.name, event.target.checked)}
                    />
                );
            default:
                return (
                    <TextField
                        size="small"
                        name="value"
                        value={value}
                        onChange={(event: any) => handleChange(event.target.name, event.target.value)}
                    />
                );
        }
    };

    return (
        <Card>
            <CardHeader
                title={<Typography variant="body1">Add Filter</Typography>}
                subheader={<Typography variant="body2">{properties.callsign}</Typography>}
                avatar={
                    <Avatar>
                        <FilterAltIcon />
                    </Avatar>
                }
                action={
                    <Stack direction="row" sx={{ ml: 4 }} spacing={0} alignItems="center">
                       <IconButton size="medium" onClick={onCommit}>
                            <CheckCircleIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="medium" onClick={onClose}>
                            <CancelIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                }
            />
            <Divider />
            <CardContent sx={{ maxHeight: '400px', overflow: 'auto' }}>
            <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <ColorPalette color={state.color} size="small" onChange={(color: any) => handleChange("color", color)} />
                        <TextField
                            select
                            value={state.key}
                            inputProps={{ name: "key" }}
                        onChange={handleChangeKey}
                            variant="outlined"
                            size="small"
                            
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {keys.map((key, index) => <option value={key} key={index}>{key}</option>)}
                        </TextField>
                        {typeof state.value !== "boolean" && (
                            <TextField
                                select
                                value={state.operator}
                                inputProps={{ name: "operator" }}
                                onChange={(event: any) => handleChange(event.target.name, event.target.value)}
                                variant="outlined"
                                size="small"
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {OPERATORS[typeof state.value].map((operator: string, index: number) => {
                                    return (
                                        <option value={operator} key={index}>
                                            {operator}
                                        </option>
                                    );
                                })}
                            </TextField>
                        )}
                        {renderInput(state.value)}
                    </Stack>
            </CardContent>
        </Card>
    );
});

const AdsbToast = forwardRef<HTMLDivElement, AdsbToastProps>(
    ({ id, ...props }, ref) => {

        const { closeSnackbar } = useSnackbar();

        const onClose = useCallback(() => {
            closeSnackbar(id)
        }, [id, closeSnackbar]);

        return (
            <SnackbarContent ref={ref}>
                <ToastContent
                    properties={props.properties}
                    onClose={onClose}
                />
            </SnackbarContent>
        );
    }
);

export default AdsbToast;