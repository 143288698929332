import axios from "axios";
import { API_CONFIG } from '../../config/api';

export const CUSTOM_API = {
    getCustoms: (token: string, missionId: string) => axios.get(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/custom/${missionId}/`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
    postCustom: (token: string, missionId: string, file: any, fileType: string, minLon: string, maxLon: string, minLat: string, maxLat: string, sourceProjection: string) => {
        const formData = new FormData();
        formData.set("file", file);
        //formData.set("dst", missionId); // TODO : uncomment once customs are fully implemented as distributed objects
        formData.set("min_long", fileType === "vector" ? "0" : minLon);
        formData.set("max_long", fileType === "vector" ? "0" : maxLon);
        formData.set("min_lat", fileType === "vector" ? "0" : minLat);
        formData.set("max_lat", fileType === "vector" ? "0" : maxLat);
        formData.set("source_projection", sourceProjection);
        formData.set("data_type", fileType || "unknown");
        formData.set("mission", missionId);
        return axios.post(
            `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/custom/${missionId}/upload/`,
            formData,
            { headers: { "Content-type": "multipart/form-data", Authorization: "Token " + token } }
        )
    },
    patchCustom: (token: string, missionId: string, id: string, minLon: string, maxLon: string, minLat: string, maxLat: string, sourceProjection: string) => {
        axios.patch(
            `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/custom/${missionId}/${id}/`,
            {
                min_long: minLon,
                max_long: maxLon,
                min_lat: minLat,
                max_lat: maxLat,
                source_projection: sourceProjection,
            },
            { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
        )
    },
    deleteCustom: (token: string, missionId: string, id: string) => axios.delete(
        `${API_CONFIG.HOSTNAME}${API_CONFIG.API_PREFIX}${API_CONFIG.API_VERSION}/custom/${missionId}/${id}/`,
        { headers: { "Content-type": "application/json", Authorization: "Token " + token } }
    ),
};