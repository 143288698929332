import React, { forwardRef } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NavigationIcon from '@mui/icons-material/Navigation';

interface InfoProps extends CustomContentProps {
    severity: any;
};

const ToastInfo = forwardRef<HTMLDivElement, InfoProps>(
    ({ id, ...props }, ref) => {
        const { closeSnackbar } = useSnackbar();

        const onClose = () => {
            closeSnackbar(id)
        };

        return (
            <SnackbarContent ref={ref}>
                <Alert
                    severity={props.severity}
                    sx={{ alignItems: 'center' }}
                    action={
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                >
                    <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">
                            {props.message}
                        </Typography>
                        <IconButton
                            size="large"
                            component={Link}
                            onClick={onClose}
                            to={`../nav/`}
                        >
                            <NavigationIcon fontSize='inherit' />
                        </IconButton>
                    </Stack>
                </Alert>
            </SnackbarContent>
        );
    }
);

export default ToastInfo;