import React, { useRef } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { createSelector } from "reselect";
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { toDate } from '../../../utils/TimeUtils';
import StringUtils from '../../../utils/StringUtils';
import TrackLayer from './layers/TrackLayer';
import VehicleLayer from './layers/VehicleLayer';
import CirclesLayer from './layers/CirclesLayer';
import findLastIndex from 'lodash/findLastIndex';

const trackSelector = createSelector(
    (state: RootState) => state.telemetry.streams,
    (_: RootState, properties: { id: string, source: string }) => properties,
    (streams, { id, source }) => {
        let positions: any[] = [];
        if (id in streams && source in streams[id]) {
            positions = streams[id][source];
        };
        return positions;
    }
);

function ForceTrackLayerRestyle({ layer }: { layer: any }) {
    useUpdateEffect(() => {
        layer && layer.changed();
    }, [layer]);

    return null;
};

function Tracker(props: any) {
    const { id, source, shown, zIndex, name, color, kind, virtual, status, hud, circles, length, colorTrack } = props;
    const currentTime = useSelector((state: RootState) => state.replay.currentTime);
    const currentTS = toDate(currentTime).getTime() / 1000;
    const trackLayerRef = useRef<{ ol: any }>();

    const positions = useSelector((state: RootState) => trackSelector(state, { id, source }));
    if (!(positions.length > 0 && positions[0].length > 0)) return null;

    const lastIndex = currentTS ? findLastIndex(positions[0], (ts: number) => ts <= currentTS) : positions[0].length - 1;
    if (lastIndex === -1) return null;
    
    const firstIndex = positions[0].findIndex((p: number) => p > positions[0][lastIndex] - length);
    const { position: lastPosition, ...lastProperties } = positions[1][lastIndex];
    const last = { position: lastPosition, properties: { ...lastProperties, timestamp: positions[0][lastIndex] } };

    return (
        <>
            <VehicleLayer
                id={id}
                status={status}
                name={name}
                color={color}
                kind={kind}
                virtual={virtual}
                visible={shown}
                last={last}
                zIndex={zIndex + 15} // Increasing zIndex to prevent vehicles being displayed below other tracks and hud
                hud={hud}
            />
            <TrackLayer
                id={id}
                innerRef={trackLayerRef}
                visible={shown}
                positions={[positions[0].slice(firstIndex, lastIndex + 1), positions[1].slice(firstIndex, lastIndex + 1)]}
                shadow={currentTS ? positions : [[], []]} // currentTS is defined only in replay mode
                zIndex={zIndex}
                length={length}
                colorTrack={colorTrack}
            />
            <ForceTrackLayerRestyle layer={trackLayerRef?.current?.ol} />
            <CirclesLayer
                id={id}
                visible={shown && circles}
                last={last}
                zIndex={zIndex}
            />
        </>
    );
};

export default function Tracking({ zIndex }: { zIndex: number }) {
    const tracking = useSelector((state: RootState) => state.nav.tracking);

    return (
        <>
            {
                Object.entries(tracking).map(([trackerId, tracker]) => {
                    const { shown, status, circles, hud, length, kind, virtual, name, source, colorTrack } = tracker;
                    return (
                        <Tracker
                            key={trackerId}
                            id={trackerId}
                            name={name}
                            color={StringUtils.stringToColor(trackerId, true)}
                            kind={kind}
                            virtual={virtual}
                            source={source}
                            zIndex={zIndex}
                            shown={shown}
                            status={status}
                            length={length}
                            circles={circles}
                            hud={hud}
                            colorTrack={colorTrack}
                        />
                    );
                })
            }
        </>
    );
};